import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-registrar-paciente',
  templateUrl: './registrar-paciente.component.html',
  styleUrls: ['./registrar-paciente.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarPacienteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
