import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION;

@Injectable()
export class AuthenticationService {
    public token: string;

    constructor(private http: HttpClient
       ) {
        // set token if saved in local storage
        var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.revisarLogin();
    }

    revisarLogin(){
        if (sessionStorage.getItem('userToken')) {
            this.token = sessionStorage.getItem('userToken');
        }
    }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    login(codigo: string, password: string): Observable<any> {
        let loginURL = BASE_URL + '/auth';
        let data = {
            username: codigo,
            password: password
        };
        return this.http.post(loginURL, data, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        );
    }
       

    logout(): void {
        this.token = null;
        sessionStorage.clear();
       // if (this.stomp["socket"]) {
        //    this.stomp.disconnect();
       // }
    }

    createAuthorizationHeader(headers: Headers) {
        let token = this.token;
        if (token) {
            headers.append("Authorization", token);
        } else {
            return {};
        }

    }

    getUserById(idUser): Observable<any> {
        let findUserUrl = BASE_URL+'/'+idUser;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    showUserInformation(): Observable<Object> {
        let getUserURL = BASE_URL + '/usuarios/jwtUsuario';
        this.revisarLogin();
        let tokenFront = this.token;
        return this.http.post(getUserURL, tokenFront, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        )
    }


     // Error handling
     errorHandl(error) {
            let errorMessage = '';
            if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
            } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            console.log(errorMessage);
            return throwError(errorMessage);
    }
}