import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {HemTrSolicitudTrans} from '../../../../common/hemtrsolicitudtrans';
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { UserService } from '../../../../services/admin/user.service';
import { SolicitudService } from '../../../../services/hemofilia/solicitud.service'
export interface estado {
  idEstado: number;
  nombre: string;
}
@Component({
  selector: 'app-solicitud-transferencia-paciente-ver',
  templateUrl: './solicitud-transferencia-paciente-ver.component.html',
  styleUrls: ['./solicitud-transferencia-paciente-ver.component.scss'],
  providers:[CentroService,MessageService, UserService, SolicitudService,PacienteService],
  encapsulation: ViewEncapsulation.None
})
export class SolicitudTransferenciaPacienteVerComponent implements OnInit {
  updateToSolicitud: HemTrSolicitudTrans;
  solicitud: HemTrSolicitudTrans[];
  centros: AdmTmCentro[];
  paciente:AdmTmPaciente;
  getData: string;
  busyUpdate: any;
  busyId: any;
  disableUpdate = false;
  pacienteApellidosNombre: string;
  codigoPaciente: string;
  pacienteUsuario: any;
  medicoOrigen: string;
  medicoDestino: string;

  estados: estado[] = [
    {idEstado: 6, nombre: 'Aprobado'},
    {idEstado: 7, nombre: 'Rechazado'}
  ];

  constructor(private centroService : CentroService,
              private router : Router,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private pacienteService:PacienteService
  ) { }

  ngOnInit() {
    this.clearUpdateSolicitud();
    let itemid= this.route.snapshot.params['id'];
    this.getSolicitudById(itemid);

    console.log(this.busyId);
    

  }
  updateSolicitud(){
    
  }
  clearUpdateSolicitud(){
    this.updateToSolicitud = {
      idSolicitud: null,
      numSolicitud:null,
      sustento:null,
      observacion:null,
      idAprobador: null,
      estado:null,
      admTmUsuarioOrigen:null,
      admTmUsuarioDestino: null,
      admTmCentroOrigen: null,
      admTmCentroDestino:null,
      admTmPaciente: null,
      fechaInicio:null,
      fechaCreacion:null
    };
 
    this.disableUpdate=false;
    this.pacienteApellidosNombre=null;
  }

  goToSolicitud(){
    let link = ['/solicitud'];
    this.router.navigate(link);
  }
  
  
  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;                    
            },
          error => this.showError('Error obtener centros')
        );
  }

  getSolicitudById(id){

    this.solicitudService.getSolicitudById(id)
        .subscribe(
          data=>{
            console.log(data);
            this.updateToSolicitud=data; 
            this.codigoPaciente=data.admTmPaciente.codigoPaciente;
            this.pacienteApellidosNombre=data.admTmPaciente.apellidoPaterno+' '+data.admTmPaciente.apellidoMaterno+' '+data.admTmPaciente.nombresPaciente; 
            this.medicoOrigen=this.updateToSolicitud.admTmUsuarioOrigen.apellidosUsuario+" "+this.updateToSolicitud.admTmUsuarioOrigen.nombresUsuario+"("+this.updateToSolicitud.admTmUsuarioOrigen.codigoUsuario+")";
            this.medicoDestino=this.updateToSolicitud.admTmUsuarioDestino.apellidosUsuario+" "+this.updateToSolicitud.admTmUsuarioDestino.nombresUsuario+"("+this.updateToSolicitud.admTmUsuarioDestino.codigoUsuario+")";
            this.busyId=true;
            },
          error => this.showError('Error obtener paciente')
        );
  }

  
 

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
