import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//services
import { MessageService } from '../../../../services/MessageService';

import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { CatalogosService } from '../../../../services/admin/catalogos.service';
import { PerfilService } from '../../../../services/mantenimiento/perfil.service'
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmPerfil} from '../../../../common/admtmperfil';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {CatalogTmCodigoTipoDocumentoIdentidad} from '../../../../common/catalogtmcodigotipodocumentoidentidad';

export interface genero {
  codigo: string;
  descripcion: string;
}

@Component({
  selector: 'app-registrar-usuario',
  templateUrl: './registrar-usuario.component.html',
  styleUrls: ['./registrar-usuario.component.scss'],
  providers:[CentroService,UserService,PerfilService,CatalogosService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarUsuarioComponent implements OnInit {
  newUsuario: AdmTmUsuario;
  userPerfil : AdmTmPerfil;
  userCentro : AdmTmCentro;
  password2Usuario : string;
  busyCreate: Subscription;
  busyId: Subscription;
  disableCreate = false;
  listaCentros:any[];
  centros: AdmTmCentro[];
  perfiles: AdmTmPerfil[];
  tipodocumentos: CatalogTmCodigoTipoDocumentoIdentidad[];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
 
  constructor(private centroService : CentroService,
              private perfilService : PerfilService,
              private usuarioService : UserService,
              private catalogosService : CatalogosService,
              private router : Router,
              private toastrService: ToastrService,
              private messageService: MessageService
    ) { }

  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;     
            this.listaCentros=data;
      
            },
          error => this.showError('Error obtener centros')
        );
  }

  listaCentroElegido(centro){
    if (centro.elegido){
      centro.elegido = false;
    }else{
      centro.elegido = true;
    }

  }



  getPerfiles(){
    this.perfilService.getPerfiles()
        .subscribe(
          data=>{
            console.log(data);
            this.perfiles=data;            
            },
          error => this.showError('Error obtener centros')
        );
  }

  getTipoDocumento(){
    this.catalogosService.getCodigoTiposDocumentoIdentidadActivas()
        .subscribe(
          data=>{
            console.log(data);
            this.tipodocumentos=data;            
            },
          error => this.showError('Error obtener centros')
        );
  }



  back(){
    let link = ['/usuario'];
    this.router.navigate(link);
  }

  clearNewUsuario(){
    this.newUsuario = {
      idUsuario: null,
      nombresUsuario:null,
      apellidosUsuario:null,
      codigoUsuario:null,
      numeroDocumento:null,
      emailUsuario:null,
      telefono:null,
      celular:null,
      estado:null,
      admTrPerfilxusuarios:null, 
      passwordUsuario:null ,
      direccion: null,
      genero: null,
      noEliminable:null,
      catalogTmTipoDocumento: null,
      apellidosNombres:null
    };
 
    this.disableCreate=false;
  }

  goToUsuario(){
    let link = ['/usuario'];
    this.router.navigate(link);
  }

  createUsuario() {
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      if(this.newUsuario.passwordUsuario !== this.password2Usuario){
        this.showWarning('Las contraseñas no coinciden. Favor verificar.');
      }else{
        this.disableCreate = true;
        let centros = [];
        for (var i = 0; i < this.listaCentros.length; i++){
          if (this.listaCentros[i].elegido){
            for (var k = 0; k < this.centros.length; k++){
              if(this.listaCentros[i].idCentro==this.centros[k].idCentro)
                  centros.push(this.centros[k]);
            }
          }
        }
        let userData = {
                        admTmUsuario: this.newUsuario,
                        admTmPerfil: this.userPerfil,
                      // admTmCentro: this.userCentro
                        centros: centros
                          }
        console.log(userData);
                          
        this.busyCreate = this.usuarioService.createUser(userData).subscribe(
          data => {
            if(data['codigoValidacion']==1)
              this.showWarning(data['mensajeValidacion']);
            if(data['codigoValidacion']==2)
              this.showSuccess(data['mensajeValidacion']);

            if(data['codigoValidacion']==3)
              this.showError(data['mensajeValidacion']);                    
          },
          error => this.showError('Error inesperado'),
          () => this.clearNewUsuario()
          );  
          this.disableCreate = false;  
      }    
    }
    
  }
 

  ngOnInit() {

    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'Crear Usuario' });
    this.clearNewUsuario();
    this.getCentros();
    this.getPerfiles();
    this.getTipoDocumento();
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
