import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LovsService } from '../../../../services/admin/lovs.service';
import { DataService } from '../../../../services/DataService';
import { RegistrohemoService } from '../../../../services/hemofilia/registrohemo.service';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core';

import * as $ from 'jquery';

@Component({
  selector: 'app-evoluciones-hemorragico',
  templateUrl: 'evoluciones-eventos-hemorragico.component.html',
  styleUrls: ['evoluciones-eventos-hemorragico.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EvolucionesEventosHemorragicoComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();

  dataValores: any = null;
  dataArticulaciones: any = null;
  dataPacienteRegistro: any = null;
  
  columnas: any[] = [];
  filas: any = [];
  totalesTabla = {};
  newPacienteEveH: any = {};
  dataRecibida: any = null;
  dataPuntajeGG: any = null;

  listaLocalizaciones: any=null;
  dataNoArticulaciones: any = [];
  actividades: any = [];
  disableCreate: boolean = false;
  idRegistro: any = null;
  //Jeancarlo A. 21-04-21
  idRegEvolucion: any = null;

  // variables de control de carga de datos
  cargadaTabla: boolean =  false;
  registroCargado: boolean = false;
  puntajeCargado: boolean = false;
  actividadesCargado: boolean = false;
  noArticulacionCargado: boolean = false;//any[] = null; cambio por Jeancarlo A. 22-03-21
  localizacionesCargadas: any[] = null;//boolean = false;


  disableEdit: boolean = false;
imagen: String;
  puntajeMarcha: number = 0;

  totalArticulaciones: number = 0;
  totalHJHS: number = 0;

  noarticularelegido: any;
  localizacioneselegido: any;
  constructor(
    private toastrService: ToastrService,
    private lovsService: LovsService,
    private dataService : DataService,
    private router : Router,
    private route: ActivatedRoute,
    private registroHemoService: RegistrohemoService
  ) { 
    this.dataArticulaciones=[];
    this.localizacioneselegido=null;
    this.noarticularelegido=null;

  }
  
  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.disableEdit= data.disableEdit;
    //this.newPacienteEveH.hemTrRegistro = {
    this.newPacienteEveH.hemTrRegistroEvolucion = {
      //idRegistro: data.idRegistro, 
      idRegEvolucion: data.idRegEvolucion, //Añadido Jeancarlo A.
    }
    //this.idRegistro = data.idRegistro;
    this.idRegEvolucion = data.idRegEvolucion;
    //if (data.idRegistroEstado > 1){ // 1: En proceso
    //  this.disableEdit = true;
    //}
  }

  ngOnInit() {
    console.log('eventos hemorragicos');
    this.imagen="http://www.hemofilia.sph-peru.org/assets/img/figura_circulos_lineas.png";
  

 
    let itemid = this.route.snapshot.params['id'];
    if (itemid){
     // this.idRegistro = itemid; --Jeancarlo A. 19-04-21
     this.idRegEvolucion = itemid;
      this.newPacienteEveH.hemTrRegistroEvolucion = {
        // idRegistro: itemid -- Jeancarlo A. 19-04-21
        idRegEvolucion: itemid
      }
      this.cargarEventos();
    }else{
      this.registroCargado = true;
      this.cargadaTabla = true;
    }
    this.getLovArticulaciones();
    this.getLovLocalizaciones();
    this.getLovNoArticulacion();
    this.getAdmTmLovActividades();
  }

  getLovArticulaciones(){
    this.dataService.obtenerArticulaciones().subscribe(response => {
  //    this.dataArticulaciones = response;
      for (var i = 0; i < response.length; i ++){
        // console.log("obtener  articulacion:");
        // console.log(response[i]);
      response[i].imagen="http://www.hemofilia.sph-peru.org/assets/img/figura_circulos_lineas.png";
       response[i].elegido=false;
      response[i].imagencheck="assets/img/"+response[i].codigo+".png";
    this.dataArticulaciones.push(response[i]); 
    }
      if (!this.dataArticulaciones || this.dataArticulaciones.length < 1){
        this.showError('Error inesperado.')
      }else{
        this.cargarPuntajes();
      }
    });
   
  }

  getAdmTmLovActividades(){
    this.lovsService.getAdmTmLovActividadActivas().subscribe(
      data=>{
        this.actividades = data;
        this.actividadesCargado = true;
        this.verificarCarga();
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovNoArticulacion(){
    this.lovsService.getAdmTmLovNoArticulacionActivas().subscribe(
      data=>{
        this.dataNoArticulaciones = data;
        this.noArticulacionCargado = true;
        this.verificarCarga();
        //Jeancarlo A. 22-03-21
        //this.revisarNoArticulaciones();
       // console.log(this.dataNoArticulaciones);
      },
      error => this.showError('Error obtener yes no')
    );
  }
  getLovLocalizaciones(){
    this.lovsService.getAdmTmLovLocalizacionCuerpoActivas().subscribe(
      data=>{
        this.listaLocalizaciones = data;
        //this.localizacionesCargado = true;
        //this.verificarCarga();
        //Jie 08-03-2021
        this.revisarLocalizaciones();
        //console.log(this.listaLocalizaciones);
      },
      error => this.showError('Error obtener localizaciones')
    );
  }
  //Jie 08-03-2021
  revisarLocalizaciones(){
    if (this.localizacionesCargadas && this.localizacionesCargadas.length > 0 && this.listaLocalizaciones && this.listaLocalizaciones.length > 0){
  //   console.log(this.localizacionesCargadas);
 //    console.log(this.listaLocalizaciones);
      for (var i = 0; i < this.localizacionesCargadas.length; i++){
        let localizacion = this.listaLocalizaciones.find(l => l.idLocalCuerpo === this.localizacionesCargadas[i].idLocalCuerpo);
        if (localizacion){
          localizacion.checked = true;
          localizacion.numeroEpisodios = this.localizacionesCargadas[i].numeroEpisodios;
          //console.log(localizacion);
        }
      }
     //console.log(this.listaLocalizaciones);
    }
  }

  cargarPuntajes(){
    this.lovsService.getAdmTmLovScoreActivos().subscribe(response => {
      this.dataValores = response;
      this.cargarTabla();
      this.puntajeCargado = true;
      this.verificarCarga();
    },error =>{
      this.showError('Error inesperado.')
    });
  }

  cargarTabla(){
    let dataFilas = [];
    for (var puntaje of this.dataValores){
      if (puntaje.codigo != "GG"){
        dataFilas.push({
          idScore: puntaje.idScore,
          score: puntaje.nombre,
          codigo: puntaje.codigo,
          valoresPuntaje: puntaje.valoresPuntajes
        });
      }else{
        this.dataPuntajeGG = puntaje.valoresPuntajes;
        this.verificarListaMarchaPuntaje();
      }
    }
    // Se agrega la fila de los totales
    dataFilas.push({
      idScore: 0,
      score_puntaje: 'Total de articulaciones',
      codigo: 'TOTAL',
      valoresPuntaje: []
    });
    let columnaNombres: any[] = [{
      name: ' ',
      prop: 'score'
    }];
    
    for (var art of this.dataArticulaciones){
      if (art.codigo != 'HOMIZQ' && art.codigo != 'HOMDER'){
        dataFilas[dataFilas.length - 1][art.codigo + '_puntaje'] = 0;
        columnaNombres.push({
          name: art.nombre,
          prop: '' + art.codigo,
          idArticulacion: art.idArticulacion
        });
        this.totalesTabla[art.codigo] = 0;
      }
    }

    this.columnas = columnaNombres;
    this.filas = dataFilas;    
  }

  opcionElegida(idPuntajeElegido, fila, codigoColumna){
    if (!this.disableEdit){
      fila[codigoColumna] = idPuntajeElegido;
      let puntajeElegido = fila.valoresPuntaje.filter(puntaje => puntaje.idScorePuntaje == idPuntajeElegido);
      fila[codigoColumna + '_puntaje'] = ( (puntajeElegido && puntajeElegido.length > 0) ? puntajeElegido[0].puntaje : 0);
      this.calcularTotalesTabla();
    }
  }

  calcularTotalesTabla(){
    var filaTotales = this.filas[this.filas.length - 1];
    let dataTotales = {
      idScore: filaTotales.idScore,
      score_puntaje: filaTotales.score_puntaje,
      codigo: filaTotales.codigo,
      valoresPuntaje: filaTotales.valoresPuntaje
    };
    var total = 0
    for (var col of this.columnas){
      if (col.prop != 'score'){
        dataTotales[col.prop + '_puntaje'] = 0;
        for(var fila of this.filas){
          if (fila.codigo != 'TOTAL'){
            //let idPuntaje = fila[col.prop + '_puntaje'];
            let idPuntaje = fila[col.prop];
            let puntajeElegido = fila.valoresPuntaje.filter(puntaje => puntaje.idScorePuntaje == idPuntaje);
            dataTotales[col.prop + '_puntaje'] += ( (puntajeElegido && puntajeElegido.length > 0) ? puntajeElegido[0].puntaje : 0);
          }
        }
        total += dataTotales[col.prop + '_puntaje'];
      }
    }
    this.totalArticulaciones = total
    this.filas[this.filas.length - 1] = dataTotales;
    this.filas = [...this.filas];
    this.actualizarTotal();
  }

  marchaGlobalElegido(){
    if (this.newPacienteEveH && this.newPacienteEveH.scoreMarchaPuntaje && this.newPacienteEveH.scoreMarchaPuntaje != '0'){
      this.puntajeMarcha = this.newPacienteEveH.scoreMarchaPuntaje.puntaje;
    }else{
      this.puntajeMarcha = 0;
    }
    this.actualizarTotal();
  }

  actualizarTotal(){
    this.totalHJHS = this.totalArticulaciones + this.puntajeMarcha;
  }

  // listaLocalizacionElegida(localizacion){
  //   console.log(this.noarticularelegido);
  //   if (localizacion.elegido){
  //     localizacion.elegido = false;
  //   }else{
  //     localizacion.elegido = true;
      
  //   }

  // }

  parteCuerpoElegida(articulacion){
    // console.log(articulacion);
    // console.log(this.disableEdit);
    if (!this.disableEdit){
      if (articulacion.elegido){  // Valida nulos o si el campo no existe
        articulacion.elegido = false;
        articulacion.imagen ="http://www.hemofilia.sph-peru.org/assets/img/figura_circulos_lineas.png";
        articulacion.imagencheck="assets/img/"+articulacion.codigo+".png";
     //   this.imagen="http://localhost:4200/assets/img/figura_circulos_lineas.png";
      }else{

        articulacion.elegido = true;
        articulacion.imagen = "http://www.hemofilia.sph-peru.org/assets/img/figura_circulos_color.png";
        articulacion.imagencheck="assets/img/"+articulacion.codigo+"S.png";
        // console.log("test");
        // console.log(articulacion);
      //  this.imagen="http://localhost:4200/assets/img/figura_circulos_color.png";
      }
    }
  }



  cargarEventos(){
    // this.registroHemoService.getRegistroEventByRegistro(this.idRegistro).subscribe( --Original Jeancarlo A.
    this.registroHemoService.getRegistroEventByRegistroEvolucion(this.idRegEvolucion).subscribe(
      data => {
        this.dataRecibida = data;
        this.registroCargado = true;
        //Jie 08-03-2021
        this.localizacionesCargadas = data.episodios;
        this.revisarLocalizaciones();
        this.verificarCarga();
      }
    );
  }

  verificarCarga(){
    if (this.actividadesCargado  && this.noArticulacionCargado && this.puntajeCargado && this.registroCargado){
      //if (this.actividadesCargado && this.localizacionesCargado && this.noArticulacionCargado && this.puntajeCargado && this.registroCargado){
      if (this.idRegEvolucion && this.dataRecibida){ //if (this.idRegistro && this.dataRecibida){
        // Se esta viendo el detalle - se deben cargar los puntajes
        this.newPacienteEveH.numeroEpisodios = this.dataRecibida.numeroEpisodios;

        let hoy = new Date();

        if(this.dataRecibida.fecha != null){
          this.newPacienteEveH.fecha = (this.dataRecibida.fecha ? new Date(this.dataRecibida.fecha + 'T24:00:00') : null);
        }else{
          this.newPacienteEveH.fecha = hoy
        }

        //Jeancarlo A. 22-03-21
        //this.newPacienteEveH.otrosNoArticulares = this.dataRecibida.otrosNoArticulares;
        if (this.dataRecibida.idActividad){
          for (var i = 0; i < this.actividades.length; i ++){
            if (this.actividades[i].idActividad == this.dataRecibida.idActividad){
              this.newPacienteEveH.actividad = this.actividades[i];
              break;
            }
          }
        }

       /*Jie 08-03-2021 if (this.dataRecibida.episodios){
          for (var i = 0; i < this.dataRecibida.episodios.length; i++){
            let episodio = this.listaLocalizaciones.find(x => x.idLocalCuerpo == this.dataRecibida.episodios[i])
            if (episodio){
              //episodio.elegido = true;
              
          //    this.localizacioneselegido=this.dataRecibida.episodios[i];
             // console.log(this.localizacioneselegido);
             this.localizacioneselegido=episodio;
              break;
            }
          }
     //    this.localizacioneselegido=this.dataRecibida.episodios
        }*/

        if (this.dataRecibida.articulares){
          for (var i = 0; i < this.dataRecibida.articulares.length; i++){
            let articulacion = this.dataArticulaciones.find(x => x.idArticulacion == this.dataRecibida.articulares[i])
            if (articulacion){
              articulacion.elegido = true;
              
              articulacion.imagen = "http://www.hemofilia.sph-peru.org/assets/img/figura_circulos_color.png";
              articulacion.imagencheck="assets/img/"+articulacion.codigo+"S.png";
              // console.log("obtener  articulacioncolor:"+articulacion.imagencheck);
              // console.log(articulacion.imagencheck);
            }
          }
        }

        if (this.dataRecibida.noArticulares){
          for (var i = 0; i < this.dataRecibida.noArticulares.length; i++){
            let noArticulacion = this.dataNoArticulaciones.find(x => x.idNoArticulacion == this.dataRecibida.noArticulares[i].idNoArticulacion)
            if (noArticulacion){
           //   noArticulacion.elegido = true;
           //  this.noarticularelegido=this.dataRecibida.noArticulares[i];
            noArticulacion.checked = true;
            //this.noarticularelegido = noArticulacion;
            //this.noarticularelegido.nombreOtros=this.dataRecibida.noArticulares[i].nombreOtros;
            noArticulacion.nombreOtros=this.dataRecibida.noArticulares[i].nombreOtros;
            }
          }
           console.log('');
        }
        this.tardioCargaTabla();
        this.verificarListaMarchaPuntaje();

      }
    }
  }

  

  verificarListaMarchaPuntaje(){
    if (this.dataRecibida && this.dataRecibida.idScoreMarchaPuntaje && this.dataPuntajeGG){
      let scoreMarchaPuntaje = this.dataPuntajeGG.find(x => x.idScorePuntaje == this.dataRecibida.idScoreMarchaPuntaje)
      if (scoreMarchaPuntaje){
        this.newPacienteEveH.scoreMarchaPuntaje = scoreMarchaPuntaje;
      }
    }
    this.marchaGlobalElegido();
  }

  tardioCargaTabla(){
    //if (this.idRegistro && this.dataRecibida && this.dataRecibida.puntajes && !this.cargadaTabla){
    if (this.idRegEvolucion && this.dataRecibida && this.dataRecibida.puntajes && !this.cargadaTabla){
      for (var i = 0; i < this.dataRecibida.puntajes.length; i++){
        let puntaje = this.dataRecibida.puntajes[i];
        if (puntaje.codigoScore != "GG"){
          let fila = this.filas.find(x => x.idScore == puntaje.idScore);
          fila[puntaje.codigoArticulacion] = puntaje.idScorePuntaje
          fila[puntaje.codigoArticulacion + '_puntaje'] = puntaje.puntaje
          $("#" + puntaje.codigoScore + "_" + puntaje.codigoArticulacion).val(puntaje.idScorePuntaje);
        }
      }
      this.cargadaTabla = true;      
      this.calcularTotalesTabla();
    }
  }

  createRegistroEventos(){
    var f = document.getElementsByTagName('form')[1];
    if(f.reportValidity()) {
      this.disableCreate = true;

      let episodios = []
      for (var i = 0; i < this.listaLocalizaciones.length; i++){
        // if (this.listaLocalizaciones[i].elegido){
        //   episodios.push(this.listaLocalizaciones[i].idLocalCuerpo);
        // }
        if (this.listaLocalizaciones[i].checked){
          // console.log(this.listaLocalizaciones[i]);
          episodios.push({
            idLocalCuerpo: this.listaLocalizaciones[i].idLocalCuerpo,
            numeroEpisodios: this.listaLocalizaciones[i].numeroEpisodios,  //null  <---- REVISAR IMPLEMENTACION EN CASO "OTROS"
          });
        }
      }

      //episodios.push(this.localizacioneselegido);  

      let articulares = []
      for (var i = 0; i < this.dataArticulaciones.length; i++){
        if (this.dataArticulaciones[i].elegido){
          articulares.push(this.dataArticulaciones[i].idArticulacion);
        }
      }

      let noArticulares = []
      for(var i=0; i<this.dataNoArticulaciones.length; i++){
        if(this.dataNoArticulaciones[i].checked){
          // console.log(this.dataNoArticulaciones[i]);
          noArticulares.push({
            idNoArticulacion: this.dataNoArticulaciones[i].idNoArticulacion,
            nombreOtros: this.dataNoArticulaciones[i].nombreOtros,  //null  <---- REVISAR IMPLEMENTACION EN CASO "OTROS"
          });

        }
      }
 //     for (var i = 0; i < this.dataNoArticulaciones.length; i++){
  //      if (this.dataNoArticulaciones[i].elegido){
   //       noArticulares.push(this.dataNoArticulaciones[i].idNoArticulacion);
 //       }
 //     }
 
      let puntajes = []
      for (var i = 0; i < (this.filas.length - 1); i++){
        for (var j = 1; j < this.columnas.length; j++){
          var puntaje: any = {
            idScore: this.filas[i].idScore,
            idArticulacion: this.columnas[j].idArticulacion
          };
          if (this.filas[i][this.columnas[j].prop]){
            // Si se ha seleccionado este item
            puntaje.idScorePuntaje = parseInt(this.filas[i][this.columnas[j].prop]);
            puntaje.puntaje = this.filas[i][this.columnas[j].prop + '_puntaje'];
          }else{
            puntaje.idScorePuntaje = this.filas[i].valoresPuntaje[0].idScorePuntaje;
            puntaje.puntaje = this.filas[i].valoresPuntaje[0].puntaje;
          }
          puntajes.push(puntaje);
        }
      }

      let dataEvento: any = {
        numeroEpisodios: this.newPacienteEveH.numeroEpisodios,
        fecha: this.newPacienteEveH.fecha,
        episodios: episodios,
        articulares: articulares,
        noArticulares: noArticulares,
        idActividad: this.newPacienteEveH.actividad.idActividad,
        idScoreMarchaPuntaje: (this.newPacienteEveH.scoreMarchaPuntaje ? this.newPacienteEveH.scoreMarchaPuntaje.idScorePuntaje : 0),
        puntajes: puntajes,
        //idRegistro: this.newPacienteEveH.hemTrRegistro.idRegistro,
        //idRegistroEvolucion: null// Jeancarlo A. 18-03-21
        idRegistro: null,
        idRegEvolucion: this.newPacienteEveH.hemTrRegistroEvolucion.idRegEvolucion
      }

      if (this.dataRecibida && this.dataRecibida.idRegistroEventHemo){
        dataEvento.idRegistroEventHemo = this.dataRecibida.idRegistroEventHemo;
      }
      
      //this.registroHemoService.getRegistroEventByRegistro(dataEvento).subscribe(data => {
      this.registroHemoService.createRegistroHemo(dataEvento).subscribe(data => {
        if (data['codigoValidacion'] == 1){
          this.showWarning(data['mensajeValidacion']);
          this.disableCreate = false;
        }else if (data['codigoValidacion'] == 2){
          this.newPacienteEveH.idRegistroHemo = data['id'];
          this.showSuccess('Eventos hemorrágicos guardados.')
          this.siguienteTab(dataEvento);
        }else if (data['codigoValidacion'] == 3){
          this.showError(data['mensajeValidacion']);
          this.disableCreate = false;
        }
      },
        error => this.showError('Error inesperado'),
      );
    }
  }

  //Jie 08/03/2021
  sumarEpisodios(){
    let totalEpisodios=0;
    for (var i = 0; i < this.listaLocalizaciones.length; i++){
      // if (this.listaLocalizaciones[i].elegido){
      //   episodios.push(this.listaLocalizaciones[i].idLocalCuerpo);
      // }
      if (this.listaLocalizaciones[i].checked){
        totalEpisodios=totalEpisodios+this.listaLocalizaciones[i].numeroEpisodios;
        
      }
    }
    this.newPacienteEveH.numeroEpisodios=totalEpisodios;
  }

  //Añadido por Jeancarlo A. 23-03-21
  bloquearOpcionesNoArticulares(){
    if(this.dataNoArticulaciones){
      for (var i = 0; i < this.dataRecibida.noArticulares.length; i++){
        let noArticulacion = this.dataNoArticulaciones.find(x => x.idNoArticulacion != this.dataRecibida.noArticulares[i].idNoArticulacion)
        if(noArticulacion.idNoArticulacion == 6){
        noArticulacion.checked = false;
        noArticulacion.nombreOtros=null;
        } else{
          
        }
      }
    }
}

  siguienteTab(data){
    this.dataRegistrada.next(data);
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }
  
  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
