import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core'; 
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {HemTrSolicitudTrans} from '../../../../common/hemtrsolicitudtrans';
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
import { AdmTmPacienteActual } from '../../../../common/admtmpacienteactual';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { UserService } from '../../../../services/admin/user.service';
import { SolicitudService } from '../../../../services/hemofilia/solicitud.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';

@Component({
  selector: 'app-solicitud-transferencia-paciente',
  templateUrl: './solicitud-transferencia-paciente.component.html',
  styleUrls: ['./solicitud-transferencia-paciente.component.scss'],
  providers:[CentroService,MessageService, UserService, SolicitudService,PacienteService],
  encapsulation: ViewEncapsulation.None
})
export class SolicitudTransferenciaPacienteComponent implements OnInit {
  newSolicitud: HemTrSolicitudTrans;
  solicitud: HemTrSolicitudTrans[];
  centros: AdmTmCentro[];
  paciente:AdmTmPaciente;
  getData: string;
  newPaciente: AdmTmPaciente;
  newPacienteActual: AdmTmPacienteActual;
  busyCreate: Subscription;
  busyId: Subscription;
  disableCreate = false;
  pacienteApellidosNombre: string;
  codigoPaciente: string;
  pacienteUsuario: any;
  listaCentroOrigen: AdmTmCentro[];
  listaCentroDestino: AdmTmCentro[];
  medicoOrigen: string;
  listaMedicoDestino: AdmTmUsuario[];
  idRegistro: any = null;
  private registroService: RegistroService;
  @Output() dataRegistrada = new EventEmitter<any>();

  constructor(private centroService : CentroService,
              private router : Router,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private pacienteService:PacienteService
  ) { }

  ngOnInit() {
    this.clearNewSolicitud();
    this.getCentros();
    let itemid= this.route.snapshot.params['id'];
    this.getPaciente(itemid);
    

  }
/*-----------15/06/2020*/ 
  siguienteTab(dataPaciente){
    this.dataRegistrada.next(dataPaciente);
  }
  clearNewSolicitud(){
    this.newSolicitud = {
      idSolicitud: null,
      numSolicitud:null,
      sustento:null,
      observacion:null,
      idAprobador: null,
      estado:null,
      admTmUsuarioOrigen:null,
      admTmUsuarioDestino: null,
      admTmCentroOrigen: null,
      admTmCentroDestino:null,
      admTmPaciente: null,
      fechaInicio:null,
      fechaCreacion:null
    };
 
    this.disableCreate=false;
    this.pacienteApellidosNombre=null;
    this.codigoPaciente=null;
  }
  /*----------- 15/06/2020*/ 
 
  createSolicitud() {
    debugger;
      console.log("createSolicitud");
      console.log(this.newSolicitud);
     
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()){
      let dataEvento = {
            idPaciente: this.newSolicitud.admTmPaciente.idPaciente,
            idCentroOrigen:this.newSolicitud.admTmCentroOrigen.idCentro,
            idCentroDestino:this.newSolicitud.admTmCentroDestino.idCentro,
            idMedicoOrigen:this.newSolicitud.admTmUsuarioOrigen.idUsuario,
            idMedicoDestino:this.newSolicitud.admTmUsuarioDestino.idUsuario,
            sustento:this.newSolicitud.sustento,
            fechaInicio:this.newSolicitud.fechaInicio,
            idAprobador:this.newSolicitud.idAprobador,
            idSolicitante:this.newSolicitud.idAprobador
      }
     console.log(dataEvento,'estado aprobado o  pendiente');
     debugger;
      this.busyCreate = this.solicitudService.createSolicitud(dataEvento).subscribe(
        data => {
          if(data['codigoValidacion']==1)
            this.showWarning(data['mensajeValidacion']);
          if(data['codigoValidacion']==2)
            this.showSuccess(data['mensajeValidacion']);
            this.goToBack();
           
          if(data['codigoValidacion']==3)
            this.showError(data['mensajeValidacion']);                    
        },
        error => this.showError('Error inesperado'),
        () => this.clearNewSolicitud()
        );  
        
       


      }
    
  }

  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;                    
            },
          error => this.showError('Error obtener centros')
        );
  }

  getPaciente(id){
    this.pacienteService.getPacienteById(id)
        .subscribe(
          data=>{
            console.log(data);
            this.paciente=data; 
            this.newSolicitud.admTmPaciente=data;
            this.codigoPaciente=data.codigoPaciente;
            this.pacienteApellidosNombre=data.apellidoPaterno+' '+data.apellidoMaterno+' '+data.nombresPaciente; 
            this.getPacienteUsuario(data);

            },
          error => this.showError('Error obtener paciente')
        );
  }

  getPacienteUsuario(paciente){
    let centrosO = [];
    let centrosD = [];
    this.pacienteService.getPacienteUsuario(paciente)
        .subscribe(
          data=>{
            console.log("getPacienteUsuario");
            console.log(data);
            this.pacienteUsuario=data;

            if(this.pacienteUsuario.admTmCentro){
              var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
              this.newSolicitud.idAprobador=usuario.idUsuario;
              
              this.newSolicitud.admTmCentroOrigen=this.pacienteUsuario.admTmCentro;
              for (var i = 0; i < this.centros.length; i++){
                if (this.centros[i].idCentro==this.pacienteUsuario.admTmCentro.idCentro){
                  centrosO.push(this.centros[i]);
                  this.newSolicitud.admTmCentroOrigen=this.centros[i];
                  this.listaCentroOrigen=centrosO;
                  this.newSolicitud.admTmUsuarioOrigen=this.pacienteUsuario.admTmUsuario;
                  this.medicoOrigen=this.pacienteUsuario.admTmUsuario.apellidosUsuario+" "+this.pacienteUsuario.admTmUsuario.nombresUsuario+"("+this.pacienteUsuario.admTmUsuario.codigoUsuario+")";
                }else{

                  centrosD.push(this.centros[i]);
                }
              }
              this.listaCentroDestino=centrosD;

            }
            if(this.pacienteUsuario.admTmUsuarioOrigen){

              this.medicoOrigen=this.pacienteUsuario.admTmUsuarioOrigen;
              this.pacienteUsuario.admTmUsuarioOrigen.admTrPerfilxusuarios=null;
              this.newSolicitud.admTmUsuarioOrigen=this.pacienteUsuario.admTmUsuarioOrigen;
            }
                      
            },
          error => this.showError('Error obtener paciente usuario')
        );
  }


  loadMedicos() {
    this.listaMedicoDestino = [];
    let medicosDestino = [];

    if (this.newSolicitud.admTmCentroDestino) {
      this.userService.getUsersByCentro(this.newSolicitud.admTmCentroDestino.idCentro)
        .subscribe(response => {
         // medicosDestino= response;
          for (var i = 0; i < response.length; i++){
            
            response[i].admTmUsuario.apellidosNombres=response[i].admTmUsuario.apellidosUsuario+" "+response[i].admTmUsuario.nombresUsuario+"("+response[i].admTmUsuario.codigoUsuario+")";
            response[i].admTmUsuario.admTrPerfilxusuarios=null;
            medicosDestino.push(response[i].admTmUsuario);
          }
          this.listaMedicoDestino=medicosDestino;
          console.log(this.listaMedicoDestino);
        })
    }
  }

 

  goToBack() {
    let link = ['/paciente'];
    this.router.navigate(link);
}
 

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
