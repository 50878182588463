import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {AdmTmMedicamento} from '../../../../common/admtmmedicamento';
import {AdmTmLovMedida} from '../../../../common/admtmlovmedida';
import { MedicamentoService } from '../../../../services/mantenimiento/medicamento.service';
import { LovsService } from '../../../../services/admin/lovs.service';


@Component({
  selector: 'app-editar-medicamento',
  templateUrl: './editar-medicamento.component.html',
  styleUrls: ['./editar-medicamento.component.scss'],
  providers:[MedicamentoService,LovsService],
  encapsulation: ViewEncapsulation.None
})
export class EditarMedicamentoComponent implements OnInit {
  medicamentoToEdit: AdmTmMedicamento;
  busyUpdate: Subscription;
  medidas: AdmTmLovMedida[];
  disableUpdate = false;
  constructor(private medicamentoService : MedicamentoService,
    private lovsService: LovsService,
    private router : Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService) { }
  

  loadUnidadMedidas() {
    this.lovsService.getAdmTmLovMedidaActivas()
      .subscribe(response => {
        console.log(response);

        this.medidas = response;
      })
  }
  getMedicamentoById(id){
    this.medicamentoService.getMedicamentoById(id)
        .subscribe(
          data=>{
            console.log(data);
            this.medicamentoToEdit=data;
            console.log(this.medicamentoToEdit);
            if (this.medicamentoToEdit.admTmLovMedida!=null){
              for (let i = 0; i < this.medidas.length; i++) {
                if (this.medidas[i].idMedida === data.admTmLovMedida.idMedida) {
                  this.medicamentoToEdit.admTmLovMedida = this.medidas[i];
                  break;
                }
              }
            }
            
            },
          error => this.showError('Error obtener medicamento')
        );
  }
  clearUpdateMedicamento(){
    this.medicamentoToEdit = {
      idMedicamento: null,
      codigoMedicamento: null,
      nombreGenerico: null,
      nombreComercial: null,
      fabricante: null,
      cantidadMedida: null,
      admTmLovMedida: null
      
    };
 
    this.disableUpdate=false;
  }

  goToMedicamento(){
    let link = ['/medicamento'];
    this.router.navigate(link);
  }

  updateMedicamento() {
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.disableUpdate = true;
      console.log(this.medicamentoToEdit);
      this.busyUpdate = this.medicamentoService.updateMedicamento(this.medicamentoToEdit)
        .subscribe(
        data => {
          console.log(data);
          //this.showSuccess();

          if (data['codigoValidacion'] == 1)
            this.showWarning(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 2)
            this.showSuccess(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 3)
            this.showError(data['mensajeValidacion']);

          //this.reloadItems(this.params);		
        },
        error => this.showError('Error inesperado'),
        () => this.clearUpdateMedicamento()
        );
    }
  }

  ngOnInit() {
    this.clearUpdateMedicamento();
    this.loadUnidadMedidas();
    let itemid= this.route.snapshot.params['id'];
    this.getMedicamentoById(itemid);
  }
  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }



}
