import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'input-dialog-component',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent{

    inputValue: any = null;

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string) {
    }
  
    onNoClick(): void {
        this.dialogRef.close();
    }

    onYesclick(): void{
        if (this.inputValue && this.inputValue != ''){
            this.dialogRef.close(this.inputValue);
        }
    }

}