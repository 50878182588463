import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {HemTrSolicitudTrans} from '../../../../common/hemtrsolicitudtrans';
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { UserService } from '../../../../services/admin/user.service';
import { SolicitudService } from '../../../../services/hemofilia/solicitud.service'
export interface estado {
  idEstado: number;
  nombre: string;
}

@Component({
  selector: 'app-solicitud-transferencia-paciente-aprobacion',
  templateUrl: './solicitud-transferencia-paciente-aprobacion.component.html',
  styleUrls: ['./solicitud-transferencia-paciente-aprobacion.component.scss'],
  providers:[CentroService,MessageService, UserService, SolicitudService,PacienteService],
  encapsulation: ViewEncapsulation.None
})
export class SolicitudTransferenciaPacienteAprobacionComponent implements OnInit {
  updateToSolicitud: HemTrSolicitudTrans;
  solicitud: HemTrSolicitudTrans[];
  centros: AdmTmCentro[];
  paciente:AdmTmPaciente;
  getData: string;
  busyUpdate: any;
  busyId: any;
  disableUpdate = false;
  pacienteApellidosNombre: string;
  codigoPaciente: string;
  pacienteUsuario: any;
  medicoOrigen: string;
  medicoDestino: string;
  usuarios: AdmTmUsuario[];
  menus: any[];
  medicomenu :String;
  perfilUsuario: Int16Array;
  newPaciente: AdmTmPaciente;
  disableCreate = false;
  disableUsuario = false;
  


  estados: estado[] = [
    {idEstado: 6, nombre: 'Aprobado'},
    {idEstado: 7, nombre: 'Rechazado'}
  ];

  constructor(private centroService : CentroService,
              private router : Router,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private pacienteService:PacienteService
  ) { }

  ngOnInit() {
    this.clearUpdateSolicitud();
    let itemid= this.route.snapshot.params['id'];
    this.getSolicitudById(itemid);

    //Jeancarlo A. 10-06-21
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let usuario=currentUser.admTrPerfilxusuarios[0].admTmPerfil.idPerfil;
    if(usuario != 2){
      this.disableUsuario = true;
    }else{
      this.disableUsuario = false;
    }



    console.log(this.busyId);
    this.getMenus();

  }
  clearUpdateSolicitud(){
    this.updateToSolicitud = {
      idSolicitud: null,
      numSolicitud:null,
      sustento:null,
      observacion:null,
      idAprobador: null,
      estado:null,
      admTmUsuarioOrigen:null,
      admTmUsuarioDestino: null,
      admTmCentroOrigen: null,
      admTmCentroDestino:null,
      admTmPaciente: null,
      fechaInicio:null,
      fechaCreacion:null
    };
 
    this.disableUpdate=false;
    this.pacienteApellidosNombre=null;
  }
  goToSolicitud(){
    let link = ['/solicitud'];
    this.router.navigate(link);
  }
  updateSolicitud() {
      let pacienteactualid :any;
      console.log(this.updateToSolicitud,'updateSolicitud');
      let centro = this.updateToSolicitud.admTmCentroDestino.idCentro;
      let dataEvento = {
            idSolicitud: this.updateToSolicitud.idSolicitud,
            observacion:this.updateToSolicitud.observacion,
            estado:this.updateToSolicitud.estado,
            idAprobador:this.updateToSolicitud.idAprobador
      }
            
      console.log(this.newPaciente,'este es newpaciente');

           /*  if(dataEvento.estado==6){
             
             // this.updateToSolicitud.admTmPaciente.
              debugger;
                let newPPA: any = {
                  //admTmPacienteActual: this.newPacienteActual,
                  admTmPaciente: this.updateToSolicitud.admTmPaciente,
                  admTmCentro: {
                    idCentro: centro
                  }
                }
               debugger;
                this.pacienteService.createPaciente(newPPA).subscribe( data => {
                  console.log(data,'CREADA OBTENIDA*******');
                  this.getData = JSON.stringify(data);
                  if (data['codigoValidacion'] == 1){
                    this.showWarning(data['mensajeValidacion']);
                    this.disableCreate = false;
                  }else if (data['codigoValidacion'] == 2){
                    debugger;
                    newPPA.id = data['id'];
                    //this.crearRegistro(newPPA);
                    console.log(newPPA,'ESTE ES LA DATA PARA EL REGISTRO')
                    debugger;
                  }else if (data['codigoValidacion'] == 3){
                    this.showError(data['mensajeValidacion']);
                    this.disableCreate = false;
                  }
                },
                error => this.showError('Error inesperado'),
              );

              }  
           pacienteactualid= this.updateToSolicitud.admTmPaciente.idPaciente;   
              
           console.log('paciente actual',pacienteactualid);

              */
    

      this.busyUpdate= this.solicitudService.aprobacionSolicitud(dataEvento).subscribe(
        data => {
          if(data['codigoValidacion']==1)
            this.showWarning(data['mensajeValidacion']);
          if(data['codigoValidacion']==2)
         
            this.showSuccess(data['mensajeValidacion']);
            console.log('jie');
            console.log(this.updateSolicitud)
            console.log(data['mensajeValidacion'],'MENSAJE');
            
           
            this.goToView();
            
          if(data['codigoValidacion']==3)
            this.showError(data['mensajeValidacion']);                    
        },
        error => this.showError('Error inesperado'),
        () => this.clearUpdateSolicitud()
        );  
  }

  goToView() {
    let link = ['/solicitud/',this.updateToSolicitud.idSolicitud];
    console.log('solicitud Ver: ' + link);
    this.router.navigate(link);
  }


  getSolicitudById(id){

    this.solicitudService.getSolicitudById(id)
        .subscribe(
          data=>{
            console.log(data);
            this.updateToSolicitud=data; 
            this.codigoPaciente=data.admTmPaciente.codigoPaciente;
            this.pacienteApellidosNombre=data.admTmPaciente.apellidoPaterno+' '+data.admTmPaciente.apellidoMaterno+' '+data.admTmPaciente.nombresPaciente; 
            this.medicoOrigen=this.updateToSolicitud.admTmUsuarioOrigen.apellidosUsuario+" "+this.updateToSolicitud.admTmUsuarioOrigen.nombresUsuario+"("+this.updateToSolicitud.admTmUsuarioOrigen.codigoUsuario+")";
            this.medicoDestino=this.updateToSolicitud.admTmUsuarioDestino.apellidosUsuario+" "+this.updateToSolicitud.admTmUsuarioDestino.nombresUsuario+"("+this.updateToSolicitud.admTmUsuarioDestino.codigoUsuario+")";
            this.busyId=true;
            console.log(this.medicoOrigen,'medico origen campo');
            },
          error => this.showError('Error obtener paciente')
        );
  }



  getMenus() {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    let perfil = currentUser.admTrPerfilxusuarios;

    console.log(currentUser,'currentUser home');
    this.medicomenu= currentUser.apellidosUsuario +' '+ currentUser.nombresUsuario +'('+ currentUser.codigoUsuario+')';
    for(var i=0;i<perfil.length;i++){
      this.perfilUsuario=currentUser.admTrPerfilxusuarios[i].admTmPerfil.idPerfil;
      console.log(this.perfilUsuario,'este es el id del perfil');
    }
    console.log(this.medicomenu,'este es el medico origen');
    
}




  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
