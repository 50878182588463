import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { AdmTmCentro } from '../../common/admtmcentro';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/pacientes';

@Injectable()
export class PacienteService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    // search(body: any, pageable: DataTableParams): Observable<Object> {
    //     let uri = BASE_URL + '/searchAndPage?' + this.paramsToQueryString(pageable);
    //     return this.http.post(uri, body, this.httpOptions)
    //     .pipe(
    //     retry(1),
    //     catchError(this.errorHandl)
    //     )
    // }

    createPaciente(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoPaciente';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updatePaciente(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    getPacientes(): Observable<any> {
      let getUrl = BASE_URL+'/todos';
      return this.http.get(getUrl)
      .pipe(
      retry(1),
      catchError(this.errorHandl)
      )
    }

    getPacienteById(idPaciente): Observable<any> {
        let getUrl = BASE_URL+'/'+idPaciente;
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getVerificarDocumentoPaciente(numeroDocumento): Observable<any> {
        let createUrl = BASE_URL + '/verificarDocumentoPaciente';
        return this.http.get(`${createUrl}/${numeroDocumento}`, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    getPacienteUsuario(body: Object): Observable<any> {
        let getUrl = BASE_URL+'/pacienteusuario';
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    search(paramsBusqueda: any): Observable<any> {
      let getUrl = BASE_URL + '/search';
      let paramsGet = new HttpParams()
          .set('codigoPaciente', paramsBusqueda.codigoPaciente)
          .set('nombresPaciente', paramsBusqueda.nombresPaciente)
          .set('apellidosPaciente', paramsBusqueda.apellidosPaciente)
          .set('nroDocumentoPaciente', paramsBusqueda.nroDocumentoPaciente)
          .set('generoPaciente', paramsBusqueda.generoPaciente)
          .set('centroPaciente', paramsBusqueda.centroPaciente)
          .set('medicoPaciente', paramsBusqueda.medicoPaciente)
          .set('idUsuario' , paramsBusqueda.idUsuario)
          .set('estadoUsuario' ,paramsBusqueda.estadoUsuario)
          .set('pagina', paramsBusqueda.pagina)
          .set('cantidadPagina', paramsBusqueda.cantidadPagina);

      let options = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json'
          }),
          params: paramsGet
      }
      console.log(paramsGet,'entrada a java');
      return this.http.get(getUrl, options)
      .pipe(
      retry(1),
      catchError(this.errorHandl)
      )
  }

     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
    /************16-06-2020****************/
    getPacienteActualByIdPaciente(idPaciente): Observable<any> {
        let getUrl = BASE_URL+'/'+idPaciente;
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
   
}


