import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
//domain
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import * as moment from 'moment';
export interface genero {
  codigo: string;
  descripcion: string;
}
export interface estado {
  value: string;
  nombre: string;
}



@Component({
  selector: 'app-listar-pacientes',
  templateUrl: './listar-pacientes.component.html',
  styleUrls: ['./listar-pacientes.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ListarPacientesComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEditar = false;
  canCrear = false;
  canChange = false;
  parametros:any;
  getData: string;
  pacientes: AdmTmPaciente[];
  centros: AdmTmCentro[];
  currentCentro: any;
  user :any;

  pagina: any = {
    total: 0,
    numeroPagina: 0,
    cantidadPagina: 10
  }

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];

  
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  displayedColumns: string[] = ['FechaAtencion', 'Centro', 'Medico','CodigoPaciente','NombresApellidos','Estado','Acciones'];
  dataSource: MatTableDataSource<AdmTmPaciente>;
  data: any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private centroService : CentroService,
    private usuarioService : UserService,
    private registroService: RegistroService,
    private router : Router,
    private pacienteService: PacienteService,
    private toastrService: ToastrService,
    private messageService: MessageService) { }
  

    getCurrentUsuario(){
      let perfil:any[];
      let per :any;
      let pert :any[] ;
      
      this.user = JSON.parse(sessionStorage.getItem('currentUser'));
  
     perfil = this.user.admTrPerfilxusuarios;
  
      for(var i=0 ;i<perfil.length;i++){
  
        per =perfil[i].admTmPerfil.idPerfil; 
        console.log(per ,'ID DE PERFIl PER ') ;
      };
      
        if(per == 2 ){
  
          this.user.idUsuario = null;
        }
        else if (per ==1){

          this.user.idUsuario =0;
        }
        
       else {
         this.user.idUsuario = this.user.idUsuario;
       }
       console.log(this.user.idUsuario,'ID USUARIO SEGUN PERFIL');
    }
 



  getCentros(){
    this.centroService.getCentros().subscribe(
      data=>{
        this.centros=data;
        for (var k = 0; k < this.centros.length; k++){
         console.log(this.user.idUsuario,'USUARIOENTRADAAAAA');
        /*************************** */
         if(this.user.idUsuario == 0){
          this.params.centro = 0;
          this.params.idUsuario = this.user.idUsuario;
         }
         else{   
          if(this.currentCentro.idCentro==this.centros[k].idCentro){
              this.params.centro=this.centros[k];
              console.log(this.user.idUsuario);
              this.params.idUsuario = this.user.idUsuario;
              break;
          }
        }
        } 
        this.obtenerData();         
      },
      error => this.showError('Error obtener centros')
    );
  }
  
  clear() {
    this.items = [];
    this.params = {
      codigoUsuario:null,
      nombresUsuario:null,
      apellidosUsuario:null,
      admTrPerfilxusuarios:null,
      centro:null,
      numeroDocumento:null,
      genero:null,
      estado:null
    };
    this.parametros = [];
  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }


  checkPermisos() {
    this.canSelect = true;
    this.canEditar = this.checkMenusPermitidos('/solicitud/nuevo/id');
    //this.canCrear=this.checkMenusPermitidos('/paciente/nuevo');
  }

  checkMenusPermitidos(url) {
    let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
    let response = false;
    if (menus){
      for (let i = 0; i < menus.length; i++) {
          url = url.replace(/\d+/g, "id");
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
    }
    return response;
  }

  getCurrentCentro() {
    this.currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    console.log(this.currentCentro);
  }

  seleccionarPagina(pageInfo){
    this.pagina.numeroPagina = pageInfo.offset;
    this.obtenerData();
  }

  obtenerData(){
    console.log('data a buscar')
    console.log(this.params);

    let paramsBuscar: any = {
      codigoPaciente: this.params.codigoPaciente,
      nombresPaciente: this.params.nombresPaciente,
      apellidosPaciente: this.params.apellidosPaciente,
      nroDocumentoPaciente: this.params.nroDocumentoPaciente,
      medicoPaciente: this.params.medicoPaciente,
      idUsuario : this.user.idUsuario,
      pagina: this.pagina.numeroPagina,
      cantidadPagina: this.pagina.cantidadPagina
    }

    if(this.user.idUsuario==0){
      paramsBuscar.estadoUsuario =1
    }else{
      paramsBuscar.estadoUsuario =0;
    }

    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centroPaciente = this.params.centro.idCentro;
    }
   
    if (this.params.genero){
      paramsBuscar.generoPaciente = this.params.genero.codigo;
    }


    this.pacienteService.search(paramsBuscar).subscribe( data => {
        console.log(data.content,'DATA')
        let aux:any;
      aux=data.content
        
        for (let i = 0; i < aux.length; i++) {
          console.log(aux[i].estado,'ESTADOOOOOOO');
         
         if (aux[i].estado ===1) {
            aux[i].estado ='Activo';
          }
          else if (aux[i].estado ===2){
            aux[i].estado = 'Desactivo';
          }
         
        }
       console.log(aux,'aux ')
        this.data=aux;
        
        this.pagina.total = data.totalElements;
      },
      error => this.showError('Error inesperado')
    );
 
  }

  ngOnInit() {
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarPacientes' });
    this.clear();
    this.checkPermisos();
    this.getCurrentUsuario();
    this.getCurrentCentro();
    this.getCentros();
    //this.obtenerData();
  }

  solicitudTrans(idPaciente) {
  
    let link = ['/solicitud/nuevo/',idPaciente];
    console.log(link);
    this.router.navigate(link);
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
