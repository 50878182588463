import { Component, Inject, OnInit,ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'elegir-centro-component',
    templateUrl: './elegir-centro.component.html',
    styleUrls: ['./elegir-centro.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ElegirCentroComponent implements OnInit {

    centroElegido: any = null;
    centros: any[] = [];

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) { centros }: any ) {
            this.centros = centros;
    }

    ngOnInit() {
        console.log('holi')
    }
    
    elegir() {
        this.dialogRef.close(this.centroElegido);
    }

    close() {
        this.dialogRef.close();
    }

}