import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';


import { LovsService } from '../../../../services/admin/lovs.service';

import { RegistroinhibidoresService } from '../../../../services/hemofilia/registroinhibidores.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { RegistroEvolucionService } from '../../../../services/hemofilia/registroevolucion.service';
import { MessageService } from '../../../../services/MessageService';

@Component({
  selector: 'app-registro-evoluciones',
  templateUrl: './registro-evoluciones.component.html',
  styleUrls: ['./registro-evoluciones.component.scss'],
  providers:[RegistroinhibidoresService,MessageService,PacienteService,RegistroEvolucionService],
  encapsulation: ViewEncapsulation.None
})
export class RegistroEvolucionesComponent implements OnInit {
  disableCreate = false;
  newPacienteEvolucion:any= {};
  dosajeTratamientos: any=null;
  tratamientos: any=null;
  busyId =false;
  busyCreate: Subscription;
  paciente: any=null;
  tratamientosDosajeInhibidros: any = [];
  evenHemmoragico: any =[]; //Jeancarlo A 09/03/21
  procQuirurgico: any =[]; //Jeancarlo A 09/03/21
  inhibidoresTabla: any =[]; //Jeancarlo A 09/03/21
  listaYesNos: any = null; //Jeancarlo A 09/03/21
  idLovYesNoAnteFs: any = null; //Jeancarlo A 09/03/21
  cargadoYesNo: boolean = false; //Jeancarlo A 09/03/21
  disableEdit = false;

  constructor(
    private toastrService: ToastrService,
    private registroInhibidoresService: RegistroinhibidoresService,
    private lovsService: LovsService,
    private pacienteService:PacienteService,
    private router : Router,
    private route: ActivatedRoute,
    private registroEvolucion:RegistroEvolucionService
    ) { }
  
  getPaciente(id){
      this.pacienteService.getPacienteById(id).subscribe(
            data=>{
              console.log(data,'PACIENTE OBTENIDO');
              this.paciente=data; 
              this.newPacienteEvolucion.admTmPaciente=data;
              this.newPacienteEvolucion.codigoPaciente=data.codigoPaciente;
              this.newPacienteEvolucion.pacienteApellidosNombre=data.apellidoPaterno+' '+data.apellidoMaterno+' '+data.nombresPaciente; 
              this.newPacienteEvolucion.numeroDocumento = data.numeroDocumento;
              this.getPacienteUsuario(data);
              
              },
            error => this.showError('Error obtener paciente')
          );
  }


  goToEvolucion(){
    let link = ['/evolucion'];
    this.router.navigate(link);
  }

  /*getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(
      data=>{
        this.listaYesNos = data;
        this.idLovYesNoAnteFs = data;
        this.cargadoYesNo = true;
        //this.verificarCarga();
      },
      error => this.showError('Error obtener yes no')
    );
  }*/

  agregarFilaEvento(){
    this.evenHemmoragico.push({
      evento:'',
      fechaevent:'',
      hospitalizado:'',
      tratamiento:''
    });

    //this.evenHemmoragico = [...this.evenHemmoragico]
  }

  agregarFilaProcedimiento(){
    this.procQuirurgico.push({
      procedimiento:'',
      detalle:'',
      fechaproc:'',
      complicaciones:'',
      detalleCompl:''
    });
   // this.procQuirurgico = [...this.procQuirurgico]
  }


  agregarFilaTratamiento (){
    this.tratamientosDosajeInhibidros.push({
      titulo: '',
      fecha: '',
      tratamiento: '',
      dosis: ''
    });
   // this.tratamientosDosajeInhibidros = [...this.tratamientosDosajeInhibidros]
  }

  agregarFilaInhibidores(){
    this.inhibidoresTabla.push({
      dosaje:'',
      fechainih:'',
      tratamintoinih:'',
      medicacion:'',
      inmunotolerancia:'',
      respinmunotolerancia:'',
      esquema:''

    });
    //this.inhibidoresTabla = [...this.inhibidoresTabla]
  }

  eliminarFilaEvento(value){
    console.log('eliminarFila');
    console.log(value);
    this.evenHemmoragico.splice(value,1);
   // this.evenHemmoragico = [...this.evenHemmoragico]
  }

  eliminarFilaTratamiento(value){
    console.log('eliminarFila');
    console.log(value);
    this.tratamientosDosajeInhibidros.splice(value, 1);
  //  this.tratamientosDosajeInhibidros = [...this.tratamientosDosajeInhibidros]
  }

  eliminarFilaInhibidores(value){
    console.log('eliminarFila');
    console.log(value);
    this.inhibidoresTabla.splice(value,1);
   // this.inhibidoresTabla = [...this.inhibidoresTabla]
  }

  eliminarFilaProcedimiento(value){
    console.log('eliminarFila');
    console.log(value);
    this.procQuirurgico.splice(value,1);
  //  this.procQuirurgico = [...this.procQuirurgico]
  }

  getPacienteUsuario(paciente){
    this.pacienteService.getPacienteUsuario(paciente)
        .subscribe(
          data=>{
            console.log("getPacienteUsuario");
            console.log(data);
            this.newPacienteEvolucion.pacienteUsuario=data;
            console.log( this.newPacienteEvolucion.pacienteUsuario,'paciente usuario centro')
            this.busyId=true;
                      
            },
          error => this.showError('Error obtener paciente usuario')
        );
  }

  createEvolucionInhibidores(){

    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      console.log("createEvolucionInhibidores");
      console.log(this.newPacienteEvolucion);
      console.log(this.tratamientosDosajeInhibidros);
      let centro = JSON.parse(sessionStorage.getItem('currentCentro'));
      this.newPacienteEvolucion.admTmCentro=centro;
      let data={
        evolucion:this.newPacienteEvolucion,
        hemTrRegistroEvolucionTrata:this.tratamientosDosajeInhibidros

      }
                          
          this.busyCreate = this.registroEvolucion.createEvolucion(data).subscribe(
            data => {
              if(data['codigoValidacion']==1)
                this.showWarning(data['mensajeValidacion']);
              if(data['codigoValidacion']==2)
                this.showSuccess(data['mensajeValidacion']);
                this.goToEvolucion();
              if(data['codigoValidacion']==3)
                this.showError(data['mensajeValidacion']);                    
            },
            error => this.showError('Error inesperado'),
            () => this.clearNewEvolucion()
            );  

    }


  }

  clearNewEvolucion() {

  }

  loadTratamientos() {

    this.lovsService.getAdmTmLovTratamientoRecibeActivas()
    .subscribe(response => {
      this.tratamientos = response;
    });

    this.lovsService.getAdmTmLovTratamientoActivas()
    .subscribe(response => {
      this.dosajeTratamientos = response;
    })
  }

  ngOnInit() {
    this.loadTratamientos();
    let itemid= this.route.snapshot.params['id'];
    this.getPaciente(itemid);
    
 
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }


}
