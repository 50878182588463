import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmMenu } from '../../common/admtmmenu';
import { Util } from '../../../util';



const BASE_URL = Util.IP_API_CONNECTION + '/perfiles';
const BASE_USER_URL = Util.IP_API_CONNECTION + '/usuarios';

@Injectable()
export class PerfilService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    createPerfil(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoPerfil';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updatePerfil(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.put(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    checkUserxPerfil (idPerfil): Observable<any> {
        let checkUrl = BASE_USER_URL+'/findByPerfil';
        return this.http.get(`${checkUrl}/${idPerfil}`)
            .pipe(
            retry(1),
            catchError(this.errorHandl));    
    } 

    getPerfilById(idPerfil): Observable<any> {
        let findPerfilUrl = BASE_URL+'/'+idPerfil;
        return this.http.get(findPerfilUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getPerfiles(): Observable<any>{
        let url = '/todosSinFiltro'
        return this.http.get(BASE_URL+url)
        .pipe(
        retry(1),
        catchError(this.errorHandl) );
    }

    getPerfilesActivos(): Observable<any>{
        let url = '/todosActivos'
        return this.http.get(BASE_URL+url)
        .pipe(
            retry(1),
            catchError(this.errorHandl) );
    }

    getPerfilesByMenu() : Observable<any>{
        let urlPerfilesByMenu = BASE_URL+'/todosByMenu/';
         return this.http.get(urlPerfilesByMenu)
         .pipe(
            retry(1),
            catchError(this.errorHandl) );
    }

    
     // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
      
    
    deleteUser(body: any): Observable<any> {
        var perfil=JSON.parse(sessionStorage.getItem('currentUser'));
        let updateUrl = BASE_URL + '/updatePerfilEstado';
        return this.http.post(`${updateUrl}/${perfil.idUsuario}`, body, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
   
}
