import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
//domain
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import { DataService } from '../../../../services/DataService';

import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { InputDialogComponent } from '../../../shared/input-dialog/input-dialog.component';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
type AOA = any[][];

export interface genero {
  codigo: string;
  descripcion: string;
}
export interface estado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-listar-registros',
  templateUrl: './listar-registros.component.html',
  styleUrls: ['./listar-registros.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ListarRegistrosComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEdit = false;
  canCreate = true; // Para que funcione el disable correctamente
  parametros:any;
  getData: string;
  pacientes: AdmTmPaciente[];
  centros: AdmTmCentro[];
  disableButtons: boolean = true;
  currentCentro: any;
  user :any;
  usuarios :any;

  pagina: any = {
    total: 0,
    numeroPagina: 0,
    cantidadPagina: 10
  }

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  displayedColumns: string[] = ['FechaAtencion', 'Centro', 'Medico','CodigoPaciente','NombresApellidos','Estado','Acciones'];
  dataSource: MatTableDataSource<AdmTmPaciente>;
  data: any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('excelInput', {static: true}) excelInput: ElementRef;

  constructor(private centroService : CentroService,
    private usuarioService : UserService,
    public dialog: MatDialog,
    private registroService: RegistroService,
    private router : Router,
    private pacienteService: PacienteService,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dataService: DataService
  ) { }
 
 
  
  clear() {
    this.items = [];
    this.params = {
      fechaDesde: null,
      fechaHasta: null,
      codigoUsuario:null,
      nombresUsuario:null,
      apellidosUsuario:null,
      admTrPerfilxusuarios:null,
      centro:null,
      numeroDocumento:null,
      genero:null,
      estado:null
    };
    this.parametros = [];
  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }

  ingresarDocumento(){
    this.disableButtons = true;
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '350px',
      data: "Ingrese el número del documento del paciente:"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        console.log(result);
        // Se inicia verificacion de si ya existe registro para ese documento
        this.registroService.getVerificarDocumento(result).subscribe(
          data => {
            let dataValidacionDocumento = {
              numeroDocumento: result,
              idRegistro: data.idRegistro,
              idPaciente: data.idPaciente
            }

            console.log(data.codigoValidacion,'ENTRADA PARA SI CREA O NO');
            debugger;
            if (data.codigoValidacion == 1){
              this.confirmarCreacion(dataValidacionDocumento);
            }else if (data.codigoValidacion == 2){
              this.dataService.dataValidacionDocumento = dataValidacionDocumento;
              this.goToCrear();
            }else if (data.codigoValidacion == 3){
              this.showError(data.observaciones);
            }
            this.disableButtons = false;
          },
          error => {
            this.showError('Error inesperado');
            this.disableButtons = false;
          }
        );
      }
    });
  }

  confirmarCreacion(dataValidacionDocumento){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Ya existe un paciente con el documento ingresado. ¿Desea deshabilitar el anterior registro y crear uno nuevo?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        this.registroService.postRegistroReemplaza(dataValidacionDocumento).subscribe(
          data => {
            if (data['codigoValidacion'] == 1){
              this.showWarning(data['mensajeValidacion']);
            }else if (data['codigoValidacion'] == 2){
              this.showSuccess('Registro creado.')
              dataValidacionDocumento.idRegistro = data['id'];
              this.dataService.dataValidacionDocumento = dataValidacionDocumento;
              this.verDetalle(dataValidacionDocumento.idRegistro);
            }else if (data['codigoValidacion'] == 3){
              this.showError(data['mensajeValidacion']);
            }
            this.disableButtons = false;
          },
          error => {
            this.showError('Error inesperado');
            this.disableButtons = false;
          }
        );
      }
    });
  }

  goToCrear() {
    let link = ['/tab'];
    this.router.navigate(link);
  }
  /*
  goToEdit(item: AdmTmUsuario) {
    let link = ['/registro/', item.idUsuario];
    this.router.navigate(link);
  }
  */
  goToView(item: AdmTmUsuario) {
    let link = ['/usuario/ver/', item.idUsuario];
    this.router.navigate(link);
  }
  checkPermisos() {
    this.canSelect = true;
    this.canEdit = this.checkMenusPermitidos('/registro/id');
    this.canCreate=this.checkMenusPermitidos('/registro/nuevo');
  }

  checkMenusPermitidos(url) {
    let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
    let response = false;
    url = url.replace(/\d+/g, "id");
    if (menus){
      for (let i = 0; i < menus.length; i++) {          
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
    }
    return response;
  }

  seleccionarPagina(pageInfo){
    this.pagina.numeroPagina = pageInfo.offset;
    this.obtenerData();
  }

  obtenerData(){
    console.log('data a buscar')
    console.log(this.params);

    let paramsBuscar: any = {
      fechaDesde: this.params.fechaDesde,
      fechaHasta: this.params.fechaHasta,
      codigoPaciente: this.params.codigoPaciente,
      nombresPaciente: this.params.nombresPaciente,
      apellidosPaciente: this.params.apellidosPaciente,
      nroDocumentoPaciente: this.params.nroDocumentoPaciente,
      medicoPaciente: this.params.medicoPaciente,
      idUsuario : this.user.idUsuario,
      pagina: this.pagina.numeroPagina,
      cantidadPagina: this.pagina.cantidadPagina
      
    }

    if (this.params.fechaDesde){
      let momentDesde = moment(this.params.fechaDesde);
      if (momentDesde.isValid()){
        paramsBuscar.fechaDesde = momentDesde.format('MM/DD/YYYY');
      }
    }

    if (this.params.fechaHasta){
      let momentHasta = moment(this.params.fechaHasta);
      if (momentHasta.isValid()){
        paramsBuscar.fechaHasta = momentHasta.format('MM/DD/YYYY');
      }
    }

    if(this.user.idUsuario==0){
      paramsBuscar.estadoUsuario =1
    }else{
      paramsBuscar.estadoUsuario =0;
    }


    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centroPaciente = this.params.centro.idCentro;
    }

    if (this.params.genero){
      paramsBuscar.generoPaciente = this.params.genero.codigo;
    }
    

    this.registroService.getRegistrosFiltrado(paramsBuscar).subscribe( data => {
       console.log(data,'data')
      console.log(data.content,'data.conten')
        this.data = data.content;
        this.pagina.total = data.totalElements;
      },
      error => this.showError('Error inesperado')
    );
    /*
    this.pacienteService.getPacientes().subscribe(data => {
      this.data = data.content;
      console.log(data);
    },
    error => this.showError('Error inesperado'),
    );
    */
  }

  getCurrentCentro() {
    this.currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    console.log(this.currentCentro,'id centro');
  }

 /* getUsuarios(){
   let usuario : any;
   usuario = this.user.idUsuario;
    console.log(this.user,'USEEER ');
    console.log(usuario,'UUU****')
   this.usuarioService.getUserById(usuario)
        .subscribe(
          data=>{
            console.log(data,'DATA');
            usuario=data.idUsuario 
            //console.log(usuario,'usuario de salida  33')
            }
        );
        this.usuarios=usuario;
        console.log(this.usuarios,'usuario de salida 22');
    }*/
    getCurrentUsuario(){
      let perfil:any[];
      let per :any;
      let pert :any[] ;
      
      this.user = JSON.parse(sessionStorage.getItem('currentUser'));
  
     perfil = this.user.admTrPerfilxusuarios;
  
      for(var i=0 ;i<perfil.length;i++){
  
        per =perfil[i].admTmPerfil.idPerfil; 
        console.log(per ,'ID DE PERFIl PER ') ;
      };
      
        if(per == 2 ){
  
          this.user.idUsuario = null;
        }
        else if (per ==1){

          this.user.idUsuario =0;
        }
       else {
         this.user.idUsuario = this.user.idUsuario;
       }
       console.log(this.user.idUsuario,'ID USUARIO SEGUN PERFIL');
    }
 

  getCentros(){

    console.log(this.user.idUsuario,'usuario a necesitar');
    this.centroService.getCentros().subscribe(
      data=>{

  
        this.centros=data;
        console.log(this.centros,'centroo');
        for (var k = 0; k < this.centros.length; k++){
          if(this.user.idUsuario == 0){
            this.params.centro = 0;
            this.params.idUsuario = this.user.idUsuario;
           }
           else{   
            if(this.currentCentro.idCentro==this.centros[k].idCentro){
                this.params.centro=this.centros[k];
                console.log(this.user.idUsuario);
                this.params.idUsuario = this.user.idUsuario;
                break;
            }
          }
        } 
       
        this.obtenerData();            
      },
      error => this.showError('Error obtener centros')
    );

  
  }
  /****************************************************************************************************
                              EVENTOS DE CARGA DE EXCEL
  ****************************************************************************************************/

  descargarExcel(){
    window.open('assets/plantilla/FormularioRegistro.xlsx');
  }

  cargarExcel(evt: any){
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
      const wsDatos: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];

      /* save data */
      let hojaData = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
      let hojaListas = <AOA>(XLSX.utils.sheet_to_json(wsDatos, {header: 1}));

      var dataResultado = {
        idCentro: 0,
        demografico: {},
        antecedentes: {},
        diagnostico: {},
        eventosHemorragicos: {},
      //  episodios:[],
        comorbilidades: { enfermedades:[] },
        inhibidores: {},
        tratamiento: {},
        antecedentesFamiliares: {}
     //   antecetendesMedicos:[]
      };
      
      var error = this.leerDemograficos(dataResultado, hojaData, hojaListas);
      
      if (!error){

        // Se envia la data al BE

        let centro = JSON.parse(sessionStorage.getItem('currentCentro'));

        dataResultado.idCentro = centro.idCentro;
      
        console.log('dataResultado');
        console.log(dataResultado);
        
       this.registroService.createExcelRegistro(dataResultado).subscribe(dataCreacion => {
            if (dataCreacion['codigoValidacion'] == 2){
              this.showSuccess('¡Excel cargado con éxito!');
              this.obtenerData();
            }else{
              this.showError(dataCreacion['mensajeValidacion']);
            }
          },
          error => this.showError('Error inesperado')
        );
        console.log(JSON.stringify(dataResultado));
      }else{
        this.showError('Error en la hoja Demografia: ' + error);
      }

      // Se limpia el input
      this.excelInput.nativeElement.value = "";
    };
    reader.readAsBinaryString(target.files[0]);
  }

  leerDemograficos(dataResultado, hojaDatos, hojaListas){
    try{
      
      console.log(hojaDatos[11][6]);

      dataResultado.demografico.fecha = this.obtenerDateDeExcel(hojaDatos[4][4]); //moment(hojaDatos[4][4], 'DD/MM/YYYY').toDate();
      dataResultado.demografico.apellidoPaterno = hojaDatos[8][0];
      dataResultado.demografico.apellidoMaterno = hojaDatos[8][4];
      dataResultado.demografico.nombresPaciente = hojaDatos[9][1];
      var dia = "" + hojaDatos[11][2];
      if (dia.length == 1){
        dia = "0" + hojaDatos[11][2];
      }
      var mes = "" + hojaDatos[11][3];
      if (mes.length == 1){
        mes = "0" + hojaDatos[11][3];
      }
      dataResultado.demografico.fechaNacimiento = moment('' + dia + mes + hojaDatos[11][4], 'DDMMYYYY').toDate();
     // dataResultado.demografico.edad = parseInt(hojaDatos[10][7]);
      if (hojaDatos[11][6] == "FEMENINO"){//if (hojaDatos[11][7] = 'FEMENINO'){
        dataResultado.demografico.genero = 'F';
      }else{
        dataResultado.demografico.genero = 'M';
      }
      
      dataResultado.demografico.tipoDocumento = hojaDatos[12][2];
      dataResultado.demografico.numeroDocumento = '' + hojaDatos[12][6];
      //
      dataResultado.demografico.telefono = '' + hojaDatos[13][2];
      dataResultado.demografico.celular = '' + hojaDatos[13][6];
      //
      dataResultado.demografico.ocupacion = hojaDatos[14][2];

      //dataResultado.demografico.direccionNacimiento = hojaDatos[15][2];
      dataResultado.demografico.paisNacimiento = hojaDatos[17][0];
      dataResultado.demografico.departamentoNacimiento = hojaDatos[17][2];
      dataResultado.demografico.provinciaNacimiento = hojaDatos[17][4];
      dataResultado.demografico.distritoNacimiento = hojaDatos[17][6];


      dataResultado.demografico.direccionActual = hojaDatos[19][2];
      dataResultado.demografico.paisActual = hojaDatos[21][0];
      dataResultado.demografico.departamentoActual = hojaDatos[21][2];
      dataResultado.demografico.provinciaActual = hojaDatos[21][4];
      dataResultado.demografico.distritoActual = hojaDatos[21][6];

      dataResultado.demografico.personaContacto = hojaDatos[23][0];
      dataResultado.demografico.personaContactoParentezco = hojaDatos[23][2];
      dataResultado.demografico.personaContactoTelefono = '' + hojaDatos[23][4];
      dataResultado.demografico.personaContactoCelular = '' + hojaDatos[23][6];

      //Jeancarlo A. 01/07/21
      if(hojaDatos[24][2] == 'Pre escolar'){
        dataResultado.demografico.idGradoInstruccion = 1;
      }else if (hojaDatos[24][2] == 'Primario'){
        dataResultado.demografico.idGradoInstruccion = 2;
      }else if(hojaDatos[24][2] == 'Secundario'){
        dataResultado.demografico.idGradoInstruccion = 3;
      }else if(hojaDatos[24][2] == 'Superior'){
        dataResultado.demografico.idGradoInstruccion = 4;
      }

      return this.leerDiagnostico(dataResultado, hojaDatos, hojaListas);
     // return this.leerAntecedentes(dataResultado, hojaDatos, hojaListas);

    } catch (Error)  {
      console.log('Error en la sección Demografia: ' + Error.message);
      return Error.message;
    }
  }

  leerAntecedentes(dataResultado, hojaDatos, hojaListas){
    try{

    /*  dataResultado.antecedentes.edadDiagnostico = parseInt(hojaDatos[27][6]);
      dataResultado.antecedentes.mesDiagnostico = parseInt(hojaDatos[27][7]);
      dataResultado.antecedentes.porClinica = (hojaDatos[29][4] == 'SI' ? 1 : 2);
      dataResultado.antecedentes.porLaboratorio = (hojaDatos[29][8] == 'SI' ? 1 : 2);

      dataResultado.antecedentes.perfilCoagulacion = (hojaDatos[32][0] == 'SI' ? 'Sí' : 'No');
      dataResultado.antecedentes.dosajeFactor = (hojaDatos[32][4] == 'SI' ? 'Sí' : 'No');
      dataResultado.antecedentes.evaluacionHospitalizacion = (hojaDatos[33][8] == 'SI' ? 1 : 2);
      dataResultado.antecedentes.edadHospitalizacion = parseInt(hojaDatos[36][6]);
      dataResultado.antecedentes.mesesHospitalizacion = parseInt(hojaDatos[36][7]);
      dataResultado.antecedentes.localizacionSangrado = hojaDatos[37][5];*/

      dataResultado.antecedentes.edadDiagnostico = parseInt(hojaDatos[32][6]);
      dataResultado.antecedentes.mesDiagnostico = parseInt(hojaDatos[32][7]);
      dataResultado.antecedentes.porClinica = (hojaDatos[34][3] == 'SI' ? 1 : 2);
      dataResultado.antecedentes.porLaboratorio = (hojaDatos[34][7] == 'SI' ? 1 : 2);

      dataResultado.antecedentes.perfilCoagulacion = (hojaDatos[37][0] == 'SI' ? 'Sí' : 'No');
      dataResultado.antecedentes.dosajeFactor = (hojaDatos[37][4] == 'SI' ? 'Sí' : 'No');
      dataResultado.antecedentes.evaluacionHospitalizacion = (hojaDatos[38][7] == 'SI' ? 1 : 2);
      dataResultado.antecedentes.edadHospitalizacion = parseInt(hojaDatos[41][6]);
      dataResultado.antecedentes.mesesHospitalizacion = parseInt(hojaDatos[41][7]);
      //dataResultado.antecedentes.localizacionSangrado = hojaDatos[42][5];
      //dataResultado.antecedentes.informacionAdicional = hojaDatos[43][5];
      
      //Jeancarlo A. 23-06-21 Localizaciones del sangrado
      dataResultado.antecedentes.localizaciones = [];

      if (hojaDatos[44][3] == 'X' || hojaDatos[44][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 1,
          nombreOtros: hojaDatos[44][4],
        })
      }
      if (hojaDatos[45][3] == 'X' || hojaDatos[45][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 2,
          nombreOtros: hojaDatos[45][4],
        })
      }
      if (hojaDatos[46][3] == 'X' || hojaDatos[46][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 3,
          nombreOtros: hojaDatos[46][4],
        })
      }
      if (hojaDatos[47][3] == 'X' || hojaDatos[47][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 4,
          nombreOtros: hojaDatos[47][4],
        })
      }
      if (hojaDatos[48][3] == 'X' || hojaDatos[48][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 5,
          nombreOtros: hojaDatos[48][4],
        })
      }
      if (hojaDatos[49][3] == 'X' || hojaDatos[49][3] == 'x'){  
        dataResultado.antecedentes.localizaciones.push({
          idLocalCuerpo: 6,
          nombreOtros: hojaDatos[49][4],
        })
      }

      //
      
      let HepatitisA = {
        idLovEnfermedad: 1,
        nombreOtro: null,
        tipo:'VACUNA',
       // Fecha1:moment(hojaDatos[47][2], "DD/MM/YYYY").toDate(),
       fecha1:this.obtenerDateDeExcel(hojaDatos[53][2]),
      //  Fecha2:moment(hojaDatos[47][4], "DD/MM/YYYY").toDate(),
      fecha2:this.obtenerDateDeExcel(hojaDatos[53][4]),
      //  Fecha3:moment(hojaDatos[47][6], "DD/MM/YYYY").toDate(),
      fecha3:null,
      //fecha3No:hojaDatos[47][7] == 'X' ? 1 : 0,
      fecha1No:hojaDatos[53][3] == 'X' ? 1 : 0,
      fecha2No:hojaDatos[53][5] == 'X' ? 1 : 0,
      fecha3No: 0,
      resultado: null,
      idOtraEnfermedad: null,
      idProcQuirurgico: null,
      idYNVida: null,
      };
      
      let HepatitisB = {
        idLovEnfermedad: 2,
        nombreOtro: null,
        tipo:'VACUNA',
       // Fecha1:moment(hojaDatos[47][2], "DD/MM/YYYY").toDate(),
       fecha1:this.obtenerDateDeExcel(hojaDatos[54][2]),
      //  Fecha2:moment(hojaDatos[47][4], "DD/MM/YYYY").toDate(),
      fecha2:this.obtenerDateDeExcel(hojaDatos[54][4]),
      //  Fecha3:moment(hojaDatos[47][6], "DD/MM/YYYY").toDate(),
      fecha3:this.obtenerDateDeExcel(hojaDatos[54][6]),
      //fecha3No:hojaDatos[47][7] == 'X' ? 1 : 0,
      fecha1No:hojaDatos[54][3] == 'X' ? 1 : 0,
      fecha2No:hojaDatos[54][5] == 'X' ? 1 : 0,
      fecha3No:hojaDatos[54][7] == 'X' ? 1 : 0,
      resultado: null,
      idOtraEnfermedad: null,
      idProcQuirurgico: null,
      idYNVida: null,
      };

      dataResultado.comorbilidades.enfermedades.push(HepatitisA);
      dataResultado.comorbilidades.enfermedades.push(HepatitisB);
        return this.leerAntecedentesFamiliares(dataResultado, hojaDatos, hojaListas);
      //return this.leerDiagnostico(dataResultado, hojaDatos, hojaListas);
      
    } catch (Error)  {
      console.log('Error en la sección Antecedentes: ' + Error.message);
      return Error.message;
    }
  }

  leerDiagnostico(dataResultado, hojaDatos, hojaListas){
    try{
     /* dataResultado.diagnostico.factor = hojaDatos[41][2];
      dataResultado.diagnostico.percentajeFactor = parseInt(hojaDatos[41][6]);
      dataResultado.diagnostico.severidad = hojaDatos[42][6];*/
      dataResultado.diagnostico.factor = hojaDatos[27][2];
      dataResultado.diagnostico.percentajeFactor = parseInt(hojaDatos[27][6]);
      dataResultado.diagnostico.severidad = hojaDatos[28][6];

     // return this.leerEventosHemorragicos(dataResultado, hojaDatos, hojaListas);
      return this.leerAntecedentes(dataResultado,hojaDatos,hojaListas);
    } catch (Error)  {
      console.log('Error en la sección Diagnostico: ' + Error.message);
      return Error.message;
    }
  }

  leerEventosHemorragicos(dataResultado, hojaDatos, hojaListas){
    try{

      // Episodios hemorrágicos
     // dataResultado.eventosHemorragicos.episodios = [];
     /* this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[47]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[48]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[49]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[50]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[51]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[52]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[53]);
      this.leerArticulacion(dataResultado.eventosHemorragicos.episodios, hojaDatos[54]);*/
     // this.leerArticulacion
     // dataResultado.episodios = [];

     dataResultado.eventosHemorragicos.episodios = [];

      if (hojaDatos[69][3] == 'X' || hojaDatos[69][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 1,
          numeroEpisodios: hojaDatos[69][4],
        })
      }
      if (hojaDatos[70][3] == 'X' || hojaDatos[70][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 2,
          numeroEpisodios: hojaDatos[70][4],
        })
      }
      if (hojaDatos[71][3] == 'X' || hojaDatos[71][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 3,
          numeroEpisodios: hojaDatos[71][4],
        })
      }
      if (hojaDatos[72][3] == 'X' || hojaDatos[72][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 4,
          numeroEpisodios: hojaDatos[72][4],
        })
      }
      if (hojaDatos[73][3] == 'X' || hojaDatos[73][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 5,
          numeroEpisodios: hojaDatos[73][4],
        })
      }
      if (hojaDatos[74][3] == 'X' || hojaDatos[74][3] == 'x'){  
        dataResultado.eventosHemorragicos.episodios.push({
          idLocalCuerpo: 6,
          numeroEpisodios: hojaDatos[74][4],
        })
      }
      

      // Articulaciones afectadas
      dataResultado.eventosHemorragicos.articulaciones = [];
      if (hojaDatos[78][3] == 'X' || hojaDatos[78][3] == 'x'){  // Rodilla izquierda
        dataResultado.eventosHemorragicos.articulaciones.push(1)
      }
      if (hojaDatos[78][4] == 'X' || hojaDatos[78][4] == 'x'){  // Rodilla derecha
        dataResultado.eventosHemorragicos.articulaciones.push(2)
      }
      if (hojaDatos[79][3] == 'X' || hojaDatos[79][3] == 'x'){  // Tobillo izquierdo
        dataResultado.eventosHemorragicos.articulaciones.push(3)
      }
      if (hojaDatos[79][4] == 'X' || hojaDatos[79][4] == 'x'){  // Tobillo derecho
        dataResultado.eventosHemorragicos.articulaciones.push(4)
      }
      if (hojaDatos[80][3] == 'X' || hojaDatos[80][3] == 'x'){  // Codo izquierdo
        dataResultado.eventosHemorragicos.articulaciones.push(7)
      }
      if (hojaDatos[80][4] == 'X' || hojaDatos[80][4] == 'x'){  // Codo derecho
        dataResultado.eventosHemorragicos.articulaciones.push(8)
      }
      if (hojaDatos[81][3] == 'X' || hojaDatos[81][3] == 'x'){  // Hombro izquierdo
        dataResultado.eventosHemorragicos.articulaciones.push(5)
      }
      if (hojaDatos[81][4] == 'X' || hojaDatos[81][4] == 'x'){  // Hombro derecho
        dataResultado.eventosHemorragicos.articulaciones.push(6)
      }
    /*  if (hojaDatos[62][3] && hojaDatos[62][3].length > 0 && hojaDatos[62][3].trim() != ""){
        dataResultado.eventosHemorragicos.enfermedadArticularOtro = hojaDatos[62][3];
      }*/
      // Actividades
      dataResultado.eventosHemorragicos.gradoActividad = hojaDatos[82][3];
     // dataResultado.eventosHemorragicos.gradoActividad = hojaDatos[63][3];

      // Puntajes
      dataResultado.eventosHemorragicos.puntajes = [];
      let dataInflamacion = this.obtenerFilaPuntajesInflamacion(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataInflamacion)
      let dataDuracionInflamacion = this.obtenerFilaPuntajesDuracionInflamacion(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataDuracionInflamacion)
      let dataAtrofia = this.obtenerFilaPuntajesAtrofia(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataAtrofia)
      let dataCrepitacion = this.obtenerFilaPuntajesCrepitacion(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataCrepitacion)
      let dataFlexion = this.obtenerFilaPuntajesFlexion(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataFlexion)
      let dataExtension = this.obtenerFilaPuntajesExtension(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataExtension)
      let dataDolor = this.obtenerFilaPuntajesDolor(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataDolor)
      let dataFuerza = this.obtenerFilaPuntajesFuerza(hojaDatos, hojaListas);
      dataResultado.eventosHemorragicos.puntajes.push(dataFuerza)
      //let dataMovimiento = this.obtenerFilaPuntajesMovimiento(hojaDatos, hojaListas);
      //dataResultado.eventosHemorragicos.puntajes.push(dataMovimiento);

      //Jeancarlo A. 06-07-21
      if(hojaDatos[94][2] == 'No evaluable'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje='';
      }else if(hojaDatos[94][2] == 'Habilidades dentro de límites normales'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje=29;
      }else if(hojaDatos[94][2] == '1 habilidad no está en límites normales'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje=30;
      }else if(hojaDatos[94][2] == '2 habilidades no están en límites normales'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje=31;
      }else if(hojaDatos[94][2] == '3 habilidades no están en límites normales'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje=32;
      }else if(hojaDatos[94][2] == 'Ninguna habilidad en límites normales'){
        dataResultado.eventosHemorragicos.idScoreMarchaPuntaje=33;
      }


      //dataResultado.eventosHemorragicos.idScoreMarchaPuntaje = this.obtenerFilaPuntajesMovimiento(hojaDatos, hojaListas);

      dataResultado.eventosHemorragicos.noArticulares = [];
   /*   if (hojaDatos[78][2] == 'x' || hojaDatos[78][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push(hojaDatos[78][0]);
      }
      if (hojaDatos[78][5] == 'x' || hojaDatos[78][5] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push(hojaDatos[78][3]);
      }
      if (hojaDatos[79][2] == 'x' || hojaDatos[79][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push(hojaDatos[79][0]);
      }
      if (hojaDatos[79][5] == 'x' || hojaDatos[79][5] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push(hojaDatos[79][3]);
      }
      if (hojaDatos[80][2] == 'x' || hojaDatos[80][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push(hojaDatos[80][0]);
      }*/

      if (hojaDatos[97][2] == 'x' || hojaDatos[97][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 1,
        })
      }
      if (hojaDatos[97][6] == 'x' || hojaDatos[97][6] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 2,
        })
      }
      if (hojaDatos[98][2] == 'x' || hojaDatos[98][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 3,
        })
      }
      if (hojaDatos[98][6] == 'x' || hojaDatos[98][6] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 4,
        })
      }
      if (hojaDatos[99][2] == 'x' || hojaDatos[99][2] == 'X'){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 5,
        })
      }
      if (hojaDatos[99][5] != null || hojaDatos[99][5] != undefined){
        dataResultado.eventosHemorragicos.noArticulares.push({
          idNoArticulacion: 6,
          nombreOtros: hojaDatos[99][5],
        })
      }

    

      
return this.leerTratamiento(dataResultado, hojaDatos, hojaListas);

     // return this.leerComorbilidades(dataResultado, hojaDatos, hojaListas);
      
    } catch (Error) {
      console.log('Error en la sección Eventos Hemorragiocos: ' + Error.message);
      return Error.message;
    }
  }

  leerArticulacion(arreglo, fila){
    if (fila[2] && fila[2] > 0){
      arreglo.push({
        articulacion: fila[0],
        anho: fila[2],
        cantidad: fila[3]
      })
    }
    if (fila[4] && fila[4] > 0){
      arreglo.push({
        articulacion: fila[0],
        anho: fila[4],
        cantidad: fila[5]
      })
    }
    if (fila[6] && fila[6] > 0){
      arreglo.push({
        articulacion: fila[0],
        anho: fila[6],
        cantidad: fila[7]
      })
    }
  }

  obtenerFilaPuntajesInflamacion(hojaDatos, hojaListas){
    let data: any = { idScore: 1};
 /*   let listaScoresPuntaje = [
      { nombre: hojaListas[11][0], idScore: hojaListas[11][1]},
      { nombre: hojaListas[12][0], idScore: hojaListas[12][1]},
      { nombre: hojaListas[13][0], idScore: hojaListas[13][1]},
      { nombre: hojaListas[14][0], idScore: hojaListas[14][1]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][18], idScore: hojaListas[2][19]},
      { nombre: hojaListas[3][18], idScore: hojaListas[3][19]},
      { nombre: hojaListas[4][18], idScore: hojaListas[4][19]},
      { nombre: hojaListas[5][18], idScore: hojaListas[5][19]}
    ];
   // console.log(listaScoresPuntaje);
    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[67][2 + i];
     let textoCelda = hojaDatos[86][2 + i];
    // console.log(textoCelda);
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesDuracionInflamacion(hojaDatos, hojaListas){
    let data: any = { idScore: 2};
   /* let listaScoresPuntaje = [
      { nombre: hojaListas[11][2], idScore: hojaListas[11][3]},
      { nombre: hojaListas[12][2], idScore: hojaListas[12][3]}
    ];*/

    let listaScoresPuntaje = [
      { nombre: hojaListas[2][20], idScore: hojaListas[2][21]},
      { nombre: hojaListas[3][20], idScore: hojaListas[3][21]}
    ];

    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[68][2 + i];
     let textoCelda = hojaDatos[87][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesAtrofia(hojaDatos, hojaListas){
    let data: any = { idScore: 3};
  /*  let listaScoresPuntaje = [
      { nombre: hojaListas[11][4], idScore: hojaListas[11][5]},
      { nombre: hojaListas[12][4], idScore: hojaListas[12][5]},
      { nombre: hojaListas[13][4], idScore: hojaListas[13][5]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][22], idScore: hojaListas[2][23]},
      { nombre: hojaListas[3][22], idScore: hojaListas[3][23]},
      { nombre: hojaListas[4][22], idScore: hojaListas[4][23]}
    ];
  //  console.log(listaScoresPuntaje);
    for (var i = 0; i < 6; i++){
    //  let textoCelda = hojaDatos[69][2 + i];
    let textoCelda = hojaDatos[88][2 + i];
  //  console.log(textoCelda);
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesCrepitacion(hojaDatos, hojaListas){
    let data: any = { idScore: 4};
   /* let listaScoresPuntaje = [
      { nombre: hojaListas[11][6], idScore: hojaListas[11][7]},
      { nombre: hojaListas[12][6], idScore: hojaListas[12][7]},
      { nombre: hojaListas[13][6], idScore: hojaListas[13][7]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][24], idScore: hojaListas[2][25]},
      { nombre: hojaListas[3][24], idScore: hojaListas[3][25]},
      { nombre: hojaListas[4][24], idScore: hojaListas[4][25]}
    ];

    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[70][2 + i];

     let textoCelda = hojaDatos[89][2 + i];

      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesFlexion(hojaDatos, hojaListas){
    let data: any = { idScore: 5};
   /* let listaScoresPuntaje = [
      { nombre: hojaListas[11][8], idScore: hojaListas[11][9]},
      { nombre: hojaListas[12][8], idScore: hojaListas[12][9]},
      { nombre: hojaListas[13][8], idScore: hojaListas[13][9]}
    ];*/

    let listaScoresPuntaje = [
      { nombre: hojaListas[2][26], idScore: hojaListas[2][27]},
      { nombre: hojaListas[3][26], idScore: hojaListas[3][27]},
      { nombre: hojaListas[4][26], idScore: hojaListas[4][27]},
      { nombre: hojaListas[5][26], idScore: hojaListas[5][27]}
    ];
    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[71][2 + i];
     //let textoCelda = hojaDatos[81][2 + i];
     let textoCelda = hojaDatos[90][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesExtension(hojaDatos, hojaListas){
    let data: any = { idScore: 6};
   /* let listaScoresPuntaje = [
      { nombre: hojaListas[17][0], idScore: hojaListas[17][1]},
      { nombre: hojaListas[18][0], idScore: hojaListas[18][1]},
      { nombre: hojaListas[19][0], idScore: hojaListas[19][1]},
      { nombre: hojaListas[20][0], idScore: hojaListas[20][1]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][28], idScore: hojaListas[2][29]},
      { nombre: hojaListas[3][28], idScore: hojaListas[3][29]},
      { nombre: hojaListas[4][28], idScore: hojaListas[4][29]},
      { nombre: hojaListas[5][28], idScore: hojaListas[5][29]}
    ];
    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[72][2 + i];
     let textoCelda = hojaDatos[91][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesDolor(hojaDatos, hojaListas){
    let data: any = { idScore: 7};
   /* let listaScoresPuntaje = [
      { nombre: hojaListas[17][2], idScore: hojaListas[17][3]},
      { nombre: hojaListas[18][2], idScore: hojaListas[18][3]},
      { nombre: hojaListas[19][2], idScore: hojaListas[19][3]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][30], idScore: hojaListas[2][31]},
      { nombre: hojaListas[3][30], idScore: hojaListas[3][31]},
      { nombre: hojaListas[4][30], idScore: hojaListas[4][31]}
    ];
    for (var i = 0; i < 6; i++){
     // let textoCelda = hojaDatos[73][2 + i];
     let textoCelda = hojaDatos[92][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  obtenerFilaPuntajesFuerza(hojaDatos, hojaListas){
    let data: any = { idScore: 8};
  /*  let listaScoresPuntaje = [
      { nombre: hojaListas[17][4], idScore: hojaListas[17][5]},
      { nombre: hojaListas[18][4], idScore: hojaListas[18][5]},
      { nombre: hojaListas[19][4], idScore: hojaListas[19][5]},
      { nombre: hojaListas[20][4], idScore: hojaListas[20][5]},
      { nombre: hojaListas[21][4], idScore: hojaListas[21][5]}
    ];*/
    let listaScoresPuntaje = [
      { nombre: hojaListas[2][32], idScore: hojaListas[2][33]},
      { nombre: hojaListas[3][32], idScore: hojaListas[3][33]},
      { nombre: hojaListas[4][32], idScore: hojaListas[4][33]},
      { nombre: hojaListas[5][32], idScore: hojaListas[5][33]},
      { nombre: hojaListas[6][32], idScore: hojaListas[6][33]}
    ];
    for (var i = 0; i < 6; i++){
    //  let textoCelda = hojaDatos[74][2 + i];
    let textoCelda = hojaDatos[93][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return data;
  }

  /*obtenerFilaPuntajesMovimiento(hojaDatos, hojaListas){
  /*  let listaScoresPuntaje = [
      { nombre: hojaListas[17][6], idScore: hojaListas[17][7]},
      { nombre: hojaListas[18][6], idScore: hojaListas[18][7]},
      { nombre: hojaListas[19][6], idScore: hojaListas[19][7]},
      { nombre: hojaListas[20][6], idScore: hojaListas[20][7]},
      { nombre: hojaListas[21][6], idScore: hojaListas[21][7]}
    ];*/

    /*let listaScoresPuntaje = [
      { nombre: hojaListas[1][34], idScore: 0},
      { nombre: hojaListas[2][34], idScore: hojaListas[1][35]},
      { nombre: hojaListas[3][34], idScore: hojaListas[2][35]},
      { nombre: hojaListas[4][34], idScore: hojaListas[3][35]},
      { nombre: hojaListas[5][34], idScore: hojaListas[4][35]},
      { nombre: hojaListas[6][34], idScore: hojaListas[5][35]}
    ];*/

    /*
    for (var i = 0; i < 6; i++){
      let textoCelda = hojaDatos[75][2 + i];
      var puntaje = listaScoresPuntaje[0].idScore; // Defecto
      if (textoCelda && textoCelda.length > 0){
        let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
        if (resultado){
          puntaje = resultado.idScore;
        }
      }
      switch(i) { 
        case 0: { data.rodillaIzquierda = puntaje; break; }
        case 1: { data.rodillaDerecha = puntaje; break; } 
        case 2: { data.tobilloIzquierdo = puntaje; break; } 
        case 3: { data.tobilloDerecho = puntaje; break; } 
        case 4: { data.codoIzquierdo = puntaje; break; } 
        case 5: { data.codoDerecho = puntaje; break; }
      }
    }
    return puntaje
    */
  //  let textoCelda = hojaDatos[75][2];
  /*let textoCelda = hojaDatos[94][2];
    var puntaje = listaScoresPuntaje[0].idScore; // Defecto
    if (textoCelda && textoCelda.length > 0){
      let resultado = listaScoresPuntaje.find(x => x.nombre == textoCelda);
      if (resultado){
        puntaje = resultado.idScore;
      }
    }
    return puntaje;
  }*/

  leerComorbilidades(dataResultado, hojaDatos, hojaListas){
    try{      
  //    dataResultado.comorbilidades.enfermedades = [];
 /*     this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 1, null, 'VACUNA', hojaDatos[85][2], hojaDatos[85][4], null );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 2, null, 'VACUNA', hojaDatos[86][2], hojaDatos[86][4], null );
*/
  /*    this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 3, null, 'SEROLOGICA', hojaDatos[89][2], null, hojaDatos[89][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 4, null, 'SEROLOGICA', hojaDatos[90][2], null, hojaDatos[90][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 5, null, 'SEROLOGICA', hojaDatos[91][2], null, hojaDatos[91][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, 6, null, 'SEROLOGICA', hojaDatos[92][2], null, hojaDatos[92][4] );

      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, null, hojaDatos[96][0], 'OTROS', hojaDatos[96][2], null, hojaDatos[96][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, null, hojaDatos[97][0], 'OTROS', hojaDatos[97][2], null, hojaDatos[97][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, null, hojaDatos[98][0], 'OTROS', hojaDatos[98][2], null, hojaDatos[98][4] );
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades, null, hojaDatos[99][0], 'OTROS', hojaDatos[99][2], null, hojaDatos[99][4] );

      dataResultado.comorbilidades.cirugias = [];
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[104][0], hojaDatos[104][2], hojaDatos[104][4], hojaDatos[104][6]);
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[105][0], hojaDatos[105][2], hojaDatos[105][4], hojaDatos[105][6]);
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[106][0], hojaDatos[106][2], hojaDatos[106][4], hojaDatos[106][6]);
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[107][0], hojaDatos[107][2], hojaDatos[107][4], hojaDatos[107][6]);
*/

      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,3, null, 'SEROLOGICA', hojaDatos[130][2], null,null,0,0,0, hojaDatos[130][4],null,null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,4, null, 'SEROLOGICA', hojaDatos[131][2], null,null,0,0,0, hojaDatos[131][4],null,null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,5, null, 'SEROLOGICA', hojaDatos[132][2], null,null,0,0,0, hojaDatos[132][4],null,null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,6, null, 'SEROLOGICA', hojaDatos[133][2], null,null,0,0,0, hojaDatos[133][4],null,null,null);

      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[137][2],'OTROS',hojaDatos[137][4],null,null,0,0,0,hojaDatos[137][6],hojaDatos[137][0],null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[138][2],'OTROS',hojaDatos[138][4],null,null,0,0,0,hojaDatos[138][6],hojaDatos[138][0],null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[139][2],'OTROS',hojaDatos[139][4],null,null,0,0,0,hojaDatos[139][6],hojaDatos[139][0],null,null);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[140][2],'OTROS',hojaDatos[140][4],null,null,0,0,0,hojaDatos[140][6],hojaDatos[140][0],null,null);

      //dataResultado.comorbilidades.cirugias = [];
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[144][1],'CIRUGIA',hojaDatos[144][3],null,null,0,0,0,hojaDatos[144][6],null,hojaDatos[144][0],hojaDatos[144][5] == 'SI' ? 1 : 2);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[145][1],'CIRUGIA',hojaDatos[145][3],null,null,0,0,0,hojaDatos[145][6],null,hojaDatos[145][0],hojaDatos[145][5] == 'SI' ? 1 : 2);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[146][1],'CIRUGIA',hojaDatos[146][3],null,null,0,0,0,hojaDatos[146][6],null,hojaDatos[146][0],hojaDatos[146][5] == 'SI' ? 1 : 2);
      this.leerComorbilidad(dataResultado.comorbilidades.enfermedades,null,hojaDatos[147][1],'CIRUGIA',hojaDatos[147][3],null,null,0,0,0,hojaDatos[147][6],null,hojaDatos[147][0],hojaDatos[147][5] == 'SI' ? 1 : 2);
      /*this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[145][0], hojaDatos[145][1], hojaDatos[145][3], hojaDatos[145][5], hojaDatos[145][6]);
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[146][0], hojaDatos[146][1], hojaDatos[146][3], hojaDatos[146][5], hojaDatos[146][6]);
      this.leerCirugia(dataResultado.comorbilidades.cirugias, hojaDatos[147][0], hojaDatos[147][1], hojaDatos[147][3], hojaDatos[147][5], hojaDatos[147][6]);*/
      dataResultado.observaciones = hojaDatos[150][0];
   //   return this.leerInhibidores(dataResultado, hojaDatos, hojaListas);
   //return this.leerTratamiento(dataResultado, hojaDatos, hojaListas);
   return null;
      
    } catch (Error)  {
      console.log('Error en la sección Diagnostico: ' + Error.message);
      return Error.message;
    }
  }

  leerComorbilidad(listaComorbilidades, idLovEnfermedad, nombreOtro, tipo,fecha1,fecha2,fecha3,
    fecha1No,fecha2No,fecha3No,resultado,idOtraEnfermedad,idProcQuirurgico,idYNVida){
    if ((idLovEnfermedad || resultado) && fecha1){
      let enfermedad = {
        idLovEnfermedad: idLovEnfermedad,
        nombreOtro: nombreOtro,
        tipo: tipo,
        //fecha1: moment(fecha1).toDate(),
        fecha1: this.obtenerDateDeExcel(fecha1),
        //fecha2: moment(fecha2).toDate(),
        fecha2: this.obtenerDateDeExcel(fecha2),
        fecha3: this.obtenerDateDeExcel(fecha3),
        fecha1No: fecha1No,
        fecha2No: fecha2No,
        fecha3No: fecha3No,
        resultado: resultado,
        idOtraEnfermedad: this.obtenerOtraEnfermedad(idOtraEnfermedad),
        idProcQuirurgico: this.obtenerProcedimientoQuirurgico(idProcQuirurgico),
        idYNVida: idYNVida,
      }
      listaComorbilidades.push(enfermedad);
    }
  }

  leerCirugia(listaCirugias, admTmLovProcedimientosQuirurgicos, nombreOtro,fecha1, admTmLovYesNo, resultado){
    if (nombreOtro && nombreOtro.length > 0 && nombreOtro.trim() != "" && (fecha1 != null || admTmLovProcedimientosQuirurgicos != null || admTmLovYesNo != null || resultado !=null)){
      listaCirugias.push({
        admTmLovProcedimientosQuirurgicos: admTmLovProcedimientosQuirurgicos,
        nombreOtro: nombreOtro,
        fecha1: this.obtenerDateDeExcel(fecha1),
        admTmLovYesNo: (admTmLovYesNo == 'SI' ? 'Sí' : 'No'),
        resultado: resultado
        //nombre: nombre,
        //fecha1: moment(fecha1, 'DD/MM/YYYY').toDate(),
        //fecha2: moment(fecha2, 'DD/MM/YYYY').toDate(),
        //fecha3: moment(fecha3, 'DD/MM/YYYY').toDate()
        //fecha1: this.obtenerDateDeExcel(fecha1),
        //fecha2: this.obtenerDateDeExcel(fecha2),
        //fecha3: this.obtenerDateDeExcel(fecha3),

      })
    }
  }

  leerInhibidores(dataResultado, hojaDatos, hojaListas){
    try{
      console.log(hojaDatos[120][2]);
  /*    dataResultado.inhibidores.dosajeInhibidores = (hojaDatos[110][2] == 'SI' ? 1 : 2);
      //dataResultado.inhibidores.fecha = moment(hojaDatos[110][4], 'DD/MM/YYYY').toDate();
      dataResultado.inhibidores.fecha = this.obtenerDateDeExcel(hojaDatos[110][4]);
      dataResultado.inhibidores.tituloUB = hojaDatos[110][6];
      dataResultado.inhibidores.regimenTratamiento = hojaDatos[111][2];
      dataResultado.inhibidores.tratamientoRecibe = hojaDatos[111][6];
      dataResultado.inhibidores.inmunotolerancia = (hojaDatos[112][3] == 'SI' ? 1 : 2);
      dataResultado.inhibidores.esquema = hojaDatos[113][2];

      return this.leerTratamiento(dataResultado, hojaDatos, hojaListas);*/

   /*   dataResultado.inhibidores.dosajeInhibidores = (hojaDatos[120][2] == 'SI' ? 1 : 2);
      //dataResultado.inhibidores.fecha = moment(hojaDatos[110][4], 'DD/MM/YYYY').toDate();
      dataResultado.inhibidores.fecha = this.obtenerDateDeExcel(hojaDatos[120][4]);
      dataResultado.inhibidores.tituloUB = hojaDatos[120][6];
      dataResultado.inhibidores.regimenTratamiento = hojaDatos[121][2];
      dataResultado.inhibidores.tratamientoRecibe = hojaDatos[121][6];
      dataResultado.inhibidores.inmunotolerancia = (hojaDatos[122][3] == 'SI' ? 1 : 2);
      dataResultado.inhibidores.esquema = hojaDatos[123][2];*/
     // return this.leerAntecedentesFamiliares(dataResultado, hojaDatos, hojaListas);


     //Jeancarlo A. 25-06-21
     if(hojaDatos[121][2] == 'Ausencia'){
      dataResultado.inhibidores.dosajeInhibidores = 1;
     }else if(hojaDatos[121][2] == 'Presencia'){
      dataResultado.inhibidores.dosajeInhibidores = 2;
     }else if(hojaDatos[121][2] == 'No se realizó dosaje'){
      dataResultado.inhibidores.dosajeInhibidores = 3;
     }
     dataResultado.inhibidores.fecha = this.obtenerDateDeExcel(hojaDatos[121][4]);
     dataResultado.inhibidores.tituloUB = parseFloat(hojaDatos[121][6]);
     
     dataResultado.inhibidores.regimenTratamiento = hojaDatos[122][2];
     dataResultado.inhibidores.tratamientoRecibe = hojaDatos[122][6];
     dataResultado.inhibidores.inmunotolerancia = (hojaDatos[123][3] == 'SI' ? 1 : 2);
        
     dataResultado.inhibidores.respuestainmuno =  hojaDatos[123][6];
     dataResultado.inhibidores.esquema = hojaDatos[124][2];
        

     //dataResultado.inhibidores.fecha = moment(hojaDatos[110][4], 'DD/MM/YYYY').toDate();
      
     return this.leerComorbilidades(dataResultado, hojaDatos, hojaListas);

    } catch (Error)  {
      console.log('Error en la sección Diagnostico: ' + Error.message);
      return Error.message;
    }
  }

  leerTratamiento(dataResultado, hojaDatos, hojaListas){
    try{
     
     /* dataResultado.tratamiento.episodioHemoTrata = hojaDatos[116][4];
      dataResultado.tratamiento.actualTratamiento = hojaDatos[117][4];
      dataResultado.tratamiento.tipoConcentradoFactor = hojaDatos[118][5];
      dataResultado.tratamiento.recibeProfilaxis = (hojaDatos[119][4] == 'SI' ? 1 : 2);
      dataResultado.tratamiento.tipoConFactor = hojaDatos[119][7];
      dataResultado.tratamiento.edadInicioProfilaxis = parseInt(hojaDatos[120][3]);
      dataResultado.tratamiento.mesesInicioProfilaxis = parseInt(hojaDatos[120][5]);
      //dataResultado.tratamiento.fechaInicioProfilaxis = moment(hojaDatos[121][2], 'DD/MM/YYYY').toDate();
      dataResultado.tratamiento.fechaInicioProfilaxis = this.obtenerDateDeExcel(hojaDatos[121][2]);
      //dataResultado.tratamiento.edadActual = hojaDatos[97][2];
      dataResultado.tratamiento.pesoActual = parseFloat(hojaDatos[123][2]);
      dataResultado.tratamiento.kilodosis = hojaDatos[123][4];
      dataResultado.tratamiento.frecuencia = hojaDatos[123][6];

      dataResultado.tratamiento.modoAdministracion = hojaDatos[124][2];
      if (dataResultado.tratamiento.modoAdministracion == 'Otro'){
        dataResultado.tratamiento.modoAdministracion = hojaDatos[124][5];
      }
      dataResultado.tratamiento.tratamientosRecibidos = hojaDatos[125][2];

      return this.leerAntecedentesFamiliares(dataResultado, hojaDatos, hojaListas);
      */
    /* dataResultado.tratamiento.episodioHemoTrata = hojaDatos[110][4];
      dataResultado.tratamiento.actualTratamiento = hojaDatos[111][4];
      dataResultado.tratamiento.tipoConcentradoFactor = hojaDatos[112][5];
      dataResultado.tratamiento.recibeProfilaxis = (hojaDatos[113][4] == 'SI' ? 1 : 2);
      dataResultado.tratamiento.tipoConFactor = hojaDatos[113][7];
      dataResultado.tratamiento.edadInicioProfilaxis = parseInt(hojaDatos[114][3]);
      dataResultado.tratamiento.mesesInicioProfilaxis = parseInt(hojaDatos[114][5]);
      //dataResultado.tratamiento.fechaInicioProfilaxis = moment(hojaDatos[121][2], 'DD/MM/YYYY').toDate();
      dataResultado.tratamiento.fechaInicioProfilaxis = this.obtenerDateDeExcel(hojaDatos[115][2]);
      //dataResultado.tratamiento.edadActual = hojaDatos[97][2];
      dataResultado.tratamiento.pesoActual = parseFloat(hojaDatos[117][2]);
      dataResultado.tratamiento.kilodosis = hojaDatos[117][4];
      dataResultado.tratamiento.frecuencia = hojaDatos[117][6];*/

      //Jeancarlo A. 24-06-21
      dataResultado.tratamiento.primerOtros = [];

      if(hojaDatos[104][3] == 'X' || hojaDatos[104][3] == 'x'){
        dataResultado.tratamiento.primerOtros.push({
          id1Otros:1,
        })
      }

      if(hojaDatos[105][3] == 'X' || hojaDatos[105][3] == 'x'){
        dataResultado.tratamiento.primerOtros.push({
          id1Otros:2,
        })
      }

      if(hojaDatos[106][3] == 'X' || hojaDatos[106][3] == 'x'){
        dataResultado.tratamiento.primerOtros.push({
          id1Otros:3,
        })
      }

      if(hojaDatos[107][3] == 'X' || hojaDatos[107][3] == 'x'){
        dataResultado.tratamiento.primerOtros.push({
          id1Otros:4,
        })
      }

      if(hojaDatos[108][3] == 'X' || hojaDatos[108][3] == 'x'){
        dataResultado.tratamiento.primerOtros.push({
          id1Otros:5,
          nombreOtros: hojaDatos[109][3],
        })
      }
      //

      //dataResultado.tratamiento.episodioHemoTrata = hojaDatos[96][4];
      //if(hojaDatos[96][4] == 'Otros'){
      //  dataResultado.tratamiento.episodioTrataOtro = hojaDatos[97][3];
      //}
      
      //dataResultado.tratamiento.actualTratamiento = hojaDatos[98][4];
      //if(hojaDatos[98][4] == 'Otros'){
      //  dataResultado.tratamiento.actualTrataOtro = hojaDatos[99][3];
      //}
      //Jeancarlo A. 24-06-21

      dataResultado.tratamiento.actualOtros = [];
      if (hojaDatos[110][4] == 'Sangre Total'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 1,
        })
      }
      if (hojaDatos[110][4] == 'Crioprecipitado'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 2,
        })
      }
      if (hojaDatos[110][4] == 'Plasma fresco congelado'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 3,
        })
      }
      if (hojaDatos[110][4] == 'Plasma fresco congelado'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 3,
        })
      }
      if (hojaDatos[110][4] == 'Concentrado de Factor'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 4,
        })
      }
      if (hojaDatos[110][4] == 'Otros'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 5,
          nombreOtros: hojaDatos[111][3],
        })
      }
      if (hojaDatos[110][4] == 'Ninguno'){  
        dataResultado.tratamiento.actualOtros.push({
          idActualOtros: 6,
        })
      }
      //
      if(hojaDatos[112][5] == 'Plasmatico'){
        dataResultado.tratamiento.idTipoConcentradosFact = 1;
      }else if(hojaDatos[112][5] == 'Recombinante estándar'){
        dataResultado.tratamiento.idTipoConcentradosFact = 2;
      }else if(hojaDatos[112][5] == 'Recombinante de vida media prolongada'){
        dataResultado.tratamiento.idTipoConcentradosFact = 3;
      }
      //
      dataResultado.tratamiento.idYNRecibeProfilaxis = (hojaDatos[113][4] == 'SI' ? 1 : 2);
      
      //
      if(hojaDatos[114][4] == 'Concentrado de factor'){
        dataResultado.tratamiento.idProfilaxisRealizada = 1;
      }else if(hojaDatos[114][4] == 'Emicizumab'){
        dataResultado.tratamiento.idProfilaxisRealizada = 2;
      }
      //

      if(hojaDatos[113][7] == 'Primaria'){
        dataResultado.tratamiento.idTipoProfilaxis = 1;
      }else if(hojaDatos[113][7] == 'Secundaria'){
        dataResultado.tratamiento.idTipoProfilaxis = 2;
      }else if(hojaDatos[113][7] == 'Terciaria'){
        dataResultado.tratamiento.idTipoProfilaxis = 3;
      }

      dataResultado.tratamiento.edadInicioProfilaxis = parseInt(hojaDatos[115][3]);
      dataResultado.tratamiento.mesesInicioProfilaxis = parseInt(hojaDatos[115][5]);
      //dataResultado.tratamiento.fechaInicioProfilaxis = moment(hojaDatos[121][2], 'DD/MM/YYYY').toDate();
     // dataResultado.tratamiento.fechaInicioProfilaxis = this.obtenerDateDeExcel(hojaDatos[115][2]);
      //dataResultado.tratamiento.edadActual = hojaDatos[97][2];
      dataResultado.tratamiento.pesoActual = parseFloat(hojaDatos[117][2]);
      dataResultado.tratamiento.tallaActual = parseFloat(hojaDatos[117][6]);
      dataResultado.tratamiento.kilodosis = hojaDatos[118][2];
      dataResultado.tratamiento.frecuencia = hojaDatos[118][6];

      return this.leerInhibidores(dataResultado, hojaDatos, hojaListas);

    
     
    } catch (Error)  {
      console.log('Error en la sección Diagnostico: ' + Error.message);
      return Error.message;
    }
  }

  leerAntecedentesFamiliares(dataResultado, hojaDatos, hojaListas){
    try{
      dataResultado.antecedentesFamiliares = [];
     /* this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[130]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[131]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[132]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[133]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[134]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[135]);
      dataResultado.observaciones = hojaDatos[138][0];*/

      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[59]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[60]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[61]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[62]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[63]);
      this.leerAntecedenteFamiliar(dataResultado.antecedentesFamiliares, hojaDatos[64]);
     // dataResultado.observaciones = hojaDatos[138][0];

      return this.leerEventosHemorragicos(dataResultado, hojaDatos, hojaListas);
      
    } catch (Error)  {
      console.log('Error en la sección Diagnostico: ' + Error.message);
      return Error.message;
    }
  }

  leerAntecedenteFamiliar(listaAntecedentesFamliares, filaDatos){
    if (filaDatos[0] && filaDatos[0].length > 0){
      listaAntecedentesFamliares.push({
        nombre: filaDatos[0],
      //  parentesco: filaDatos[4]
      parentesco: filaDatos[2],
      vive: filaDatos[4],
      edad: '' + filaDatos[6]
      })
    }
  }

  obtenerDateDeExcel(fechaExcel){
    if (fechaExcel){
      var fecha = new Date((fechaExcel - (25567 + 1))*86400*1000);
      if (fecha instanceof Date && !isNaN(fecha.getTime())){
        return fecha;
      }else{
        return null;
      }
    }else{
      return null;
    }
  }

  obtenerOtraEnfermedad(OtraEnfermedad){
    if(OtraEnfermedad == 'Hipertensión arterial'){
      return 1;
    }else if(OtraEnfermedad == 'Diabetes mellitus'){
      return 2;
    }else if(OtraEnfermedad == 'Coronariopatía'){
      return 3;
    }else if(OtraEnfermedad == 'Obesidad'){
      return 4;
    }else{
      return 5;
    }
  }

  obtenerProcedimientoQuirurgico(ProcQuirurgico){
    if(ProcQuirurgico == 'Sinoviortesis'){
      return 1;
    }else if(ProcQuirurgico == 'Ortopédica'){
      return 2;
    }else{
      return 3;
    }
  }


  /****************************************************************************************************
                              FIN EVENTOS DE CARGA DE EXCEL
  ****************************************************************************************************/

  verDetalle(idRegistro){
    //let link = ['/registro/ver/', idPaciente];
    let link = ['/registro/', idRegistro];
  //  this.router.navigate(['/registro/' + idRegistro]);
    this.router.navigate(link);
  }

  editarDetalle(idPaciente){
    console.log(idPaciente);
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

  ngOnInit() {
    console.log('listar registros');
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarUsuarios' });
    this.clear();
    this.checkPermisos();
    this.getCurrentUsuario();
    this.getCurrentCentro();
    this.getCentros();
    //this.reloadRegistro();
  //this.obtenerData();
  }

  reloadRegistro(){
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(user.idUsuario)
    let aux:any;
    this.registroService.getRegistroByIdUsuario(user.idUsuario).subscribe( data => {
      
     aux=data;
     console.log(aux,'data');
     this.data=aux;
       
     },
     error => this.showError('Error inesperado')
        );
  }
  
}
