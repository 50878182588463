import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from 'ng-sidebar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from "@angular/material";
import { MatPaginatorModule,MatSortModule, MatStepperModule,MatDatepickerModule, MatExpansionModule } from '@angular/material';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


// import { DataTableModule } from 'angular-2-data-table/dist';

/* Angular material 8 */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MenuGuard } from './guards/menu.guards';
import { AuthGuard } from './guards/auth.guards';
import { MenuService } from './services/admin/menu.service';
import { AuthenticationService } from './services/admin/authentication.service';
import { SocketService } from './services/emitter/socketservice';
import { UserService } from './services/admin/user.service';
import { LovsService } from './services/admin/lovs.service';
import { CentroService } from './services/mantenimiento/centro.service';
import { RegistroService } from './services/hemofilia/registro.service';
import { RegistroanteService } from './services/hemofilia/registroante.service';
import { RegistrodiagService } from './services/hemofilia/registrodiag.service';
import { RegistrohemoService } from './services/hemofilia/registrohemo.service';
import { PacienteService } from './services/mantenimiento/paciente.service';
import { RegistroinhibidoresService } from './services/hemofilia/registroinhibidores.service';
import { RegistromiembroFamiliarService } from './services/hemofilia/registro.miembrofamiliar.service';
//Añadido por Jeancarlo A. 21-04-21
import { RegistroEvolucionService } from './services/hemofilia/registroevolucion.service';
import { ReportesService } from './services/hemofilia/reportes.service';
// import { StompService } from 'ng2-stomp-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
//import { MenuHeaderComponent } from './components/menu-header/menu-header.component';
//import { MenuLeftComponent } from './components/menu-left/menu-left.component';
import { LoginComponent } from './components/login/login.component';

import { ElegirCentroComponent } from './components/hemofilia/registros/elegir-centro/elegir-centro.component';
import { HomeComponent } from './components/home/home.component';
import { ListarModuloComponent } from './components/admin/modulo/listar-modulo/listar-modulo.component';
import { ListarMenusComponent } from './components/admin/menu/listar-menu/listar-menu.component';
import { ListarPerfilesComponent } from './components/mantenimiento/perfiles/listar-perfiles/listar-perfiles.component';
import { RegistrarPerfilComponent } from './components/mantenimiento/perfiles/registrar-perfil/registrar-perfil.component';
import { EditarPerfilComponent } from './components/mantenimiento/perfiles/editar-perfil/editar-perfil.component';
import { VerPerfilComponent } from './components/mantenimiento/perfiles/ver-perfil/ver-perfil.component';

import { ListarUsuariosComponent } from './components/mantenimiento/usuarios/listar-usuarios/listar-usuarios.component';
import { DeleteModal } from './components/mantenimiento/usuarios/listar-usuarios/listar-usuarios.component';
import { RegistrarUsuarioComponent } from './components/mantenimiento/usuarios/registrar-usuario/registrar-usuario.component';
import { EditarUsuarioComponent } from './components/mantenimiento/usuarios/editar-usuario/editar-usuario.component';
import { VerUsuarioComponent } from './components/mantenimiento/usuarios/ver-usuario/ver-usuario.component';
import { CambiarContrasenaComponent } from './components/admin/usuarios/cambiar-contrasena/cambiar-contrasena.component';

import { ListarCentrosComponent } from './components/mantenimiento/centros/listar-centros/listar-centros.component';
import { RegistrarCentroComponent } from './components/mantenimiento/centros/registrar-centro/registrar-centro.component';
import { VerCentroComponent } from './components/mantenimiento/centros/ver-centro/ver-centro.component';
import { EditarCentroComponent } from './components/mantenimiento/centros/editar-centro/editar-centro.component';

import { ListarMedicamentosComponent } from './components/mantenimiento/medicamentos/listar-medicamentos/listar-medicamentos.component';
import { RegistrarMedicamentoComponent } from './components/mantenimiento/medicamentos/registrar-medicamento/registrar-medicamento.component';
import { EditarMedicamentoComponent } from './components/mantenimiento/medicamentos/editar-medicamento/editar-medicamento.component';
import { VerMedicamentoComponent } from './components/mantenimiento/medicamentos/ver-medicamento/ver-medicamento.component';

import { ListarPacientesComponent } from './components/mantenimiento/pacientes/listar-pacientes/listar-pacientes.component';
import { RegistrarPacienteComponent } from './components/mantenimiento/pacientes/registrar-paciente/registrar-paciente.component';


import { ListarRegistrosComponent } from './components/hemofilia/registros/listar-registros/listar-registros.component';
import { CargarRegistrosExcelComponent } from './components/hemofilia/registros/cargar-registros-excel/cargar-registros-excel.component';

import { ListarSolicitudesTransferenciaComponent } from './components/hemofilia/solicitud/listar-solicitudes-transferencia/listar-solicitudes-transferencia.component';
import { SolicitudTransferenciaPacienteComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente/solicitud-transferencia-paciente.component';
import { SolicitudTransferenciaPacienteAprobacionComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente-aprobacion/solicitud-transferencia-paciente-aprobacion.component';
import { SolicitudTransferenciaPacienteVerComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente-ver/solicitud-transferencia-paciente-ver.component';

import { RegistroDemografiaComponent } from './components/hemofilia/registros/registro-demografia/registro-demografia.component';
import { RegistroAntecedentesComponent } from './components/hemofilia/registros/registro-antecedentes/registro-antecedentes.component';
import { RegistroDiagnosticoComponent } from './components/hemofilia/registros/registro-diagnostico/registro-diagnostico.component';
import { RegistroEventosHemorragicoComponent } from './components/hemofilia/registros/registro-eventos-hemorragico/registro-eventos-hemorragico.component';
import { RegistroComorbilidadesComponent } from './components/hemofilia/registros/registro-comorbilidades/registro-comorbilidades.component';
import { RegistroInhibidoresComponent } from './components/hemofilia/registros/registro-inhibidores/registro-inhibidores.component';
import { RegistroTratamientoComponent } from './components/hemofilia/registros/registro-tratamiento/registro-tratamiento.component';
import { RegistroAnteFamiliaresComponent } from './components/hemofilia/registros/registro-antecedente-familiares/registro-antecedente-familiares.component';
import { RegistroObservacionesComponent } from './components/hemofilia/registros/registro-observaciones/registro-observaciones.component';

import { ListarEvolucionesComponent } from './components/hemofilia/evoluciones/listar-evoluciones/listar-evoluciones.component';
import { RegistroEvolucionesComponent } from './components/hemofilia/evoluciones/registro-evoluciones/registro-evoluciones.component';
import { EditarEvolucionesComponent } from './components/hemofilia/evoluciones/editar-evoluciones/editar-evoluciones.component';
import { VerEvolucionesComponent} from './components/hemofilia/evoluciones/ver_evoluciones/ver_evoluciones.component';

//Agregado de Jeancarlo A.
import{ EvolucionesDemografiaComponent } from './components/hemofilia/evoluciones/evoluciones-demografia/evoluciones-demografia.component';
import{ EvolucionesTratamientoComponent } from './components/hemofilia/evoluciones/evoluciones-tratamiento/evoluciones-tratamiento.component';
import {EvolucionesInhibidoresComponent} from './components/hemofilia/evoluciones/evoluciones-inhibidores/evoluciones-inhibidores.component';
import {EvolucionesEventosHemorragicoComponent} from './components/hemofilia/evoluciones/evoluciones-eventos-hemorragico/evoluciones-eventos-hemorragico.component';
import { TabComponent } from './components/hemofilia/registros/registro-tab/tab.component';
import { EvolucionesTabComponent } from './components/hemofilia/evoluciones/evoluciones-tab/evoluciones-tab.component';
import { EvolucionesComorbilidadesComponent } from './components/hemofilia/evoluciones/evoluciones-comorbilidades/evoluciones-comorbilidades.component';
import { EvolucionesObservacionesComponent } from './components/hemofilia/evoluciones/evoluciones-observaciones/evoluciones-observaciones.component';
//
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { InputDialogComponent } from './components/shared/input-dialog/input-dialog.component';

//Jie 28/10/2022
import { ReporteListarComponent } from './components/hemofilia/reportes/reporte-listar.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ElegirCentroComponent,
    ListarModuloComponent,
    ListarMenusComponent,
    HomeComponent,
    TabComponent,
    RegistrarPerfilComponent,
    EditarPerfilComponent,
    VerPerfilComponent,
    ListarPerfilesComponent,
    
    
    ListarUsuariosComponent,
    DeleteModal,
    RegistrarUsuarioComponent,
    EditarUsuarioComponent,
    VerUsuarioComponent,
    CambiarContrasenaComponent,
    
    RegistrarCentroComponent,
    EditarCentroComponent,
    VerCentroComponent,
    ListarCentrosComponent,

    RegistrarMedicamentoComponent,
    EditarMedicamentoComponent,
    VerMedicamentoComponent,
    ListarMedicamentosComponent,

    RegistrarPacienteComponent,
    ListarPacientesComponent,
    
    
    ListarSolicitudesTransferenciaComponent,
    SolicitudTransferenciaPacienteComponent,
    SolicitudTransferenciaPacienteAprobacionComponent,
    SolicitudTransferenciaPacienteVerComponent,
    
    CargarRegistrosExcelComponent,
    ListarRegistrosComponent,
  

    RegistroDemografiaComponent,
    RegistroAntecedentesComponent,
    RegistroDiagnosticoComponent,
    RegistroEventosHemorragicoComponent,
    RegistroComorbilidadesComponent,
    RegistroInhibidoresComponent,
    RegistroTratamientoComponent,
    RegistroAnteFamiliaresComponent,
    RegistroObservacionesComponent,

    ListarEvolucionesComponent,
    RegistroEvolucionesComponent,
    EditarEvolucionesComponent,
    VerEvolucionesComponent,
    ConfirmationDialogComponent,
    InputDialogComponent,

    //Agregado de Evolucion
    EvolucionesTabComponent,
    EvolucionesDemografiaComponent,
    EvolucionesTratamientoComponent,
    EvolucionesInhibidoresComponent,
    EvolucionesEventosHemorragicoComponent,
    EvolucionesComorbilidadesComponent,
    EvolucionesObservacionesComponent,
    ReporteListarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule ,
  //  HttpModule,
    // ToastrModule.forRoot({
    //   timeOut: 10000,
    // }) ,
    ToastrModule.forRoot(),
    HttpClientModule,
    // NgxLoadingModule.forRoot({
    //   animationType: ngxLoadingAnimationTypes.wanderingCubes,
    //     backdropBackgroundColour: 'rgba(0,0,0,0.1)', 
    //     backdropBorderRadius: '4px',
    //     primaryColour: '#ffffff', 
    //     secondaryColour: '#ffffff', 
    //     tertiaryColour: '#ffffff'
    // }),
    SidebarModule.forRoot(),
    NgxDatatableModule,
    NgbModule,
   // DataTableModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule, 
    MatStepperModule,
    MatDatepickerModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatExpansionModule
    
  ],
  providers: [
    AppComponent,
    MenuGuard,
    AuthGuard,
    AuthenticationService,
    SocketService,
    MenuService,
    UserService,
    LovsService,
    CentroService,
    RegistroService,
    RegistroanteService,
    RegistrodiagService,
    RegistrohemoService,
    RegistroinhibidoresService,
    RegistromiembroFamiliarService,
    PacienteService,
    //Jeancarlo A. 21-04-21
    RegistroEvolucionService,
    ReportesService,

    //StompService
  ],exports:[
    MatExpansionModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [ElegirCentroComponent, ConfirmationDialogComponent, InputDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
