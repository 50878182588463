import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {AdmTmTipoCentro} from '../../../../common/admtmtipocentro';
import { DepartamentoService } from '../../../../services/ubigeo/departamento/departamento.service';
import { ProvinciaService } from '../../../../services/ubigeo/provincia/provincia.service';
import { DistritoService } from '../../../../services/ubigeo/distrito/distrito.service';
import { GeoTmDepartamento } from '../../../../common/geotmdepartamento';
import { GeoTmProvincia } from '../../../../common/geotmprovincia';
import { GeoTmDistrito } from '../../../../common/geotmdistrito';

import { PaisService } from '../../../../services/ubigeo/pais.service';
import { CatalogTmPais } from '../../../../common/catalogtmpais';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { TipoCentroService } from '../../../../services/mantenimiento/tipocentro.service';


// export interface institucion {
//   value: string;
//   nombreInstitucion: string;
// }

@Component({
  selector: 'app-registrar-centro',
  templateUrl: './registrar-centro.component.html',
  styleUrls: ['./registrar-centro.component.scss'],
  providers:[CentroService,PaisService, DepartamentoService, ProvinciaService, DistritoService,TipoCentroService],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarCentroComponent implements OnInit {
  newCentro: AdmTmCentro;
  centroToEdit: AdmTmCentro;
  centro: AdmTmCentro[];
  getData: string;
  busyCreate: Subscription;
  busyId: Promise<any>;
  disableCreate = false;
  /*ubigeo*/
  departamentos: GeoTmDepartamento[];
  provincias: GeoTmProvincia[];
  distritos: GeoTmDistrito[];
  paises: CatalogTmPais[];
  instituciones: AdmTmTipoCentro[];

  constructor(private centroService : CentroService,
              private paisService: PaisService,
              private departamentoService: DepartamentoService,
              private provinciaService: ProvinciaService,
              private distritoService: DistritoService,
              private router : Router,
              private toastrService: ToastrService,
              private tipoCentroService: TipoCentroService
  ) { }
  
  loadInstituciones() {
    this.tipoCentroService
      .getTipoCentrosActivos()
      .subscribe(response => {
        this.instituciones = response;
      })
  }

  loadPaises() {
    this.paisService
      .findAll()
      .subscribe(response => {
        this.paises = response;
      })
  }

  /*ubigeo*/
  loadDepartamentos() {
    if(this.newCentro.catalogTmPais != null){
      if(this.newCentro.catalogTmPais.iso.toLocaleUpperCase() === 'PE'){
        this.departamentoService
        .findAll()
        .subscribe(response => {
          this.departamentos = response;
        })
      }
    }
  }

  loadProvincia() {
    this.provincias = [];
    this.distritos = [];

    if (this.newCentro.geoTmDepartamento) {
      this.provinciaService
        .findByDepartamento(this.newCentro.geoTmDepartamento.id)
        .subscribe(response => {
          this.provincias = response;
        })
    }
  }

  loadDistrito() {
    if (this.newCentro.geoTmProvincia) {
      this.distritoService
        .findByProvincia(this.newCentro.geoTmProvincia.id)
        .subscribe(response => {
          this.distritos = response;
        })
    }
  }

  goToCentro(){
    let link = ['/centro'];
    this.router.navigate(link);
  }

  

  clearNewCentro(){
    this.newCentro = {
      idCentro:null,
      codigoCentro:null,
      nombreCentro:null,
      personaContacto:null,
      correoContacto:null,
      numContacto:null,
      direccionCentro:null,
      idUsuarioActualizacion:null,
      idUsuarioCreacion:null,
      institucion:null,
      ubigeo: null,
      geoTmDepartamento: null,
      geoTmProvincia: null,
      geoTmDistrito: null,
      catalogTmPais: null,
      admTmTipoCentro:null,
      idCodigotipodocumentoidentidad: null,
      estado:null
      
    };
 
    this.disableCreate=false;
  }


  createCentro() {

    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.disableCreate = true;
    // this.newCliente.ubigeo = this.newCliente.geoTmDepartamento.codigo + this.newCliente.geoTmProvincia.codigo + this.newCliente.geoTmDistrito.codigo;
      if (!this.newCentro.idCodigotipodocumentoidentidad){
        this.newCentro.idCodigotipodocumentoidentidad = 2;
      }
      console.log(this.newCentro);
      this.busyCreate = this.centroService.createCentro(this.newCentro)
        .subscribe(
        data => {
          console.log(data);
          this.getData=JSON.stringify(data);
          //this.showSuccess();

          if (data['codigoValidacion'] == 1)
            this.showWarning(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 2)
            this.showSuccess(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 3)
            this.showError(data['mensajeValidacion']);

          //this.reloadItems(this.params);		
        },
        error => this.showError('Error inesperado'),
        () => this.clearNewCentro()
        );
    }
  }
 

  ngOnInit() {
    this.clearNewCentro();
    this.loadPaises();
    this.loadDepartamentos();
    this.loadInstituciones();
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
