import { Component, OnInit ,ViewChild, ViewEncapsulation} from '@angular/core';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//services
import { MessageService } from '../../../../services/MessageService';
import { TipoCentroService } from '../../../../services/mantenimiento/tipocentro.service';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
//domains
import {AdmTmTipoCentro} from '../../../../common/admtmtipocentro';
import {AdmTmCentro} from '../../../../common/admtmcentro';

import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';


export interface estado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-listar-centros',
  templateUrl: './listar-centros.component.html',
  styleUrls: ['./listar-centros.component.scss'],
  providers:[CentroService,MessageService,TipoCentroService],
  encapsulation: ViewEncapsulation.None
})
export class ListarCentrosComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  centros: AdmTmCentro[];
  parametros:any;
  instituciones: AdmTmTipoCentro[];

  // instituciones: institucion[] = [
  //   {value: 'ESSALUD', nombreInstitucion: 'ESSALUD'},
  //   {value: 'MINISTERIO', nombreInstitucion: 'MINISTERIO DE SALUD'},
  //   {value: 'FUERZAS', nombreInstitucion: 'FUERZAS ARMADAS'}
  // ];

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];

  displayedColumns: string[] = ['Codigo', 'Nombre','Institucion', 'Distrito', 'Provincia','Departamento','Pais','Estado','Acciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private centroService : CentroService,
              private router : Router,
              private toastrService: ToastrService,
              private messageService: MessageService,
              private tipoCentroService: TipoCentroService,
              public dialog: MatDialog
    ) { }

    validarAnular(item){
      this.openDialog(item);
  
    }
    openDialog(item): void {
      console.log(item);
      let aux:any;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "¿Seguro que desea eliminar el centro "+item.centro+"?"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if(result){
          
          aux = item.idCentro;
          console.log('id centro',aux);
          this.centroService.deleteUser(aux)
          .subscribe(
            data => {
                    
              console.log(data,'data obtenida');
              this.getCentros();
              
              
              if(data.estado==0)
                  { 
                      this.showSuccess('MODIFICACION DEL CENTRO EXITOSO');
                  }
                  if(data.estado!=0)
                  {                
                    this.showError('FALLO AL  MODIFICAR EL CENTRO')
                  }
            },
                    error => this.showError('Error inesperado '),
            );
  
        }
      });
    }


    loadInstituciones() {
      this.tipoCentroService
        .getTipoCentrosActivos()
        .subscribe(response => {
          this.instituciones = response;
          console.log('loadInstituciones');
          console.log(this.instituciones);
        })
    }

    clear() {
        this.items = [];
        this.params = {
          codigoCentro:null,
          nombreCentro:null,
          admTmTipoCentro:null,
          estado:null
        };
        this.parametros = [];
    }

    back(){
      let link = ['/'];
      this.router.navigate(link);
    }

    goToCrear() {
      let link = ['/centro/nuevo'];
      this.router.navigate(link);
    }

    goToEdit(item: AdmTmCentro) {
      console.log(item)
          let link = ['/centro/', item.idCentro];
          console.log('Centro Editar: ' + link);
          this.router.navigate(link);
    }

    goToView(item: AdmTmCentro) {
          let link = ['/centro/ver/', item.idCentro];
          console.log('Centro Ver: ' + link);
          this.router.navigate(link);
    }
    checkPermisos() {
        this.canSelect = true;
        this.canEdit = this.checkMenusPermitidos('/centro/id');
        this.canCreate=this.checkMenusPermitidos('/centro/nuevo');
        this.canDelete=this.canCreate;
    }

    checkMenusPermitidos(url) {
        let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
        let response = false;
        for (let i = 0; i < menus.length; i++) {
            url = url.replace(/\d+/g, "id");
            if (url == '/' + menus[i].ruta) {
                response = true;
                break;
            }
        }
        return response;
    }

    getCentros(){
      let aux:any;
      this.centroService.getCentros()
          .subscribe(
            data=>{
              console.log(data);
              this.centros=data;
              aux=data
              for (let i = 0; i < data.length; i++) {
                if (data[i].estado ===1) {
                  aux[i].estado ='Activo';
                }
                else if (data[i].estado ===0) {
                  aux[i].estado ='Desactivo';
                }
              }
  
              this.dataSource=new MatTableDataSource(aux);
              this.dataSource.sort=this.sort;
              this.dataSource.paginator=this.paginator;
              
              },
            error => this.showError('Error obtener centros')
      );
    }

/*
    reloadCentro() {
      console.log(this.params);

      //this.itemToFilter.invTmUnidadmedida = this.unidadMedida;
      this.centroService.search(this.params)
          .subscribe(result => {
            this.dataSource=result;
          });
    }
    */
   reloadCentro(){
    console.log('data a buscar')
    console.log(this.params,'data de entrada');
    let aux :any;
    let paramsBuscar: any = {
      codigoCentro: this.params.codigoCentro,
      nombreCentro: this.params.nombreCentro,
     //medico: this.params.medico,
      //nombresPaciente: this.params.centro
      //estado: this.params.estado.value
    }

    if (this.params.admTmTipoCentro && this.params.admTmTipoCentro.idTipoCentro){
      paramsBuscar.admTmTipoCentro = this.params.admTmTipoCentro.idTipoCentro
    }

    if (this.params.estado){
      paramsBuscar.estado = this.params.estado.value;
    }
    this.centroService.getRegistrosFiltrado(paramsBuscar).subscribe( data => {
      if(!data){
        return;
      }
      aux=data.content
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].estado ===1) {
          aux[i].estado ='Activo';
        }else if(aux[i].estado ===0){
          aux[i].estado ='Desactivo';
      }  }
       console.log(data,'data obtenida')
       console.log(aux,'aux obtenida')
       //this.data = data.content;
      // this.dataSource=new MatTableDataSource(); 
      this.dataSource=new MatTableDataSource(aux); 
      this.dataSource.sort=this.sort;
      this.dataSource.paginator=this.paginator; 
   },
   error => this.showError('Error inesperado')
 );
   }

    ngOnInit() {
      this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarCentros' });
      this.clear();
      this.checkPermisos();
      this.loadInstituciones();
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      this.getCentros();
    }


    showSuccess(mensaje) {
      this.toastrService.success(mensaje ,'¡Éxito!' );
    }
      
    showInfo(mensaje) {
      this.toastrService.info(mensaje ,'¡Información!' );
    }
      
    showWarning(mensaje) {
      this.toastrService.warning(mensaje ,'¡Advertencia!' );
    }

    showError(mensaje) {
      this.toastrService.error(mensaje , '¡Error!' );
    } 

}
