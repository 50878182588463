import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/registroinhibidores';

@Injectable()
export class RegistroinhibidoresService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    createRegistroInhibidor(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoRegistroInhibidor';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updateRegistroAnte(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }


    getRegistroInhibiByRegistro(idRegistro): Observable<any> {
        let findUrl = BASE_URL + '/porRegistro/' + idRegistro;
        return this.http.get(findUrl).pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //Jeancarlo A. 23-04-21
    getRegistroInhibiByRegistroEvolucion(idRegEvolucion): Observable <any>{
        let findUrl = BASE_URL + '/porRegistroEvo/' + idRegEvolucion;
        return this.http.get(findUrl).pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // GET
    getRegistroInhibidorById(idRegistro): Observable<any> {
        let findUrl = BASE_URL+'/'+idRegistro;
        return this.http.get(findUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
   
}
