import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { RegistroEvolucionService } from '../../../../services/hemofilia/registroevolucion.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
//domain
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';

import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { InputDialogComponent } from '../../../shared/input-dialog/input-dialog.component';
import { HemTrSolicitudTrans } from 'src/app/common/hemtrsolicitudtrans';

export interface genero {
  codigo: string;
  descripcion: string;
}
export interface estado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-listar-evoluciones',
  templateUrl: './listar-evoluciones.component.html',
  styleUrls: ['./listar-evoluciones.component.scss'],
  providers: [MessageService,RegistroEvolucionService],
  encapsulation: ViewEncapsulation.None
})
export class ListarEvolucionesComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEdit = false;
  canCreate = false;
  canDelete = false;
  canChange = false;
  parametros:any;
  getData: string;
  pacientes: AdmTmPaciente[];
  centros: AdmTmCentro[];
  disableButtons: boolean = true;
  idPaciente: any;
  currentCentro: any;
  user :any;

  pagina: any = {
    total: 0,
    numeroPagina: 0,
    cantidadPagina: 10
  }

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  displayedColumns: string[] = ['FechaAtencion', 'Centro', 'Medico','CodigoEvolucion','CodigoPaciente','NombresApellidos','Estado','Acciones'];
  dataSource: MatTableDataSource<any>;
  data: any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private centroService : CentroService,
    private usuarioService : UserService,
    private evolucionService: RegistroEvolucionService,
    private router : Router,
    private pacienteService: PacienteService,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) { }

  
  
  ingresarDocumento(){
    let idusuariopaciente :any;
    this.disableButtons = true;
    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: '350px',
      data: "Ingrese el número del documento del paciente:"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        console.log(result);
        // Se inicia verificacion de si ya existe registro para ese documento
        this.pacienteService.getVerificarDocumentoPaciente(result).subscribe(
          data => {
              
            if (data.codigoValidacion == 1){
              
              this.pacienteService.getPacienteById(data.idPaciente).subscribe(
                obt => {
                  this.pacienteService.getPacienteUsuario(obt).subscribe(
                    vvr => {
                        idusuariopaciente = vvr.admTmUsuario.idUsuario;
                        console.log(vvr,'DATA PACIENTE POR USUARIO');
                        console.log(idusuariopaciente,'usuario X paciente');
                        console.log(this.user.idUsuario,'usuario perfil');
                        this.createEvolucionInhibidores(data.idPaciente);
               /*         if(idusuariopaciente != this.user.idUsuario && this.user.idUsuario != null ){
                          this.showError('El Paciente no  es atentido por este usuario.');
                        }
                        else if (this.user.idUsuario == null){
                          this.createEvolucionInhibidores(data.idPaciente);
                        }
                        else if(idusuariopaciente == this.user.idUsuario){
                          this.createEvolucionInhibidores(data.idPaciente);
                        }*/
                    }
                  );
                
                });
            }else{
              this.showError('No existe paciente ingresado.');
            }
            this.disableButtons = false;
          },
          error => {
            this.showError('Error inesperado');
            this.disableButtons = false;
          }
        );
      }
    });
  }



  getEvoluciones(){
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(user.idUsuario)
    let aux:any;
    this.evolucionService.getEvolucionesByIdUsuario(user.idUsuario)
        .subscribe(
          data=>{
              console.log(data);
              //this.dataSource=data;  
              aux=data; 
              this.data=aux;
              
            },
          error => this.showError('Error obtener solicitudes')
        );
  }

  seleccionarPagina(pageInfo){
    this.pagina.numeroPagina = pageInfo.offset;
    this.obtenerData();
  }

  obtenerData(){
    console.log('data a buscar')
    console.log(this.params);

    let paramsBuscar: any = {
      fechaDesde: this.params.fechaDesde,
      fechaHasta: this.params.fechaHasta,
      codigoPaciente: this.params.codigoPaciente,
      nombresPaciente: this.params.nombresPaciente,
      apellidosPaciente: this.params.apellidosPaciente,
      nroDocumentoPaciente: this.params.nroDocumentoPaciente,
      medicoPaciente: this.params.medicoPaciente,
      idUsuario : this.user.idUsuario,
      pagina: this.pagina.numeroPagina,
      cantidadPagina: this.pagina.cantidadPagina
    }
     
    console.log(this.user.idUsuario,'usuario a buscar');

    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centroPaciente = this.params.centro.idCentro;
    }

    if (this.params.genero){
      paramsBuscar.generoPaciente = this.params.genero.codigo;
    }

    this.evolucionService.search(paramsBuscar).subscribe( data => {
        console.log('DATA OBTENIDA',data)
        this.data = data.content;
        this.pagina.total = data.totalElements;
      },
      error => this.showError('Error inesperado')
    );
 
  }

  



  getCurrentUsuario(){
    let perfil:any[];
    let per :any;
    let pert :any[] ;
    
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(this.user);

   perfil = this.user.admTrPerfilxusuarios;

    for(var i=0 ;i<perfil.length;i++){

      per =perfil[i].admTmPerfil.idPerfil; 
      
    };
    
      if(per == 2 ){

        this.user.idUsuario = null;
      }
      else if (per == 1){

        this.user.idUsuario =0;
      }
     
     else {
       this.user.idUsuario = this.user.idUsuario;
     }
     console.log(this.user.idUsuario,'ID USUARIO SEGUN PERFIL');
  }

  getCurrentCentro() {
    this.currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    console.log(this.currentCentro);
  }
  getCentros(){
    this.centroService.getCentros().subscribe(
      data=>{
        this.centros=data;
        for (var k = 0; k < this.centros.length; k++){
          if(this.user.idUsuario == 0){
            this.params.centro = null;
            this.params.idUsuario = this.user.idUsuario;
           }
           else{   
            if(this.currentCentro.idCentro==this.centros[k].idCentro){
                this.params.centro=this.centros[k];
                console.log(this.user.idUsuario);
                this.params.idUsuario = this.user.idUsuario;
                break;
            }
          }
        } 
        this.obtenerData();            
      },
      error => this.showError('Error obtener centros')
    );
  }
  
  clear() {
    this.items = [];
    this.params = {
      fechaDesde: null,
      fechaHasta: null,
      codigoUsuario:null,
      nombresUsuario:null,
      apellidosUsuario:null,
      admTrPerfilxusuarios:null,
      centro:null,
      numeroDocumento:null,
      genero:null,
      estado:null
    };
    this.parametros = [];
  }

  createEvolucionInhibidores(idPaciente){

    var f = document.getElementsByTagName('form')[0];

      let centro = JSON.parse(sessionStorage.getItem('currentCentro'));

      let newPacienteEvolucion = {
        idCentro:centro.idCentro,
        admTmPaciente:idPaciente
      }

      this.evolucionService.createEvolucion(newPacienteEvolucion).subscribe(
        data => {
          if(data['codigoValidacion']==1)
          this.showWarning(data['mensajeValidacion']);
          if(data['codigoValidacion']==2)
          this.showSuccess(data['mensajeValidacion']);
         console.log(data['id']);
         this.goToEvolucionReg(data['id']);
          if(data['codigoValidacion']==3)
          this.showError(data['mensajeValidacion']); 
        },
        error => this.showError('Error inesperado'),
        () => this.clearNewEvolucion()
      )
    //   this.newPacienteEvolucion.admTmCentro=centro;
    //   let data={
    //     evolucion:this.newPacienteEvolucion,
    //     hemTrRegistroEvolucionTrata:this.tratamientosDosajeInhibidros

    //   }
                          
    // this.registroEvolucion.createEvolucion(data).subscribe(
    //     data => {
    //       if(data['codigoValidacion']==1)
    //         this.showWarning(data['mensajeValidacion']);
    //       if(data['codigoValidacion']==2)
    //         this.showSuccess(data['mensajeValidacion']);
    //         this.goToEdit(data['item'])
    //       if(data['codigoValidacion']==3)
    //         this.showError(data['mensajeValidacion']);                    
    //     },
    //     error => this.showError('Error inesperado'),
    //     () => this.clearNewEvolucion()
    // );  
  }

  clearNewEvolucion() {

  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }

  goToCrear(idPaciente) {
    let link = ['/evolucion/nuevo/'+ idPaciente];
    this.router.navigate(link);
  }

  goToEdit(item: any) {
    console.log(item);

    //let link = ['/evolucion/editar/'+ item.idRegEvolucion];
    let link = ['/evolucion/'+ item.idRegEvolucion];
    console.log('solicitud aprobacion: ' + link);
   this.router.navigate(link);
  //this.router.navigate(['/','evolucion/'+ item.idRegEvolucion]); 
  
  }

  //Jeancarlo A. 08-07-21
  verDetalle(idRegEvolucion){
    //let link = ['/registro/ver/', idPaciente];
    let link = ['/evolucion/', idRegEvolucion];
  //  this.router.navigate(['/registro/' + idRegistro]);
    this.router.navigate(link);
  }
  //


  goToEvolucionReg(idRegEvolucion){
    console.log(idRegEvolucion);
    //let link = ['/evolucion/editar/'+ item.idRegEvolucion];
    //let link = ['/evolucion/'+ item.idRegEvolucion];
    let link = ['/evolucion/'+ idRegEvolucion];
    console.log('solicitud aprobacion: ' + link);
    this.router.navigate(link);
  }
  
  goToView(item: any) {
    console.log(item);
    let link = ['/evolucion/'+ item.idRegEvolucion];
    console.log('solicitud aprobacion: ' + link);
    this.router.navigate(link);
  }


  checkPermisos() {
    this.canSelect = true;
    this.canEdit = this.checkMenusPermitidos('/evolucion/id');
    this.canCreate=this.checkMenusPermitidos('/evolucion/nuevo/id');
    this.canDelete=this.canCreate;
  }
  
  checkMenusPermitidos(url) {
    let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
    let response = false;
    if (menus){
      for (let i = 0; i < menus.length; i++) {
          url = url.replace(/\d+/g, "id");
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
    }
    return response;
  }







  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

  ngOnInit() {
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarUsuarios' });
    this.clear();
    this.checkPermisos();
    this.getCurrentUsuario();
    this.getCurrentCentro();
    this.getCentros();
    // this.getEvoluciones();
  }


}
