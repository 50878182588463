import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LovsService } from '../../../../services/admin/lovs.service';
import { Output, EventEmitter } from '@angular/core';

import { RegistroinhibidoresService } from '../../../../services/hemofilia/registroinhibidores.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-evoluciones-inhibidores',
  templateUrl: './evoluciones-inhibidores.component.html',
  styleUrls: ['./evoluciones-inhibidores.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EvolucionesInhibidoresComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  disableCreate = false;
  disableEdit:boolean = false;
  dataPacienteRegistro: any = null;
  idLovYesNoInmus: any = null;
  lovYesNos: any=null;
  regimenTratamientos: any=null;
  inhibidores: any=null;
  tratamientos: any=null;
  tratamientosProfix: any=null; //Jie 11/04/2021
  tratamientosEpisodio: any=null; //Jie 11/04/2021
  newPacienteInhib: any=null;
  idRegistro: any = null;
  idRegEvolucion: any = null; //Jeancarlo A. 23-04-21
  dataRecibida: any = null;

  cargadoData: boolean = false;
  cargadoYesNo: boolean = false;
  cargadoRegTrat: boolean = false;
  cargadoTratRecibe: boolean = false;
  cargadoInhibidores: boolean = false;

  requerido: boolean = false;

  rptaInmunotolerancias: any=null;  //Jie 11/04/2021
  cargadoRptaInmunotolerancia: boolean = false; //Jie 11/04/2021
  inhibidoresAusencia: boolean = true;//Jeancarlo A. 26-04-21
  bloquearTodo: boolean = false; //Jeancarlo A. 30-04-21
  respuestaNo: boolean = true; //Jeancarlo A. 30-04-21

  currentUser :any;

  constructor(
    private toastrService: ToastrService,
    private registroInhibidoresService: RegistroinhibidoresService,
    private route: ActivatedRoute,
    private router : Router,
    private lovsService: LovsService) { }
  
  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.disableEdit = data.disableEdit;
    this.idRegEvolucion = data.idRegEvolucion;
    this.newPacienteInhib.hemTrRegistroEvolucion = {
      idRegEvolucion: data.idRegEvolucion
    }
  }
  
  clearNewRegistroInhib(){
    this.newPacienteInhib = {
      admTmLovTratamientoRecibe: null,
      dosajeInhibidores: null,
      fecha: null,
      tituloUB: null,
      admTmLovRegimenTratamiento: null,
      inmunotolerancia: null,
      esquema: null,
    };
  }

  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(data=>{
      //this.idLovYesNoInmus = data;
      this.lovYesNos = data;
      this.cargadoYesNo = true;
      this.verificarCarga();
    },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovInhibidores(){
    this.lovsService.getAdmTmLovInhibidoresActivas().subscribe(data => {
      this.inhibidores = data;
      this.cargadoInhibidores = true;
      this.verificarCarga();
    },
    error => this.showError('Error obtener inhibidores')
    );
  }

  getLovRegimenTratamiento(){
    this.lovsService.getAdmTmLovRegimenTratamientoActivas().subscribe(data=>{
      this.regimenTratamientos = data;  
      this.cargadoRegTrat = true;
      this.verificarCarga();     
    },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovTratamientoRecibe(){
    this.lovsService.getAdmTmLovTratamientoRecibeActivas().subscribe(
      data=>{
        let tratamientosRec = []
        let tratamientosRec1 = []
        for(var i=0;i<data.length;i++){
          /*if(this.regimenTratamientos.idRegimenTratamiento == 2){
            if(data[i].idTratamientoRecibe!=3){
              tratamientosRec.push({
                idTratamientoRecibe:data[i].idTratamientoRecibe,
                codigo:data[i].codigo,
                nombre:data[i].nombre
              })
            }
          } else{
            tratamientosRec.push({
              idTratamientoRecibe:data[i].idTratamientoRecibe,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }*/
          if(data[i].idTratamientoRecibe <= 3){
            tratamientosRec.push({
              idTratamientoRecibe:data[i].idTratamientoRecibe,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }else{
            tratamientosRec1.push({
              idTratamientoRecibe:data[i].idTratamientoRecibe,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }
        }
      this.tratamientosProfix=tratamientosRec;
      this.tratamientosEpisodio=tratamientosRec1;
      //this.tratamientos = tratamientosRec;
      this.cargadoTratRecibe = true;
      this.verificarCarga();
    },
      error => this.showError('Error obtener yes no')
    );
  }

  //Jie 11/04/2021
  getLovRptaInmunotolerancia(){
    this.lovsService.getAdmTmLovRespuestaInmunotoleranciaActivas().subscribe(data=>{
      this.rptaInmunotolerancias = data;  
      this.cargadoRptaInmunotolerancia = true;
      this.verificarCarga();     
    },
      error => this.showError('Error obtener yes no')
    );
  }

  cargarInhibidores(){
    //this.registroInhibidoresService.getRegistroInhibiByRegistro(this.idRegistro).subscribe(
    this.registroInhibidoresService.getRegistroInhibiByRegistroEvolucion(this.idRegEvolucion).subscribe(
      data => {
        this.dataRecibida = data;
        this.cargadoData = true;
        this.verificarCarga();
      }
    );
  }

  verificarCarga(){
    if (this.cargadoData && this.cargadoRegTrat && this.cargadoTratRecibe && this.cargadoYesNo && this.cargadoInhibidores && this.cargadoRptaInmunotolerancia ){
      //if (this.idRegistro && this.dataRecibida){
      if (this.idRegEvolucion && this.dataRecibida){
        if (this.dataRecibida.fecha){
          this.newPacienteInhib.fecha = new Date(this.dataRecibida.fecha + 'T24:00:00');
        }
        this.newPacienteInhib.tituloUB = this.dataRecibida.tituloUB;
        this.newPacienteInhib.esquema = this.dataRecibida.esquema;
        this.newPacienteInhib.idRegistro= 0; //Jeancarlo A. 18-03-21
        if (this.dataRecibida.inmunotolerancia){
          for (var i = 0; i < this.lovYesNos.length; i++){
          /* if (this.dataRecibida.dosajeInhibidores.idYesNo == this.lovYesNos[i].idYesNo){
              this.newPacienteInhib.dosajeInhibidores = this.lovYesNos[i];
            }*/
            if (this.dataRecibida.inmunotolerancia.idYesNo == this.lovYesNos[i].idYesNo){
              this.newPacienteInhib.inmunotolerancia = this.lovYesNos[i];
            }
          }
        }

        for(var i = 0; i < this.inhibidores.length; i++){
          if (this.dataRecibida.dosajeInhibidores.idInhibidor == this.inhibidores[i].idInhibidor){
            this.newPacienteInhib.dosajeInhibidores = this.inhibidores[i];
            if(this.newPacienteInhib.dosajeInhibidores.idInhibidor != 3){
              this.requerido = true;
            }else{
              this.requerido = false;
            }
  
          }
        }

        if(this.dataRecibida.admTmLovRegimenTratamiento){
          for (var i = 0; i < this.regimenTratamientos.length; i++){
            if (this.dataRecibida.admTmLovRegimenTratamiento.idRegimenTratamiento == this.regimenTratamientos[i].idRegimenTratamiento){
              this.newPacienteInhib.admTmLovRegimenTratamiento = this.regimenTratamientos[i];
              break;
            }
          }

          if(this.newPacienteInhib.admTmLovRegimenTratamiento.idRegimenTratamiento== 1){
            this.tratamientos=this.tratamientosProfix;
          }else{
            this.tratamientos=this.tratamientosEpisodio;
          }
          if(this.dataRecibida.admTmLovTratamientoRecibe){
            for (var i = 0; i < this.tratamientos.length; i++){
              if (this.dataRecibida.admTmLovTratamientoRecibe.idTratamientoRecibe == this.tratamientos[i].idTratamientoRecibe){
                this.newPacienteInhib.admTmLovTratamientoRecibe = this.tratamientos[i];
                break;
              }
            }
          }
        }
        if(this.dataRecibida.admTmLovRespuestaInmunotolerancia){
          for (var i = 0; i < this.rptaInmunotolerancias.length; i++){
            if (this.dataRecibida.admTmLovRespuestaInmunotolerancia.idRespuestaInmuno == this.rptaInmunotolerancias[i].idRespuestaInmuno){
              this.newPacienteInhib.admTmLovRespuestaInmunotolerancia = this.rptaInmunotolerancias[i];
              break;
            }
          }
        }
        this.onChange();
        //if (this.currentUser.idUsuario!=this.dataRecibida.hemTrRegistro.admTmCentroUsuario.admTmUsuario.idUsuario){ // 1: En proceso
        //  this.disableEdit = true;
        
        //}
      }
    }
  }
  
  createRegistroInhibidores(){
    var f = document.getElementsByTagName('form')[3];
    if(f.reportValidity()){
      // Crear diagnostico
      //this.disableCreate = true;
      
      this.newPacienteInhib.hemTrRegistroEvolucion = {
        //idRegistro: this.idRegistro
        idRegEvolucion: this.idRegEvolucion
      };
      if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 2 && this.newPacienteInhib.admTmLovRegimenTratamiento == null){
        this.showWarning("Seleccionar un régimen de tratamiento");
      }else if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 2 && this.newPacienteInhib.admTmLovTratamientoRecibe == null){
        this.showWarning("Indique que tratamiento recibe");
      }else if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 2 && this.newPacienteInhib.inmunotolerancia == null){
        this.showWarning("Indique si ha recibido inmunotolerancia");
      }else if(this.newPacienteInhib.inmunotolerancia.idYesNo == 1 && this.newPacienteInhib.esquema == null){
        this.showWarning("Llene el campo de Esquema");
      }else if(this.newPacienteInhib.inmunotolerancia.idYesNo == 1 && this.newPacienteInhib.admTmLovRespuestaInmunotolerancia == null){
        this.showWarning("Indique una respuesta a inmunotolerancia");
      }else{

      this.registroInhibidoresService.createRegistroInhibidor(this.newPacienteInhib).subscribe(data => {
          console.log(data);
          //let getData = JSON.stringify(data);
          if (data['codigoValidacion'] == 1){
            this.showWarning(data['mensajeValidacion']);
           // this.disableCreate = false;
          }else if (data['codigoValidacion'] == 2){
            this.newPacienteInhib.idRegInhibidores = data['id'];
            this.showSuccess('Inhibidores guardados.')
            this.siguienteTab();
          }else if (data['codigoValidacion'] == 3){
            this.showError(data['mensajeValidacion']);
           // this.disableCreate = false;
          }
        },
        error => this.showError('Error inesperado'),
      );
    }
    }
  }

  siguienteTab(){
    this.dataRegistrada.next(this.newPacienteInhib);
  }

  noEscribas(){
    var titulo=document.getElementsByName("tituloUB");
    $(titulo).keydown(function(){
      return false
    });
  }

  ngOnInit() {
    this.clearNewRegistroInhib();
    this.noEscribas();
    console.log('dentro de inhibidores');
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegEvolucion = itemid;
      this.newPacienteInhib.hemTrRegistroEvolucion = {
        idRegEvolucion: itemid
      }
      this.cargarInhibidores();
    }else{
      this.cargadoData = true;
    }

    this.getLovYesNo();
    this.getLovInhibidores();
    this.getLovRegimenTratamiento();
    this.getLovTratamientoRecibe();
    this.getLovRptaInmunotolerancia();
  }

  onChange3(){
    if(this.newPacienteInhib.dosajeInhibidores.idInhibidor != 3){
    this.requerido = true;
    }else{
      this.requerido = false;
    }
  }

  //Función agregada por Jeancarlo A. 01/03/21--Solicitud diapositiva 20
  onChange2(){
    var regtrat=document.getElementsByName("regimenTratamiento");
    var inmuno=document.getElementsByName("inmuno");
    var rptainmuno=document.getElementsByName("respuesta");
    var tratamientorec=document.getElementsByName("tratamientorecibe");
    var ultimafech=document.getElementsByName("fechaDosaje");
    var titulo=document.getElementsByName("tituloUB");
    var esquem=document.getElementsByName("esquema");
console.log(this.newPacienteInhib.dosajeInhibidores.idInhibidor);

    if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 1){
      $(regtrat).attr("required","");
      $(inmuno).attr("required","");
      //$(regtrat).prop("disabled", true);
      //$(inmuno).prop("disabled",true);
      $(rptainmuno).attr("required","");
      //$(rptainmuno).prop("disabled", true);
      $(tratamientorec).attr("required","");
      //$(tratamientorec).prop("disabled", true);
      this.inhibidoresAusencia = true;
      this.bloquearTodo = false;
      this.newPacienteInhib.admTmLovRegimenTratamiento = null;
      this.newPacienteInhib.inmunotolerancia = null;
      this.newPacienteInhib.admTmLovRespuestaInmunotolerancia=null;
      this.newPacienteInhib.admTmLovTratamientoRecibe=null
    }else if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 3){
      $(regtrat).attr("required","");
      $(inmuno).attr("required","");
      $(rptainmuno).attr("required","");
      $(tratamientorec).attr("required","");
      $(ultimafech).attr("required","");
      $(titulo).attr("required","");
      $(esquem).attr("required","");
      this.bloquearTodo = true;
      this.newPacienteInhib.admTmLovRegimenTratamiento = null;
      this.newPacienteInhib.inmunotolerancia = null;
      this.newPacienteInhib.admTmLovRespuestaInmunotolerancia=null;
      this.newPacienteInhib.admTmLovTratamientoRecibe=null;
      this.newPacienteInhib.fecha=null;
      this.newPacienteInhib.tituloUB=null;
      this.newPacienteInhib.esquema=null;
    }else{
      $(regtrat).removeAttr("required");
      $(inmuno).removeAttr("required");
      //$(regtrat).prop("disabled", false);
      //$(inmuno).prop("disabled", false);
      $(rptainmuno).removeAttr("required");
      //$(rptainmuno).prop("disabled", false);
      $(tratamientorec).removeAttr("required");
      //$(tratamientorec).prop("disabled", false);
      $(ultimafech).removeAttr("required");
      $(titulo).removeAttr("required");
      $(esquem).removeAttr("required");
      this.inhibidoresAusencia = false;
      this.bloquearTodo = false;
    }
  }

  inmunotoleranciaChange(){
    var esquem=document.getElementsByName("esquema");
    var rptainmuno=document.getElementsByName("respuesta");
    if(this.newPacienteInhib.inmunotolerancia.idYesNo == 2){
      $(rptainmuno).attr("required","");
      $(esquem).attr("required","");
      this.respuestaNo = true;
      this.newPacienteInhib.admTmLovRespuestaInmunotolerancia=null;
      this.newPacienteInhib.esquema=null;
    }else{
      $(rptainmuno).removeAttr("required");
      $(esquem).removeAttr("required");
      this.respuestaNo = false;
    }
  }

  onChange(){
    this.onChange2();
    this.onChange3();
    this.onChangeTituloUB();
  }

  onChangeTituloUB(){
    var titulo=document.getElementById("tituloUB");
    if(this.newPacienteInhib.dosajeInhibidores.idInhibidor == 2){
      $(titulo).attr("min",0.7);
      $(titulo).val('0.7');
      this.newPacienteInhib.tituloUB = 0.7;
    } else{
      $(titulo).attr("min",0);
      $(titulo).val('');
      this.newPacienteInhib.tituloUB = null;
    }
  }


  changeTratamientoRecibe(){
    if(this.newPacienteInhib.admTmLovRegimenTratamiento.idRegimenTratamiento== 1){
      this.tratamientos=this.tratamientosProfix;
    }else{
      this.tratamientos=this.tratamientosEpisodio;
    }
  }
// Hasta aca el agregado

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
