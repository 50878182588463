import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core'; 

import { LovsService } from '../../../../services/admin/lovs.service';
import { RegistromiembroFamiliarService } from '../../../../services/hemofilia/registro.miembrofamiliar.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-registro-antecedente-familiares',
  templateUrl: './registro-antecedente-familiares.component.html',
  styleUrls: ['./registro-antecedente-familiares.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistroAnteFamiliaresComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  
  idRegistro: any = null;
  dataPacienteRegistro: any = null;
  idLovYesNoAnteFs: any = null;
  newPacienteAnteFamiliar: any=null;
  anteFamiliares: any = [];
  dataRecibida: any = null;

  disableCreate: boolean = false;
  
  cargadoData: boolean = false;
  cargadoYesNo: boolean = false;
  noFamiliares: boolean = true;
  disableEdit: boolean = false;

  constructor(
    private toastrService: ToastrService,
    private lovsService: LovsService,
    private router : Router,
    private registroMiembroFamiliarService: RegistromiembroFamiliarService,
    private route: ActivatedRoute
  ) { }
  
  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.idRegistro = data.idRegistro;

    if (data.idRegistroEstado > 1){ // 1: En proceso
      this.disableEdit = true;
    }
  }
  
  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(
      data=>{
        this.idLovYesNoAnteFs = data;
        this.cargadoYesNo = true;
        this.verificarCarga()
      },
      error => this.showError('Error obtener yes no')
    );
  }

  cargarMiembrosFamiliares(){
    this.registroMiembroFamiliarService.getRegistroEventByRegistro(this.idRegistro).subscribe(
      data => {
        this.dataRecibida = data;
        this.cargadoData = true;
        this.verificarCarga();
      }
    );
  }
  

  verificarCarga(){
    if (this.cargadoData && this.cargadoYesNo){
      if (this.idRegistro && this.dataRecibida){
        let filas = [];

        if (this.dataRecibida && this.dataRecibida.length > 0){
          for (var i = 0; i < this.dataRecibida.length; i++){
            filas.push({
              nombre: this.dataRecibida[i].nombre,
              parentesco: this.dataRecibida[i].parentesco
            })
          }
        }

        if (filas.length > 0){
          this.newPacienteAnteFamiliar.idLovYesNoAnteF = this.idLovYesNoAnteFs.find(x => x.codigo == 'YES');          
          this.noFamiliares = false;
        }else{
          this.newPacienteAnteFamiliar.idLovYesNoAnteF = this.idLovYesNoAnteFs.find(x => x.codigo == 'NO');
          this.noFamiliares = true;
        }

        this.anteFamiliares = filas;
        //this.anteFamiliares = [...this.anteFamiliares]
      }
    }
  }

  clearNewRegistroAnteF(){
    this.newPacienteAnteFamiliar = {
      idLovYesNoAnteF: null      
    };
  }

  nombreIngresada(event, row){
    if (event && event.target && event.target.value){
      row.nombre = event.target.value;
    }else{
      row.nombre = '';
    }
  }

  parentescoIngresada(event, row){
    if (event && event.target && event.target.value){
      row.parentesco = event.target.value;
    }else{
      row.parentesco = '';
    }
  }

  agregarFila(){
    this.anteFamiliares.push({
      nombre: '',
      parentesco: ''
    });
    this.anteFamiliares = [...this.anteFamiliares]
  }

  eliminarFila(rowIndex){
    this.anteFamiliares.splice(rowIndex, 1);
    this.anteFamiliares = [...this.anteFamiliares]
  }

  algunFamiliarCambiado(){
    if (this.newPacienteAnteFamiliar.idLovYesNoAnteF && this.newPacienteAnteFamiliar.idLovYesNoAnteF.idYesNo == 1){
      this.noFamiliares = false;
    }else{
      this.noFamiliares = true;
    }
  }

  createRegistroAnteFamiliares(){
    //var f = document.getElementsByTagName('form')[2];
    var f = $("#antecedentesFamiForm")[0];
    if(f.reportValidity()){
      let registrosMiembros = [];
      if (this.newPacienteAnteFamiliar.idLovYesNoAnteF.idYesNo == 1){
        // Si
        for (var i = 0; i < this.anteFamiliares.length; i++){
          registrosMiembros.push({
            nombre: this.anteFamiliares[i].nombre,
            parentesco: this.anteFamiliares[i].parentesco,
          })
        }
      }
      let dataCrear = {
        idRegistro: this.idRegistro,
        miembros: registrosMiembros
      }

      this.registroMiembroFamiliarService.createRegistroMiembroFamiliar(dataCrear).subscribe(data => {
        if (data['codigoValidacion'] == 1){
          this.showWarning(data['mensajeValidacion']);
          this.disableCreate = false;
        }else if (data['codigoValidacion'] == 2){
          this.showSuccess('Antecedentes familiares guardados.')
          this.siguienteTab(dataCrear);
        }else if (data['codigoValidacion'] == 3){
          this.showError(data['mensajeValidacion']);
          this.disableCreate = false;
        }
      },
      error => this.showError('Error inesperado'),
      );

    }
  }

  siguienteTab(dataCrear){
    this.dataRegistrada.next(dataCrear);
  }

  ngOnInit() {
    this.clearNewRegistroAnteF();

    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegistro = itemid;
      this.cargarMiembrosFamiliares();
    }else{
      this.cargadoData = true;
    }
    this.getLovYesNo();
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
