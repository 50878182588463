import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmCentro } from '../../common/admtmcentro';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/solicitud';

@Injectable()
export class SolicitudService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }


    createSolicitud(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        
        let createUrl = BASE_URL+'/nuevoSolicitud';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    aprobacionSolicitud(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/updateSolicitud';
        let bodyString = JSON.stringify(body);
        return this.http.put(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    getSolicitudes(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // GET
    getSolicitudById(idSolicitud): Observable<any> {
        let findUrl = BASE_URL+'/'+idSolicitud;
        return this.http.get(findUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
    getRegistrosFiltrado(paramsBusqueda: any): Observable<any> {
        let getUrl = BASE_URL + '/filtrar';
        let paramsGet = new HttpParams()
            .set('numsolicitud', paramsBusqueda.norSolicitud)
           .set('numerodocumento', paramsBusqueda.DNIPaciente)
            .set('nombresusuarioo', paramsBusqueda.medico)
            .set('nombrecentroo', paramsBusqueda.centro)
            .set('estado', paramsBusqueda.estado)
            .set('idUsuario', paramsBusqueda.idUsuario);
          
         
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
           
            params: paramsGet
        }
        console.log(paramsGet,'entrada a java')
        return this.http.get(getUrl,options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
    deleteUser(body: any): Observable<any> {
        var solicitud=JSON.parse(sessionStorage.getItem('currentUser'));
        let updateUrl = BASE_URL + '/updateSolicitudEstado';
        return this.http.post(`${updateUrl}/${solicitud.idUsuario}`, body, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

}
