import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmCentro } from '../../common/admtmcentro';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/pacienteactual';

@Injectable()
export class PacienteActualService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    // search(body: any, pageable: DataTableParams): Observable<Object> {
    //     let uri = BASE_URL + '/searchAndPage?' + this.paramsToQueryString(pageable);
    //     return this.http.post(uri, body, this.httpOptions)
    //     .pipe(
    //     retry(1),
    //     catchError(this.errorHandl)
    //     )
    // }

    createPacienteActual(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoPacienteActual';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updatePacienteActual(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    getPacientes(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getPacienteActualById(idPacienteActual): Observable<any> {
        let getUrl = BASE_URL+'/'+idPacienteActual;
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getPacienteActualByIdPaciente(idPaciente): Observable<any> {
      let getUrl = BASE_URL+'/paciente/'+idPaciente;
      return this.http.get(getUrl)
      .pipe(
      retry(1),
      catchError(this.errorHandl)
      )
  }

    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
    // paramsToQueryString(params: DataTableParams) {
    //     let result = [];
    
    //     if (params.offset != null) {
    //       let page = params.offset / params.limit;
    //       result.push(['page', page]);
    //     }
    //     if (params.limit != null) {
    //       result.push(['size', params.limit]);
    //     }
    //     if (params.sortBy != null) {
    //       let order = params.sortAsc ? 'ASC' : 'DESC';
    //       let sort = params.sortBy + ',' + order;
    //       result.push(['sort', sort]);
    //     }
    //     return result.map(param => param.join('=')).join('&');
    //   }
   
}


