import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmMenu } from '../../common/admtmmenu';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/menu';

@Injectable()
export class MenuService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    getMenusActivosbyModulo(idUser): Observable<any> {
        let getUrl = BASE_URL + '/todosbymodulo';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getMenu(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getMenuById(idMenu): Observable<any> {
        let findMenuUrl = BASE_URL+'/'+idMenu;
        return this.http.get(findMenuUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // getSubMenuByIdPadre(idMenuPadre): Observable<any> {
    //     let findMenuUrl = BASE_URL+'/todosbymenu/'+idMenuPadre;
    //     return this.http.get(findMenuUrl)
    //     .pipe(
    //     retry(1),
    //     catchError(this.errorHandl)
    //     )
    // }

    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
   
}
