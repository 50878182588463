import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LovsService } from '../services/admin/lovs.service';


@Injectable({
    providedIn: 'root',
})
export class DataService {

    dataValidacionDocumento: any;
    dataArticulaciones: any[];
    dataArticulacionesTabla: any[];

   constructor(private lovsService: LovsService) {} 

    obtenerArticulaciones(): Observable<any> {
        let obs = new Observable(observer => {
            if (this.dataArticulaciones && this.dataArticulaciones.length > 0){
                observer.next(this.dataArticulaciones);
            }else{
                this.lovsService.getAdmTmLovArticulacionActivas().subscribe(response => {
                    this.dataArticulaciones = response;
                    observer.next(this.dataArticulaciones)
                },error =>{
                    this.dataArticulaciones = null;
                    observer.next(this.dataArticulaciones)
                });
            }
        });
        return obs;
    }

}