import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {AdmTmPaciente} from '../../../../common/admtmpaciente';
//services

import { LovsService } from '../../../../services/admin/lovs.service';

import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { UserService } from '../../../../services/admin/user.service';
import { RegistroEvolucionService} from '../../../../services/hemofilia/registroevolucion.service';
import { RegistroinhibidoresService } from '../../../../services/hemofilia/registroinhibidores.service';
export interface estado {
  idEstado: number;
  nombre: string;
}
@Component({
  selector: 'app-ver-evoluciones',
  templateUrl: './ver-evoluciones.component.html',
  styleUrls: ['./ver-evoluciones.component.scss'],
  providers:[RegistroinhibidoresService,MessageService,PacienteService,RegistroEvolucionService],
  encapsulation: ViewEncapsulation.None
})
export class VerEvolucionesComponent implements OnInit {
  
  centros: AdmTmCentro[];
  
  /**/ 
  disableCreate = false;
  updatePacienteEvolucion:any= {};
  dosajeTratamientos: any=null;
  tratamientos: any=null;
  busyId =false;
  busyCreate: Subscription;
  paciente: any=null;
  tratamientosDosajeInhibidros: any = [];
  tratamientosDosajeInhibidrosOld: any = [];


  constructor(private centroService : CentroService,
    private toastrService: ToastrService,
    private registroInhibidoresService: RegistroinhibidoresService,
    private lovsService: LovsService,
    private pacienteService:PacienteService,
    private router : Router,
    private route: ActivatedRoute,
    private registroEvolucion:RegistroEvolucionService
  ) { }

  ngOnInit() {
   // this.clearUpdateSolicitud();
   this.loadTratamientos();
    let itemid= this.route.snapshot.params['id'];
    this.getEvolucion(itemid);

    console.log(this.busyId);
    

  }
  /*clearUpdateSolicitud(){
    this.updateToSolicitud = {
      idSolicitud: null,
      numSolicitud:null,
      sustento:null,
      observacion:null,
      idAprobador: null,
      estado:null,
      admTmUsuarioOrigen:null,
      admTmUsuarioDestino: null,
      admTmCentroOrigen: null,
      admTmCentroDestino:null,
      admTmPaciente: null,
      fechaInicio:null,
      fechaCreacion:null
    };
 
    this.disableUpdate=false;
    this.pacienteApellidosNombre=null;
  }*/
 
  loadTratamientos() {

    this.lovsService.getAdmTmLovTratamientoRecibeActivas()
    .subscribe(response => {
      this.tratamientos = response;
    });

    this.lovsService.getAdmTmLovTratamientoActivas()
    .subscribe(response => {
      this.dosajeTratamientos = response;
    })
  }


  goToPaciente(){
    let link = ['/evolucion'];
    this.router.navigate(link);
  }
  
  
  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;                    
            },
          error => this.showError('Error obtener centros')
        );
  }

  getEvolucion(id){
    this.registroEvolucion.getEvolucionesById(id).subscribe(
        data=>{
          console.log(data);
          this.updatePacienteEvolucion.idRegEvolucion=data.idRegEvolucion;
          this.updatePacienteEvolucion.fecha=data.fecha;
          this.updatePacienteEvolucion.admTmLovTratamientoRecibe=data.admTmLovTratamientoRecibe;
          this.updatePacienteEvolucion.dosis=data.dosis;
          this.updatePacienteEvolucion.tiempo=data.tiempo;
          this.updatePacienteEvolucion.observaciones=data.observaciones;
          this.updatePacienteEvolucion.admTmPaciente=data.admTmPaciente;
          this.updatePacienteEvolucion.codigoPaciente=data.admTmPaciente.codigoPaciente;
          this.updatePacienteEvolucion.pacienteApellidosNombre=data.admTmPaciente.apellidoPaterno+' '+data.admTmPaciente.apellidoMaterno+' '+data.admTmPaciente.nombresPaciente; 
          this.getPacienteUsuario(data.admTmPaciente);
          console.log(this.tratamientos,'TRATAMIENTO PACIENTE');
          for (let i = 0; i < this.tratamientos.length; i++) {
            if (this.tratamientos[i].idTratamientoRecibe === this.updatePacienteEvolucion.admTmLovTratamientoRecibe.idTratamientoRecibe) {
              this.updatePacienteEvolucion.admTmLovTratamientoRecibe = this.tratamientos[i];
              break;
            }
          }
        },
        error => this.showError('Error obtener paciente')

    );
  }


  getPacienteUsuario(paciente){
    this.pacienteService.getPacienteUsuario(paciente)
        .subscribe(
          data=>{
            console.log("getPacienteUsuario");
            console.log(data);
            this.updatePacienteEvolucion.pacienteUsuario=data;
            this.busyId=true;
                      
            },
          error => this.showError('Error obtener paciente usuario')
       
        );
  }

 

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}