import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmModulo } from '../../common/admtmmodulo';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/modulos';

@Injectable()
export class ModuloService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    getModulos(): Observable<any> {
        let getUrl = BASE_URL + '/todosActivosSinFiltro';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getModuloById(idModulo): Observable<any> {
        let findModuloUrl = BASE_URL+'/'+idModulo;
        return this.http.get(findModuloUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getModulosActivos(): Observable<any> {
        let url = BASE_URL+'/todosActivos';
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    

}    
