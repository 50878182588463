import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/medicamento';

@Injectable()
export class MedicamentoService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    // search(body: any, pageable: DataTableParams): Observable<Object> {
    //     let uri = BASE_URL + '/searchAndPage?' + this.paramsToQueryString(pageable);
    //     return this.http.post(uri, body, this.httpOptions)
    //     .pipe(
    //     retry(1),
    //     catchError(this.errorHandl)
    //     )
    // }

    crearMedicamento(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoMedicamento';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updateMedicamento(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }


    getMedicamentos(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
    
    getRegistrosFiltrado(paramsBuscar: any): Observable<any> {
        let getUrl = BASE_URL + '/filtrar';
        let paramsGet = new HttpParams()
            .set('codigoMedicamento', paramsBuscar.codigoMedicamento)
           .set('nombreGenerico', paramsBuscar.nombreGenerico)
            .set('nombreComercial', paramsBuscar.nombreComercial)
            .set('fabricante', paramsBuscar.fabricante)
            .set('estado', paramsBuscar.estado);
          
            
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
           
            params: paramsGet
        }
        console.log(paramsGet,'entrada a java')
        return this.http.get(getUrl, options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
  
    deleteUser(body: any): Observable<any> {
        var medicamento=JSON.parse(sessionStorage.getItem('currentUser'));
        let updateUrl = BASE_URL + '/updateMedicamentoEstado';
        return this.http.post(`${updateUrl}/${medicamento.idUsuario}`, body, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getMedicamentoById(idMedicamento): Observable<any> {
        let getUrl = BASE_URL+'/'+idMedicamento;
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
    // paramsToQueryString(params: DataTableParams) {
    //     let result = [];
    
    //     if (params.offset != null) {
    //       let page = params.offset / params.limit;
    //       result.push(['page', page]);
    //     }
    //     if (params.limit != null) {
    //       result.push(['size', params.limit]);
    //     }
    //     if (params.sortBy != null) {
    //       let order = params.sortAsc ? 'ASC' : 'DESC';
    //       let sort = params.sortBy + ',' + order;
    //       result.push(['sort', sort]);
    //     }
    //     return result.map(param => param.join('=')).join('&');
    //   }
   
}


