import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {AdmTmUsuario} from '../../common/admtmusuario';
import {AdmTmCentro} from '../../common/admtmcentro';


import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/usuarios';
@Injectable({
    providedIn: 'root'
  })

export class UserService {
    centroCurrentUser : AdmTmCentro;
    constructor(private http: HttpClient) { }

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    // GET
    getUserById(idUser): Observable<any> {
        let findUserUrl = BASE_URL+'/'+idUser;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getUsers(): Observable<any> {
        let url = BASE_URL+'/todos';
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
    getRegistrosFiltrado(paramsBuscar: any): Observable<any> {
        let getUrl = BASE_URL + '/filtrar';
        let paramsGet = new HttpParams()
            .set('codigoUsuario', paramsBuscar.codigoUsuario)
           .set('nombresUsuario', paramsBuscar.nombresUsuario )
            .set('apellidosUsuario', paramsBuscar.apellidosUsuario)
            .set('admTrPerfilxusuarios', paramsBuscar.admTrPerfilxusuarios)
            .set('genero', paramsBuscar.genero)
            .set('numeroDocumento', paramsBuscar.numeroDocumento)
            .set('estado', paramsBuscar.estado)
            .set('centro', paramsBuscar.centro);
            
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
           
            params: paramsGet
        }
        console.log(paramsGet,'entrada a java')
        return this.http.get(getUrl, options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }


    // POST
    createUser(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoUsuario';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    validateRepeatUser(body: Object): Observable<Object> {
        let createUrl = BASE_URL+'/validaUsuarioDuplicado';
        let bodyString = JSON.stringify(body);
        return this.http.post(createUrl, bodyString, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }



     // PUT
     updateUser(body: object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let updateUrl = BASE_URL + '/updateUsuario';
        return this.http.put(`${updateUrl}/${usuario.idUsuario}`, body, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // PUT
    deleteUser(body: any): Observable<any> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let updateUrl = BASE_URL + '/updateUsuarioEstado';
        return this.http.post(`${updateUrl}/${usuario.idUsuario}`, body, this.httpOptions)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        
        )
    }

     // GET
     getCentrosByUser(idUser): Observable<any> {
        let findUserUrl = BASE_URL+'/getCentrosUsuario/'+idUser;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

     // GET
     getUsersByCentro(idCentro): Observable<any> {
        let findUserUrl = BASE_URL+'/getUsuariosCentro/'+idCentro;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

  

    // Error handling
    errorHandl(error) {
            let errorMessage = '';
            if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
            } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            console.log(errorMessage);
            return throwError(errorMessage);
    }
   
     
   
}
