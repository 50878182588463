import { Component, OnInit ,ViewChild, ViewEncapsulation} from '@angular/core';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { MenuService } from '../../../../services/admin/menu.service';


import {AdmTmMenu} from '../../../../common/admtmmenu';


export interface estado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-listar-menus',
  templateUrl: './listar-menu.component.html',
  styleUrls: ['./listar-menu.component.scss'],
  providers:[MenuService,MessageService],
  encapsulation: ViewEncapsulation.None

})
export class ListarMenusComponent implements OnInit {
  items: AdmTmMenu[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEditar = false;
  canCrear = false;
  parametros:any;

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];

  displayedColumns: string[] = ['Modulo',  'Menu', 'Ruta','Descripcion'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private menuService : MenuService,
              private router : Router,
              private toastrService: ToastrService,
              private messageService: MessageService
    ) { }

    loadMenus() {
      this.menuService
        .getMenu()
        .subscribe(response => {
          this.items = response;
          this.dataSource=new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
    }

    clear() {
        this.items = [];

    }

    back(){
      let link = ['/'];
      this.router.navigate(link);
    }




    checkMenusPermitidos(url) {
        let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
        let response = false;
        for (let i = 0; i < menus.length; i++) {
            url = url.replace(/\d+/g, "id");
            if (url == '/' + menus[i].ruta) {
                response = true;
                break;
            }
        }
        return response;
    }

    
    

    ngOnInit() {
      this.messageService.sendMessage({ type: 'ruta', modulo: 'Admin', menu: 'ListarMenus' });
      this.clear();
      this.loadMenus();
    }


    showSuccess(mensaje) {
      this.toastrService.success(mensaje ,'¡Éxito!' );
    }
      
    showInfo(mensaje) {
      this.toastrService.info(mensaje ,'¡Información!' );
    }
      
    showWarning(mensaje) {
      this.toastrService.warning(mensaje ,'¡Advertencia!' );
    }

    showError(mensaje) {
      this.toastrService.error(mensaje , '¡Error!' );
    } 

}
