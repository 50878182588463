//import { environment } from './environments/environment';
import { environment} from './environments/environment.prod';
export class Util {
    constructor() { }

    public static IP_API_CONNECTION: string = environment.path_backend;
    public static SUCCESS_USUARIO_CREACION: number = 0;
    public static ERROR_USUARIO_DOCUMENTO_REPETIDO: number = 1;
    public static ERROR_USUARIO_MAIL_REPETIDO: number = 2;
    public static ERROR_USUARIO_AMBOS_REPETIDO: number = 3;
    public static ERROR_USUARIO_CREACION: number = 4;

    public static IP_API_FILES: string = environment.path_backend + '/registro/archivos/';

}
