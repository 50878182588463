import { Injectable } from '@angular/core';
import { CanActivate, Router,
         ActivatedRouteSnapshot,
         RouterStateSnapshot,
         CanActivateChild }  from '@angular/router';
         
@Injectable()
export class MenuGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;

        return this.check(url);    
    }

    check(url: string): boolean {
        let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
        let response = false;
     //   console.log('check menus');
       // console.log(menus);

        for(let i=0; i<menus.length ; i++){
            url=url.replace(/\d+/g, "id");
            //console.log('url '+url);
            //console.log(menus[i].ruta);
            if(url=='/'+menus[i].ruta){
                response= true;
                break;
            }
        }
        //console.log(response);
        return response;
    }
}