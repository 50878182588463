import { Component, OnInit ,ViewChild, ViewEncapsulation} from '@angular/core';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { PerfilService } from '../../../../services/mantenimiento/perfil.service';

import {AdmTmPerfil} from '../../../../common/admtmperfil';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-listar-perfiles',
  templateUrl: './listar-perfiles.component.html',
  styleUrls: ['./listar-perfiles.component.scss'],
  providers:[PerfilService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ListarPerfilesComponent implements OnInit {
  displayedColumns: string[] = ['Nombre',  'Descripcion', 'Estado', 'Acciones'];
  dataSource:MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  items = [];
  itemCount = 0;
  params;
  itemToDelete : AdmTmPerfil;
  response:string;
  canSelect = true;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  busyDelete: Promise<any>;

  constructor(private perfilService : PerfilService,
    private router : Router,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dialog: MatDialog) { }

    validarAnular(item){
      this.openDialog(item);
  
    }

    openDialog(item): void {
      console.log(item);
      let aux:any;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "¿Seguro que desea eliminar el perfil "+item.idPerfil+"?"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if(result){
          
          aux = item.idPerfil;
          console.log('id perfil',aux);
          this.perfilService.deleteUser(aux)
          .subscribe(
            data => {
                    
              console.log(data,'data obtenida');
              this.loadPerfiles();
             
              
              if(data.estado==0)
                  { 
                      this.showSuccess('PERFIL MODIFICADO');
                  }
                  if(data.estado!=0)
                  {                
                    this.showError('FALLO AL  MODIFICAR EL PERFIL')
                  }
            },
                    error => this.showError('Error inesperado '),
            );
  
        }
      });
    }
  reloadItems(params) {
    // this.params=params;
    // this.perfilService.query(params).then(result => {
    //     this.items = result.items.content;
    //     this.itemCount = result.items.totalElements;
    //     this.util.convertToTextState(this.items);
    //     });
  }

 

  goToNewPerfil(){
    let link = ['/perfil/nuevo'];
    this.router.navigate(link);
  }
  
  goToEditPerfil(item : AdmTmPerfil){
      let link = ['/perfil', item.idPerfil];
      this.router.navigate(link);
  }

  goToView(item : AdmTmPerfil){
    let link = ['/perfil/ver/', item.idPerfil];
    this.router.navigate(link);
  }

  loadPerfiles() {
    let aux:any;
    this.perfilService.getPerfiles()
      .subscribe(data => {
        aux=data
        for (let i = 0; i < data.length; i++) {
          if (data[i].estado ===1) {
            aux[i].estado ='Activo';
          }
          else if (data[i].estado ===0) {
            aux[i].estado ='Desactivo';
          }
        }

        this.dataSource=new MatTableDataSource(aux);
        // this.dataSource.paginator = this.paginator;
        console.log('loadPerfiles');
      })
  }
  checkMenusPermitidos(url){
      let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
      let response = false;
      for(let i=0; i<menus.length ; i++){
          url=url.replace(/\d+/g, "id");
          if(url=='/'+menus[i].ruta){
              response= true;
              break;
          }
      }
      return response;
  }

  checkPermisos(){
     this.canCreate = this.checkMenusPermitidos('/perfil/nuevo');
     this.canEdit = this.checkMenusPermitidos('/perfil/id');
     this.canDelete = this.canCreate;

  }

  back() {
    let link = ['/'];
    this.router.navigate(link);
  }
  
  ngOnInit() {

    this.checkPermisos();
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarPerfiles' });
    this.loadPerfiles();
    

  }


  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
