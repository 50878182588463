import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//services
import { MessageService } from '../../../../services/MessageService';

import { PerfilService } from '../../../../services/mantenimiento/perfil.service';
import { MenuService } from '../../../../services/admin/menu.service';
//domain
import {AdmTmPerfil} from '../../../../common/admtmperfil';
import { AdmTmMenu } from '../../../../common/admtmmenu';
@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: ['./editar-perfil.component.scss'],
  providers:[PerfilService,MenuService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class EditarPerfilComponent implements OnInit {
  perfilToEdit : AdmTmPerfil;
  response:string;
  menuPerfilActual : number[];
  menu : AdmTmMenu[];
  busyId: Subscription;
  busyUpdate: Subscription;
  busyMenus: Subscription;

  constructor(
    private perfilService : PerfilService,
    private menuService : MenuService,
    private route: ActivatedRoute,
    private router : Router,
    private toastrService: ToastrService
  ) { }
  getPerfilById(itemid){
    this.busyId=this.perfilService.getPerfilById(itemid)
        .subscribe(data=>{ this.perfilToEdit = data; 
                      this.getPerfilMenus(data);
        })
  }

  getPerfilMenus(data){
    for(let i = 0; i< data.admTrMenuxperfils.length ; i++ ){
        data.admTrMenuxperfils[i].admTmMenu.admTmModulo = null;
        data.admTrMenuxperfils[i].admTmMenu.admTmMenuPadre = null;
        if(data.admTrMenuxperfils[i].estado==1){
          this.menuPerfilActual.push(data.admTrMenuxperfils[i].admTmMenu.idMenu);
        }
      }
  }

  updatePerfil() {
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.doUpdatePerfil();
    }
  }


  doUpdatePerfil(){
    let perfilData = {
                "admTmPerfil": this.perfilToEdit,
                "idMenus": this.menuPerfilActual
                }
    this.busyUpdate =  this.perfilService.updatePerfil(perfilData)
      .subscribe( 
          data => {

                //this.response=JSON.stringify(data);
                //this.goToPerfiles();
                //this.showSuccess();            
                if(data['codigoValidacion']==1)
                { 
                    this.goToPerfiles();
                    this.showWarning(data['mensajeValidacion']);
                }
                if(data['codigoValidacion']==2)
                { 
                    this.goToPerfiles();            
                    this.showSuccess(data['mensajeValidacion']);
                }
                if(data['codigoValidacion']==3)
                { 
                    this.goToPerfiles();            
                    this.showError(data['mensajeValidacion']);                    
                }
                this.busyUpdate=null;
                  },
          error => this.showError('Error inesperado')
        );
  }

  getMenu(){
    this.busyMenus = this.menuService.getMenu()
        .subscribe(
          data=>this.menu=data,
           error => this.showError('Error inesperado')
        );
  }
 
  getMenuSelected(m : number){
     let indexMenu = this.menuPerfilActual.indexOf(m);
     if( indexMenu != -1){
       this.menuPerfilActual.splice(indexMenu, 1);
     }else{
       this.menuPerfilActual.push(m);
     }
  }
  isMenuSelected(m : number){
     let indexMenu = this.menuPerfilActual.indexOf(m);
     if( indexMenu != -1){
       return true;
     }else{
       return false;
     }
  }

  goToPerfiles(){
    let link = ['/perfil'];
    this.router.navigate(link);
  }

  clearPerfilToEdit(){
    this.perfilToEdit = {
      idPerfil:null,
      descripcion:null,
      estado:null,
      isSuperAdmin:null,
      idUsuarioActualizacion:null,
      idUsuarioCreacion:null,
      nombrePerfil:null,
      admTrMenuxperfils:[]
      };
      this.menuPerfilActual=[];
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

  ngOnInit() {
    this.clearPerfilToEdit();
    this.getMenu();
    let itemid= this.route.snapshot.params['id'];
    this.getPerfilById(itemid);
  }


}
