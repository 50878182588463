import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdmTmRegistro } from '../../../../common/admtmregistro';
import { LovsService } from '../../../../services/admin/lovs.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { RegistrodiagService } from '../../../../services/hemofilia/registrodiag.service';
import { DataService } from '../../../../services/DataService';
import { Output, EventEmitter } from '@angular/core'; 

@Component({
  selector: 'app-registro-diagnostico',
  templateUrl: './registro-diagnostico.component.html',
  styleUrls: ['./registro-diagnostico.component.scss'],
  providers:[LovsService,ToastrService,RegistroService],
  encapsulation: ViewEncapsulation.None
})
export class RegistroDiagnosticoComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  registro: AdmTmRegistro;
  newPacienteDiagn:any=null;
  disableCreate = false;
  factores: any = null;
  serveridades: any = null;
  dataArticulaciones: any = null;
  dataPacienteRegistro: any = null;
  idRegistro: any = null;

  constructor(
    private toastrService: ToastrService,
    private lovsService: LovsService,
    private registroService: RegistroService,
    private dataService : DataService,
    private router : Router,
    private route: ActivatedRoute,
    private registrodiagService: RegistrodiagService
  ) { }

  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.newPacienteDiagn.hemTrRegistro = {
      idRegistro: data.idRegistro
    }
    this.idRegistro = data.idRegistro;
  }

  loadPercentajeFactor(){
    for (let i = 0; i < this.serveridades.length; i++) {
      if (this.serveridades[i].porcentajeInicio <= this.newPacienteDiagn.percentajeFactor && this.serveridades[i].porcentajeFin > this.newPacienteDiagn.percentajeFactor ) {
        this.newPacienteDiagn.admTmLovSeveridad = this.serveridades[i];
        break;
      }
    }
  }

  revisarCampoDeficiencia(){
    if (this.newPacienteDiagn && this.newPacienteDiagn.admTmLovFactor && this.factores){
      let factor = this.factores.find(i => i.idFactor === this.newPacienteDiagn.admTmLovFactor.idFactor);
      if (factor){
        this.newPacienteDiagn.admTmLovFactor = factor;
      }
    }
  }

  revisarCampoSeveridad(){
    if (this.newPacienteDiagn && this.newPacienteDiagn.admTmLovSeveridad && this.serveridades){
      let severidad = this.serveridades.find(i => i.idSeveridad === this.newPacienteDiagn.admTmLovSeveridad.idSeveridad);
      if (severidad){
        this.newPacienteDiagn.admTmLovSeveridad = severidad;
      }
    }
  }

  clearNewPacienteDiagn(){
    this.newPacienteDiagn = {
      admTmLovFactor: null,
      admTmLovSeveridad:null,
      hemTrRegistro: null,
      percentajeFactor:null
    };
  }

  getRegistroById(id){
    this.registroService.getRegistroById(id).subscribe(
      data => {
        this.registro = data;
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovFactores(){
    this.lovsService.getAdmTmLovFactorActivas().subscribe(
      data => {
        this.factores = data;
        this.revisarCampoDeficiencia();
      },
      error => this.showError('Error obtener yes no')
    );
  }
  getLovServeridades(){
    this.lovsService.getAdmTmLovServeridadActivas().subscribe(
      data => {
        this.serveridades = data;
        this.revisarCampoSeveridad();
      },
      error => this.showError('Error obtener yes no')
    );
  }

  cargarDiagnostico(){
    this.registrodiagService.getRegistroDiagByRegistro(this.idRegistro).subscribe(
      data => {
        if (data && data.length > 0){
          this.newPacienteDiagn = data[0];
          this.revisarCampoDeficiencia();
          this.revisarCampoSeveridad();
        }
      }
    );
  }

  createRegistroDiagnostico(){
    var f = document.getElementsByTagName('form')[1];
    if(f.reportValidity()){
      // Crear diagnostico
      this.disableCreate = true;

      let dataCrear = {
        idDiagnostico: this.newPacienteDiagn.idRegistroDiag,
        idRegistro: this.idRegistro,
        idFactor: this.newPacienteDiagn.admTmLovFactor.idFactor,
        idServeridad: this.newPacienteDiagn.admTmLovSeveridad.idSeveridad,
        percentajeFactor: this.newPacienteDiagn.percentajeFactor,
        idRegistroEvolucion: null //Jeancarlo A. 18/03/21
      }

      this.registrodiagService.createRegistroDiag(dataCrear).subscribe(data => {
          if (data['codigoValidacion'] == 1){
            this.showWarning(data['mensajeValidacion']);
            //this.disableCreate = false;
          }else if (data['codigoValidacion'] == 2){
            this.newPacienteDiagn.id_registro_diag = data['id'];
            this.showSuccess('Diagnóstico guardado.')
            this.siguienteTab();
          }else if (data['codigoValidacion'] == 3){
            this.showError(data['mensajeValidacion']);
            //this.disableCreate = false;
          }
        },
        error => this.showError('Error inesperado'),
      );
    }
  }

  siguienteTab(){
    this.dataRegistrada.next(this.newPacienteDiagn);
  }

  ngOnInit() {
    this.clearNewPacienteDiagn();

    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegistro = itemid;
      this.newPacienteDiagn.hemTrRegistro = {
        idRegistro: itemid
      }
      this.cargarDiagnostico();
    }

    // prueba
    this.dataService.obtenerArticulaciones().subscribe(response => {
      this.dataArticulaciones = response;
      if (!this.dataArticulaciones || this.dataArticulaciones.length < 1){
        this.showError('Error inesperado.')
      }
    });

    this.getLovFactores();
    this.getLovServeridades();
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
