import { Component, OnInit ,EventEmitter,Output,ViewEncapsulation} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { LovsService } from '../../../../services/admin/lovs.service';
import { RegistroTratamientoService } from '../../../../services/hemofilia/registrotratamiento.service';
import { DataService } from '../../../../services/DataService';
//import { $ } from 'protractor';

import * as $ from 'jquery';

@Component({
  selector: 'app-registro-tratamiento',
  templateUrl: './registro-tratamiento.component.html',
  styleUrls: ['./registro-tratamiento.component.scss'],
  providers:[RegistroTratamientoService,DataService],
  encapsulation: ViewEncapsulation.None
  
})
export class RegistroTratamientoComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  dataPacienteRegistro: any = null;
  newRegistroTrat: any=null;
  idRegistro: any = null;
  tratamientos: any=null;
  tratamientosActual: any=null;
  tipoConcentrados: any=null;
  yesnoprofilaxis: any=null;
  tipoProfilaxis:any=null;
  ProfilaxisRealizada: any = null;
  frecuencias: any=null;
  frecuencias1: any=null; //Jeancarlo A. 30-04-21
  frecuencias2: any=null; //Jeancarlo A. 30-04-21
  disableCreate: boolean = false;
  dataRecibida: any = null;


  cargadoData: boolean = false;
  cargadoConcentrados: boolean = false;
  cargadoProfilaxis: boolean = false;
  cargadoProfilaxisRealizado: boolean = false;
  cargadoTratamientos: boolean = false;
  cargadoYesNo: boolean = false;
  cargadoFrecuencia: boolean = false;
  noProfilaxis: boolean = true;
  disableEdit: boolean = false;
  ningunoDos: boolean = true; //Jeancarlo A. 27-04-21
  
  constructor(private toastrService: ToastrService,
              private lovsService: LovsService,
              private dataService : DataService,
              private router : Router,
              private route: ActivatedRoute,
              private registroTratamientoService: RegistroTratamientoService
  
  ) { }

  recibirData(data) {
    this.dataPacienteRegistro = data;
    this.disableEdit=data.disableEdit;
    this.idRegistro = data.idRegistro;
    this.newRegistroTrat.hemTrRegistro = {
      idRegistro: data.idRegistro
    }

    /*if (data.hemTrRegistroEstado.idRegistroEstado > 1){ // 1: En proceso
      $("input[id^='loc_cod_']").attr("disabled",true);
      $("input[id^='_2']").attr("disabled",true);
      $("input[id^='tratamiento_']").attr("disabled",true);
    }*/
  }

  clearNewRegistroTrata(){
    this.newRegistroTrat = {
      tratamientoPrimer: null,
      tratamientoActual:null,
      tipoConcentrado:null,
      recibeProfilaxis:null,
      tipoProfilaxis:null,
      ProfilaxisRealizada:null,
      edadInicio:null,
      mesInicio:null,
      pesoActual:null,
      UIkilodosis:null,
      frecuencia:null,
      idRegistroEvolucion: null, //Jeancarlo A. 18-03-21
      tallaActual:null //Jeancarlo A. 29-04-21
    };
  }


  getLovTratamientos(){
    this.lovsService.getAdmTmLovTratamientoActivas().subscribe(
      data=>{
        let tratamientoT =[]
        for (var i = 0; i < data.length; i++){
          if(data[i].idTratamiento<=5){
            //tratamientoT.push(data[i])
            tratamientoT.push({
              idTratamiento:data[i].idTratamiento,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }
        }
        this.tratamientos = tratamientoT;
        this.tratamientosActual = data;
        this.cargadoTratamientos = true;
        this.revisarTratamientos()
      },
      error => this.showError('Error obtener yes no')
    );
  }

  //Jie 02-04-2021
  revisarTratamientos(){
    if (this.cargadoTratamientos && this.tratamientos.length > 0 && this.cargadoData && this.dataRecibida && this.dataRecibida.primerOtros){/*Forma original: if (this.cargadoTratamientos && this.tratamientos.length > 0 && this.cargadoData && this.dataRecibida*/
      for (var i = 0; i < this.dataRecibida.primerOtros.length; i++){
        let tratamiento = this.tratamientos.find(l => l.idTratamiento === this.dataRecibida.primerOtros[i].id1Otros);
        if (tratamiento){
          tratamiento.checked = true;
          tratamiento.nombreOtros = this.dataRecibida.primerOtros[i].nombreOtros;
        }
      }

      if (this.dataRecibida.actualOtros){
        for (var i = 0; i < this.tratamientosActual.length; i++){
          if(this.dataRecibida.actualOtros.length>0){
            if (this.dataRecibida.actualOtros[0].idActualOtros == this.tratamientosActual[i].idTratamiento){
              this.newRegistroTrat.tratamientosActual = this.tratamientosActual[i];
              if(this.tratamientosActual[i].idTratamiento==5){
                this.newRegistroTrat.tratamientosActual.nombreOtrosActual=this.dataRecibida.actualOtros[0].nombreOtros
              }
              break;
            }
          }
        }
      }
      


    }
  }


  getLovTipoConcentrados(){
    this.lovsService.getAdmTmLovTiposConcentradosFactoresActivas().subscribe(
      data=>{
        this.tipoConcentrados = data;
        this.cargadoConcentrados = true;
        this.verificarCarga()
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(
      data=>{
        this.yesnoprofilaxis=data;
        this.cargadoYesNo = true;
        this.verificarCarga()
      },
      error => this.showError('Error obtener yes no')
    );
  }
  getLovProfilaxisRealizada(){
    this.lovsService.getAdmTmLovProfilaxisRealizadaActivas().subscribe(
      data=>{
        this.ProfilaxisRealizada=data;
        this.cargadoProfilaxisRealizado = true;
        this.verificarCarga();
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovTipoProfilaxis(){
    this.lovsService.getAdmTmLovTipoProfilaxisActivas().subscribe(
      data=>{
        this.tipoProfilaxis=data;
        this.cargadoProfilaxis = true;
        this.verificarCarga()
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovFrecuencia(){
    this.lovsService.getAdmTmLovFrecuenciaActivas().subscribe(
      data=>{
        let frecuenciaCon = []
        let frecuenciaEmici = []
        for(var i=0;i<data.length;i++){
          if(data[i].idFrecuencia < 3){
            frecuenciaEmici.push({
              idFrecuencia:data[i].idFrecuencia,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          } else if(data[i].idFrecuencia > 3){
            frecuenciaCon.push({
              idFrecuencia:data[i].idFrecuencia,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }else {
            frecuenciaEmici.push({
              idFrecuencia:data[i].idFrecuencia,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
            frecuenciaCon.push({
              idFrecuencia:data[i].idFrecuencia,
              codigo:data[i].codigo,
              nombre:data[i].nombre
            })
          }
        }
        this.frecuencias1 = frecuenciaEmici;
        this.frecuencias2 = frecuenciaCon;
        this.cargadoFrecuencia = true;
        this.verificarCarga()
      },
      error => this.showError('Error obtener yes no')
    );
  }

  siguienteTab(){
    this.dataRegistrada.next(this.newRegistroTrat);
  }

  cargarTratamiento(){
    this.registroTratamientoService.getRegistroTratatamientoByRegistro(this.idRegistro).subscribe(
      data => {
        this.dataRecibida = data;
        this.cargadoData = true;
        this.dataRecibida.tratamientos=data.primerOtros;
        this.dataRecibida.actualTratamiento=data.actualOtros;
        this.verificarCarga();
        this.revisarTratamientos();
        console.log('');
      }
    );
  }

  verificarCarga(){
    if (this.cargadoData && this.cargadoFrecuencia && this.cargadoConcentrados && this.cargadoProfilaxis && this.cargadoTratamientos && this.cargadoYesNo && this.cargadoProfilaxisRealizado){
      if (this.idRegistro && this.dataRecibida){

        if (this.dataRecibida.idTipoConcentradosFact>0){
          for (var i = 0; i < this.tipoConcentrados.length; i++){
            if (this.dataRecibida.idTipoConcentradosFact == this.tipoConcentrados[i].idTipoConFact){
              this.newRegistroTrat.tipoConcentrado = this.tipoConcentrados[i];
              break;
            }
          }
        }

        if (this.dataRecibida.idYNRecibeProfilaxis>0){
          for (var i = 0; i < this.yesnoprofilaxis.length; i++){
            if (this.dataRecibida.idYNRecibeProfilaxis == this.yesnoprofilaxis[i].idYesNo){
              this.newRegistroTrat.recibeProfilaxis = this.yesnoprofilaxis[i];
              break;
            }
          }
        }else{
          this.newRegistroTrat.recibeProfilaxis = this.yesnoprofilaxis[0];
        }

        if (this.newRegistroTrat.recibeProfilaxis && this.newRegistroTrat.recibeProfilaxis.idYesNo == 1){
          this.noProfilaxis = false;

          if (this.dataRecibida.idTipoProfilaxis>0){
            for (var i = 0; i < this.tipoProfilaxis.length; i++){
              if (this.dataRecibida.idTipoProfilaxis == this.tipoProfilaxis[i].idTipoProfilaxis){
                this.newRegistroTrat.tipoProfilaxis = this.tipoProfilaxis[i];
                break;
              }
            }
          }
          if (this.dataRecibida.idProfilaxisRealizada>0){
            for (var i = 0; i < this.ProfilaxisRealizada.length; i++){
              console.log('profilaxisrealizada:');
              console.log(this.dataRecibida);
              if (this.dataRecibida.idProfilaxisRealizada == this.ProfilaxisRealizada[i].idProfilaxisRealizada ){
                this.newRegistroTrat.ProfilaxisRealizada = this.ProfilaxisRealizada[i];
                break;
              }
            }
          }
        }else{
          this.noProfilaxis = true;
        }

        /*if (this.dataRecibida.idFrecuencia>0){
          for (var i = 0; i < this.frecuencias.length; i++){
            if (this.dataRecibida.idFrecuencia == this.frecuencias[i].idFrecuencia){
              this.newRegistroTrat.frecuencia = this.frecuencias[i];
              break;
            }
          }
        }*/

        if(this.dataRecibida.idProfilaxisRealizada){
          for(var i = 0;i<this.ProfilaxisRealizada.length;i++){
            if(this.dataRecibida.idProfilaxisRealizada == this.ProfilaxisRealizada[i].idProfilaxisRealizada){
              this.newRegistroTrat.ProfilaxisRealizada = this.ProfilaxisRealizada[i];
              break;
            }
          }
          if(this.newRegistroTrat.ProfilaxisRealizada.idProfilaxisRealizada == 1){
            this.frecuencias=this.frecuencias2;
          }else{
            this.frecuencias=this.frecuencias1;
          }
          if(this.dataRecibida.idProfilaxisRealizada){
            for(var i = 0;i < this.ProfilaxisRealizada.length;i++){
              if(this.dataRecibida.idFrecuencia == this.frecuencias[i].idFrecuencia){
                this.newRegistroTrat.frecuencia = this.frecuencias[i];
                break;
              }
            }
          }
        }

        if (this.dataRecibida.fechaInicioProfilaxis){
          //this.newRegistroTrat.fechaInicioProfilaxis = new Date(this.dataRecibida.fechaInicioProfilaxis + 'T00:00:00');
          this.newRegistroTrat.fechaInicioProfilaxis = new Date(this.dataRecibida.fechaInicioProfilaxis);
        }
        
        console.log(this.dataRecibida);

        this.newRegistroTrat.edadInicio = this.dataRecibida.edadInicioProfilaxis;
        this.newRegistroTrat.mesInicio = this.dataRecibida.mesesInicioProfilaxis;
        this.newRegistroTrat.pesoActual = this.dataRecibida.pesoActual;
        this.newRegistroTrat.kilodosis = this.dataRecibida.kilodosis;
        this.newRegistroTrat.edadActual = this.dataRecibida.edadActual;
        this.newRegistroTrat.tratamientosRecibidos = this.dataRecibida.tratamientosRecibidos;
        this.newRegistroTrat.modoAdministracion = this.dataRecibida.modoAdministracion;
        this.newRegistroTrat.idRegistroEvolucion = null; //Jeancarlo A. 18-03-21
        this.newRegistroTrat.tallaActual = this.dataRecibida.tallaActual;

      }
    }
    console.log('');
  }

  recibeProfilaxisCambiado(){
    if (this.newRegistroTrat.recibeProfilaxis && this.newRegistroTrat.recibeProfilaxis.idYesNo == 1){
      this.noProfilaxis = false;
    }else{
      this.noProfilaxis = true;
      //$("#tipoProfilaxis").removeAttr("required");
      this.newRegistroTrat.tipoProfilaxis = null;
      //$("#ProfilaxisRealizada").removeAttr("required");
      this.newRegistroTrat.ProfilaxisRealizada = null;
      //$("#edadInicio").removeAttr("required");
      this.newRegistroTrat.edadInicio = null;
      //$("#mesInicio").removeAttr("required");
      this.newRegistroTrat.mesInicio = null;
      //$("#kilodosis").removeAttr("required");
      this.newRegistroTrat.kilodosis = null;
      //$("#frecuencia").removeAttr("required");
      this.newRegistroTrat.frecuencia = null;
    }
  }

  bloquear3(){
    if(this.newRegistroTrat.tratamientosActual.idTratamiento == 6){
      $("input[id^='loc_cod_']").prop("checked",false);
      //$("input[id^='loc_cod_']").attr("disabled",true);
      this.ningunoDos = true;
      this.newRegistroTrat.tipoConcentrado = null;
      if(this.tipoConcentrados){

        for(var i = 0;i < this.tipoConcentrados.length; i++){
          if(this.tipoConcentrados[i].checked){
            console.log(this.tipoConcentrados[i]);
            this.tipoConcentrados[i].checked=false;
          }
        }
      }
    } else{
      //$("input[id^='loc_cod_']").removeAttr("disabled",false);
      this.ningunoDos = false;
    }
  }

  /*Metodo Para cambiar el título segun el comboBox*/ 
  TituloChange(){
    if(this.newRegistroTrat.ProfilaxisRealizada.idProfilaxisRealizada == 2){
      document.getElementById('tituloUI').innerHTML='<font color="red">*</font>mg/k/dosis';
    }
    else{
      document.getElementById('tituloUI').innerHTML='<font color="red">*</font>UI/kilo/dosis';
    }
    this.frecuenciaChange();
  }

  frecuenciaChange(){
    this.newRegistroTrat.frecuencia = null;
    if(this.newRegistroTrat.ProfilaxisRealizada.idProfilaxisRealizada == 1){
      this.frecuencias=this.frecuencias2;
    }else{
      this.frecuencias=this.frecuencias1;
    }
  }

  createRegistroTratamiento(){

    let primerOtros = []
      for (var i = 0; i < this.tratamientos.length; i++){
        // if (this.listaLocalizaciones[i].elegido){
        //   episodios.push(this.listaLocalizaciones[i].idLocalCuerpo);
        // }
        if (this.tratamientos[i].checked){
          console.log(this.tratamientos[i]);
          primerOtros.push({
            id1Otros: this.tratamientos[i].idTratamiento,
            nombreOtros: this.tratamientos[i].nombreOtros,
          });
        }
      }

      let actualOtros = []
      if(this.newRegistroTrat.tratamientosActual){
          actualOtros.push({
            idActualOtros: this.newRegistroTrat.tratamientosActual.idTratamiento,
            nombreOtros: this.newRegistroTrat.tratamientosActual.nombreOtrosActual
          });

      }
      let idTipoConcentradosFact
      if(this.newRegistroTrat.tipoConcentrado == null){
        idTipoConcentradosFact = 0;
      }
      else{
        idTipoConcentradosFact = this.newRegistroTrat.tipoConcentrado.idTipoConFact ? this.newRegistroTrat.tipoConcentrado.idTipoConFact : 0
      }

console.log(primerOtros);
console.log(actualOtros);
    

    if(primerOtros == null || primerOtros.length == 0 ){
      this.showWarning("Se debe elegir mínimo un tratamiento utilizado en el primer episodio hemorrágico");
    }
    else if (actualOtros == null || actualOtros.length == 0){
      this.showWarning("Se debe elegir un tratamiento utilizado actualmente");
    }
    else if(this.newRegistroTrat.tipoConcentrado == null && this.newRegistroTrat.tratamientosActual.idTratamiento !=6){
        this.showWarning("Es obligatorio seleccionar un tipo de concentrado de factor");
      }else if(this.newRegistroTrat.recibeProfilaxis == null){
        this.showWarning("Es obligatorio indicar si se recibe profilaxis");
      }
  /*    else if (this.newRegistroTrat.edadInicio == 0 || this.newRegistroTrat.edadInicio == null){
        this.showWarning("Se debe seleccionar una edad mayor a 0");
      }
      else if (this.newRegistroTrat.mesInicio == 0 || this.newRegistroTrat.mesInicio == null){
        this.showWarning("Se debe seleccionar un mes mayor a 0");
      }*/else if(this.newRegistroTrat.ProfilaxisRealizada == null && this.newRegistroTrat.recibeProfilaxis.idYesNo == 1){
        this.showWarning("Indique la profilaxis realizada");
      }else if(this.newRegistroTrat.recibeProfilaxis.idYesNo == 1 && (this.newRegistroTrat.edadInicio == 0 || this.newRegistroTrat.edadInicio == null)){
        this.showWarning("Indique la edad (por lo menos los años) del inicio de profilaxis")
      }
      else if (this.newRegistroTrat.pesoActual == 0 || this.newRegistroTrat.pesoActual == null){
        this.showWarning("Se debe seleccionar un peso mayor a 0");
      } else if(this.newRegistroTrat.tallaActual == 0 || this.newRegistroTrat.tallaActual == null){
        this.showWarning("Se debe seleccionar una talla mayor a 0");
      }
      else if (this.newRegistroTrat.recibeProfilaxis.idYesNo == 1 && (this.newRegistroTrat.kilodosis == 0 || this.newRegistroTrat.kilodosis == null)){
        this.showWarning("Se debe seleccionar un UI/kilo/dosis mayor a 0");
      }  else if (this.newRegistroTrat.recibeProfilaxis.idYesNo == 1 && this.newRegistroTrat.frecuencia == null){
        this.showWarning("Se debe seleccionar una Frecuencia");
      }
    
else{

    let dataGuardar: any = {
      //cambiando de lugar: hemTrRegistro: this.newRegistroTrat.hemTrRegistro,
      //episodioHemoTrata: {
      //  idTratamiento: this.newRegistroTrat.tratamientoPrimer.idTratamiento
      //},
      primerOtros: primerOtros,
      actualOtros: actualOtros,
      idTipoConcentradosFact: idTipoConcentradosFact,
      idYNRecibeProfilaxis : this.newRegistroTrat.recibeProfilaxis.idYesNo,
      //recibeProfilaxis: {
      //  idYesNo: this.newRegistroTrat.recibeProfilaxis.idYesNo
      //},
      /*
      admTmLovTipoProfilaxis: {
        idTipoProfilaxis: this.newRegistroTrat.tipoProfilaxis.idTipoProfilaxis
      },
      */
      idTipoProfilaxis: this.newRegistroTrat.tipoProfilaxis ? this.newRegistroTrat.tipoProfilaxis.idTipoProfilaxis : 0 ,
      idProfilaxisRealizada: this.newRegistroTrat.ProfilaxisRealizada ? this.newRegistroTrat.ProfilaxisRealizada.idProfilaxisRealizada :0,
      edadInicioProfilaxis: this.newRegistroTrat.edadInicio,
      mesesInicioProfilaxis: this.newRegistroTrat.mesInicio,
      pesoActual: this.newRegistroTrat.pesoActual,
      kilodosis: this.newRegistroTrat.kilodosis,
      idRegistroEvolucion: null, //Jeancarlo A. 18-03-21
      edadActual: 0,//this.newRegistroTrat.edadActual,
      idFrecuencia: this.newRegistroTrat.frecuencia ? this.newRegistroTrat.frecuencia.idFrecuencia :0,
      tratamientosRecibidos: this.newRegistroTrat.tratamientosRecibidos,
      modoAdministracion: this.newRegistroTrat.modoAdministracion,
      fechaInicioProfilaxis: this.newRegistroTrat.fechaInicioProfilaxis,
      idRegistro:this.newRegistroTrat.hemTrRegistro.idRegistro,
      tallaActual: this.newRegistroTrat.tallaActual
      //hemTrRegistro: this.newRegistroTrat.hemTrRegistro
    };
 
   
  
    //if (!this.noProfilaxis){
    //  dataGuardar.admTmLovTipoProfilaxis =  {
    //    idTipoProfilaxis: this.newRegistroTrat.tipoProfilaxis.idTipoProfilaxis
    //  };

    //  dataGuardar.admTmLovProfilaxisRealizada = {
    //    idProfilaxisRealizada: this.newRegistroTrat.ProfilaxisRealizada.idProfilaxisRealizada
    //  };

    //}
    if(this.dataRecibida && this.dataRecibida.idRegTratamiento){
      dataGuardar.idRegTratamiento = this.dataRecibida.idRegTratamiento;
    }

    console.log(dataGuardar);

    this.registroTratamientoService.createRegistroTratamiento(dataGuardar).subscribe(data => {
      console.log(data);
      if (data['codigoValidacion'] == 1){
        this.showWarning(data['mensajeValidacion']);
        this.disableCreate = false;
      }else if (data['codigoValidacion'] == 2){
        this.newRegistroTrat.idRegTratam = data['id'];
        this.showSuccess('Tratamiento guardado.')
        this.siguienteTab();
      }else if (data['codigoValidacion'] == 3){
        this.showError(data['mensajeValidacion']);
        this.disableCreate = false;
      }
    },
    error => this.showError('Error inesperado'),
    );

  }
  }

  noEscribas(){
    var mes=document.getElementsByName("mesInicio");
    $(mes).keydown(function(){
      return false
    });
  }

  ngOnInit() {
    console.log('dentro de tratamiento');
    this.clearNewRegistroTrata();
    this.noEscribas();

    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegistro = itemid;
      this.newRegistroTrat.hemTrRegistro = {
        idRegistro: itemid
      }
      this.cargarTratamiento();
    }else{
      this.cargadoData = true;
    }

    this.getLovFrecuencia(); 
    this.getLovTipoConcentrados();
    this.getLovProfilaxisRealizada();
    this.getLovTipoProfilaxis();
    this.getLovTratamientos();
    this.getLovYesNo();
 

  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }
}
