import { Component, OnInit,ViewChild,Inject, Directive,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//services
import { MessageService } from '../../../../services/MessageService';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { PerfilService } from '../../../../services/mantenimiento/perfil.service'
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmPerfil} from '../../../../common/admtmperfil';

import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';

export interface genero {
  codigo: string;
  descripcion: string;
}
export interface estado {
  value: string;
  nombre: string;
}
export interface itemToDelete {
  id: string;
  nombresUsuario: string;
  apellidosUsuario: string;
}


@Component({
  selector: 'app-listar-usuarios',
  templateUrl: './listar-usuarios.component.html',
  styleUrls: ['./listar-usuarios.component.scss'],
  providers:[CentroService,UserService,PerfilService,MessageService],
  encapsulation: ViewEncapsulation.None
})

export class ListarUsuariosComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  usuarios: AdmTmUsuario[];
  centros: AdmTmCentro[];
  perfiles: AdmTmPerfil[];
  centroUsuarios: AdmTmCentroUsuario[];
  parametros:any;
  getData: string;

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  displayedColumns: string[] = ['CodigoMedico', 'NroDocumento', 'Apellidos','Nombres','Genero','Perfil','Estado','Acciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 
  constructor(private centroService : CentroService,
    private perfilService : PerfilService,
    private usuarioService : UserService,
    private router : Router,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dialog: MatDialog) { }
  
  validateDelete(item){
    this.openDialog(item);

  }
  openDialog(item): void {
    console.log(item);
    let aux:any;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "¿Seguro que desea eliminar a "+item.nombresUsuario+" "+item.apellidosUsuario+" ?"
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if(result){
     /* debugger
          
          if(item.estado =='Activo'){
                 
            item.estado = 1
          }
       console.log(item.estado,'estado');*/
       aux = item.idUsuario;
       /* let userData = {
          
          admTmUsuario : item
            };*/
        
         
        console.log(aux,'user data');
        this.usuarioService.deleteUser(aux)
        .subscribe(
          data => {
                    
                  console.log(data,'data obtenida');
                 this.getUsuarios();
               

                  if(data.estado==0)
                  { 
                      this.showSuccess('MODIFICACION DEL USUARIO EXITOSO');
                  }
                  if(data.estado!=0)
                  {                
                    this.showError('FALLO AL  MODIFICAR EL USUARIO')
                  }
                
          },
                  error => this.showError('Error inesperado '),
          );
                
      }
    });
  }

  

  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;            
            },
          error => this.showError('Error obtener centros')
        );
  }

  getPerfiles(){
    this.perfilService.getPerfiles()
        .subscribe(
          data=>{
            console.log(data);
            this.perfiles=data;            
            },
          error => this.showError('Error obtener centros')
        );
  }

  getUsuariosXCentro(){
    let aux= [];
    let currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    this.usuarioService.getUsersByCentro(currentCentro.idCentro)
        .subscribe(
          data=>{
            console.log(data);
            console.log(data);
           

            for (let i = 0; i < data.length; i++) {
              aux.push(data[i].admTmUsuario);
              if (data[i].admTmUsuario.estado ===1) {
                
                aux[i].estado ='Activo'; 
              }
              
            }

            this.dataSource=new MatTableDataSource(aux);
            this.dataSource.sort=this.sort;
            this.dataSource.paginator=this.paginator;
            console.log('dataSource length');
            console.log(this.dataSource);
            
            },
          error => this.showError('Error obtener usuarios')
        );
    }

  getUsuarios(){
    let aux: any;
    this.usuarioService.getUsers()
        .subscribe(
          data=>{
            console.log(data);
            console.log(data['content']);
            aux=data['content']
           

            for (let i = 0; i < data['content'].length; i++) {
              if (data['content'][i].estado ===1) {
                aux[i].estado ='Activo';
              }
              else if(data['content'][i].estado ===0){
                aux[i].estado = 'Desactivo';
              }
            }
             
            this.dataSource=new MatTableDataSource(aux);
            this.dataSource.sort=this.sort;
            this.dataSource.paginator=this.paginator;
            console.log('dataSource length');
            console.log(this.dataSource);
            
            },
          error => this.showError('Error obtener usuarios')
        );
    }

  clear() {
      this.items = [];
      this.params = {
        codigoUsuario:null,
        nombresUsuario:null,
        apellidosUsuario:null,
        admTrPerfilxusuarios:null,
        centro:null,
        numeroDocumento:null,
        genero:null,
        estado:null
      };
      this.parametros = [];
  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }

  goToCrear() {
    let link = ['/usuario/nuevo'];
    this.router.navigate(link);
  }

  goToEdit(item: AdmTmUsuario) {
    console.log(item)
        let link = ['/usuario/', item.idUsuario];
        console.log('usuario Editar: ' + link);
        this.router.navigate(link);
  }

  goToView(item: AdmTmUsuario) {
        let link = ['/usuario/ver/', item.idUsuario];
        console.log('usuario Ver: ' + link);
        this.router.navigate(link);
  }
  checkPermisos() {
      this.canSelect = true;
      this.canEdit = this.checkMenusPermitidos('/usuario/id');
      this.canCreate=this.checkMenusPermitidos('/usuario/nuevo');
      this.canDelete = this.canCreate;
  }

  checkMenusPermitidos(url) {
      let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
      let response = false;
      url = url.replace(/\d+/g, "id");
      for (let i = 0; i < menus.length; i++) {
          
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
      return response;
  }
  
  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

  validateSuperUser(){
    
    let user = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(user.admTrPerfilxusuarios[0].admTmPerfil);
    if(user.admTrPerfilxusuarios[0].admTmPerfil.isSuperAdmin==1){
      return true;
    }else{
      return false;
    }
  }

  ngOnInit() {
   
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarUsuarios' });
    this.clear();
    this.checkPermisos();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.getCentros();

    this.getPerfiles();
    if(this.validateSuperUser()){
      this.getUsuarios();
    }else{
      this.getUsuariosXCentro();
    }
    
    
  

  }
 
  reloadusuario(){

    console.log('data a buscar')
    console.log(this.params,'data de entrada');
    let aux:any;
    let paramsBuscar: any = {
      codigoUsuario: this.params.codigoUsuario,
      nombresUsuario: this.params.nombresUsuario,
      apellidosUsuario: this.params.apellidosUsuario,
      //admTrPerfilxusuarios:this.params.admTrPerfilxusuarios,
      numeroDocumento: this.params.numeroDocumento
      
     }
    if(this.params.admTrPerfilxusuarios){
      paramsBuscar.admTrPerfilxusuarios=this.params.admTrPerfilxusuarios.idPerfil;
    }  
    
    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centro = this.params.centro.idCentro;
    }
     if(this.params.genero){
       paramsBuscar.genero = this.params.genero.codigo;
     }

    
    if (this.params.estado){
      paramsBuscar.estado = this.params.estado.value;
    }
     this.usuarioService.getRegistrosFiltrado(paramsBuscar).subscribe( data => {
      if(!data){
        return;
      }
      aux=data.content
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].estado ===1) {
          aux[i].estado ='Activo';
        }else if(aux[i].estado ===0){
          aux[i].estado ='Desactivo';
      }  }
       console.log(data,'data obtenida')
       console.log(aux,'aux obtenida')
       //this.data = data.content;
      // this.dataSource=new MatTableDataSource(); 
      this.dataSource=new MatTableDataSource(aux); 
     //this.data = data.content;
     
      this.dataSource.sort=this.sort;
      this.dataSource.paginator=this.paginator; 
   },
   error => this.showError('Error inesperado')
 );
  }


}


@Component({
  selector: 'modal-usuarios.component',
  templateUrl: './modal-usuarios.component.html'
})
//@Directive({})
export class DeleteModal {
  item: any;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA){item}:any) {
      this.item=item;
    }

  eliminar(): void {
    this.dialogRef.close(this.item);
  }
  close() {
    this.dialogRef.close();
  }

}
