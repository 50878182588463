import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';

import { ListarModuloComponent } from './components/admin/modulo/listar-modulo/listar-modulo.component';
import { ListarMenusComponent } from './components/admin/menu/listar-menu/listar-menu.component';

import { RegistrarPerfilComponent } from './components/mantenimiento/perfiles/registrar-perfil/registrar-perfil.component';
import { EditarPerfilComponent } from './components/mantenimiento/perfiles/editar-perfil/editar-perfil.component';
import { VerPerfilComponent } from './components/mantenimiento/perfiles/ver-perfil/ver-perfil.component';
import { ListarPerfilesComponent } from './components/mantenimiento/perfiles/listar-perfiles/listar-perfiles.component';

import { CambiarContrasenaComponent } from './components/admin/usuarios/cambiar-contrasena/cambiar-contrasena.component';
import { ListarUsuariosComponent } from './components/mantenimiento/usuarios/listar-usuarios/listar-usuarios.component';
import { RegistrarUsuarioComponent } from './components/mantenimiento/usuarios/registrar-usuario/registrar-usuario.component';
import { EditarUsuarioComponent } from './components/mantenimiento/usuarios/editar-usuario/editar-usuario.component';
import { VerUsuarioComponent } from './components/mantenimiento/usuarios/ver-usuario/ver-usuario.component';

import { RegistrarCentroComponent } from './components/mantenimiento/centros/registrar-centro/registrar-centro.component';
import { EditarCentroComponent } from './components/mantenimiento/centros/editar-centro/editar-centro.component';
import { VerCentroComponent } from './components/mantenimiento/centros/ver-centro/ver-centro.component';
import { ListarCentrosComponent } from './components/mantenimiento/centros/listar-centros/listar-centros.component';

import { RegistrarMedicamentoComponent } from './components/mantenimiento/medicamentos/registrar-medicamento/registrar-medicamento.component';
import { EditarMedicamentoComponent } from './components/mantenimiento/medicamentos/editar-medicamento/editar-medicamento.component';
import { VerMedicamentoComponent } from './components/mantenimiento/medicamentos/ver-medicamento/ver-medicamento.component';
import { ListarMedicamentosComponent } from './components/mantenimiento/medicamentos/listar-medicamentos/listar-medicamentos.component';

import { RegistrarPacienteComponent } from './components/mantenimiento/pacientes/registrar-paciente/registrar-paciente.component';
import { ListarPacientesComponent } from './components/mantenimiento/pacientes/listar-pacientes/listar-pacientes.component';

import { ListarRegistrosComponent } from './components/hemofilia/registros/listar-registros/listar-registros.component';
import { CargarRegistrosExcelComponent } from './components/hemofilia/registros/cargar-registros-excel/cargar-registros-excel.component';

import { SolicitudTransferenciaPacienteComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente/solicitud-transferencia-paciente.component';
import { SolicitudTransferenciaPacienteVerComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente-ver/solicitud-transferencia-paciente-ver.component';
import { SolicitudTransferenciaPacienteAprobacionComponent } from './components/hemofilia/solicitud/solicitud-transferencia-paciente-aprobacion/solicitud-transferencia-paciente-aprobacion.component';

import { ListarSolicitudesTransferenciaComponent } from './components/hemofilia/solicitud/listar-solicitudes-transferencia/listar-solicitudes-transferencia.component';


import { RegistroDemografiaComponent } from './components/hemofilia/registros/registro-demografia/registro-demografia.component';
import { RegistroAntecedentesComponent } from './components/hemofilia/registros/registro-antecedentes/registro-antecedentes.component';
import { RegistroDiagnosticoComponent } from './components/hemofilia/registros/registro-diagnostico/registro-diagnostico.component';
import { RegistroEventosHemorragicoComponent } from './components/hemofilia/registros/registro-eventos-hemorragico/registro-eventos-hemorragico.component';
import { RegistroComorbilidadesComponent } from './components/hemofilia/registros/registro-comorbilidades/registro-comorbilidades.component';
import { RegistroInhibidoresComponent } from './components/hemofilia/registros/registro-inhibidores/registro-inhibidores.component';
import { RegistroTratamientoComponent } from './components/hemofilia/registros/registro-tratamiento/registro-tratamiento.component';
import { RegistroAnteFamiliaresComponent } from './components/hemofilia/registros/registro-antecedente-familiares/registro-antecedente-familiares.component';
import { RegistroObservacionesComponent } from './components/hemofilia/registros/registro-observaciones/registro-observaciones.component';

import { ListarEvolucionesComponent } from './components/hemofilia/evoluciones/listar-evoluciones/listar-evoluciones.component';
import { RegistroEvolucionesComponent } from './components/hemofilia/evoluciones/registro-evoluciones/registro-evoluciones.component';
import { EditarEvolucionesComponent } from './components/hemofilia/evoluciones/editar-evoluciones/editar-evoluciones.component';
import { VerEvolucionesComponent} from './components/hemofilia/evoluciones/ver_evoluciones/ver_evoluciones.component';

//Añadidos por Jeancarlo Aleman 14-04-21
import{ EvolucionesDemografiaComponent } from './components/hemofilia/evoluciones/evoluciones-demografia/evoluciones-demografia.component';
import{ EvolucionesTratamientoComponent } from './components/hemofilia/evoluciones/evoluciones-tratamiento/evoluciones-tratamiento.component';
import{ EvolucionesTabComponent } from './components/hemofilia/evoluciones/evoluciones-tab/evoluciones-tab.component';
import {EvolucionesInhibidoresComponent} from './components/hemofilia/evoluciones/evoluciones-inhibidores/evoluciones-inhibidores.component';
import {EvolucionesEventosHemorragicoComponent} from './components/hemofilia/evoluciones/evoluciones-eventos-hemorragico/evoluciones-eventos-hemorragico.component';
import { EvolucionesComorbilidadesComponent } from './components/hemofilia/evoluciones/evoluciones-comorbilidades/evoluciones-comorbilidades.component';
import { EvolucionesObservacionesComponent } from './components/hemofilia/evoluciones/evoluciones-observaciones/evoluciones-observaciones.component';
//Hasta aca.
//Jie 28/10/2022
import { ReporteListarComponent } from './components/hemofilia/reportes/reporte-listar.component';


import { TabComponent } from './components/hemofilia/registros/registro-tab/tab.component';

import { AuthGuard } from './guards/auth.guards';
import { MenuGuard } from './guards/menu.guards';

const routes: Routes = [
  { path: 'tab', component: TabComponent }, 
  //Otro agregado Jeancarlo A. 14-04-21 
  {path: 'evotab', component: EvolucionesTabComponent},
  //
  { path: 'login', component: LoginComponent , pathMatch: 'full'},
  { path: '', component: HomeComponent,canActivate: [AuthGuard]},
  { path: 'modulo', component: ListarModuloComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'menu', component: ListarMenusComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'perfil/nuevo', component: RegistrarPerfilComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'perfil', component: ListarPerfilesComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'perfil/:id', component: EditarPerfilComponent ,canActivate: [AuthGuard, MenuGuard] }, 
  { path: 'perfil/ver/:id', component: VerPerfilComponent ,canActivate: [AuthGuard, MenuGuard] }, 
  { path: 'usuario/nuevo', component: RegistrarUsuarioComponent,canActivate: [AuthGuard, MenuGuard]  },
  { path: 'usuario/:id', component: EditarUsuarioComponent ,canActivate: [AuthGuard, MenuGuard] }, 
  { path: 'usuario/ver/:id', component: VerUsuarioComponent ,canActivate: [AuthGuard, MenuGuard] },

  { path: 'cambiarContrasena', component: CambiarContrasenaComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'usuario', component: ListarUsuariosComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'centro/nuevo', component: RegistrarCentroComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'centro/:id', component: EditarCentroComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'centro/ver/:id', component: VerCentroComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'centro', component: ListarCentrosComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'medicamento/nuevo', component: RegistrarMedicamentoComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'medicamento/:id', component: EditarMedicamentoComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'medicamento/ver/:id', component: VerMedicamentoComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'medicamento', component: ListarMedicamentosComponent ,canActivate: [AuthGuard, MenuGuard] },

  { path: 'paciente/nuevo', component: RegistrarPacienteComponent ,canActivate: [AuthGuard, MenuGuard] }, //
  { path: 'paciente', component: ListarPacientesComponent ,canActivate: [AuthGuard, MenuGuard] }, //
  { path: 'registro', component: ListarRegistrosComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'cargarRegistrosExcel', component: CargarRegistrosExcelComponent ,canActivate: [AuthGuard, MenuGuard] },

  { path: 'solicitud/nuevo/:id', component: SolicitudTransferenciaPacienteComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'solicitud/aprobacion/:id', component: SolicitudTransferenciaPacienteAprobacionComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'solicitud', component: ListarSolicitudesTransferenciaComponent ,canActivate: [AuthGuard, MenuGuard] }, //
  { path: 'solicitud/:id', component: SolicitudTransferenciaPacienteVerComponent ,canActivate: [AuthGuard, MenuGuard] },


  { path: 'registro/nuevo', component: RegistroDemografiaComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registro/:id', component: TabComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroAntecedentes', component: RegistroAntecedentesComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroDiagnostico', component: RegistroDiagnosticoComponent,canActivate: [AuthGuard, MenuGuard]  },
  { path: 'registroEventosHemorragico', component: RegistroEventosHemorragicoComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroComorbilidades', component: RegistroComorbilidadesComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroInhibidores', component: RegistroInhibidoresComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroTratamiento', component: RegistroTratamientoComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroAnteFamiliares', component: RegistroAnteFamiliaresComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'registroObservaciones', component: RegistroObservacionesComponent ,canActivate: [AuthGuard, MenuGuard] },

  { path: 'evolucion', component: ListarEvolucionesComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'evolucion/nuevo/:id', component: RegistroEvolucionesComponent ,canActivate: [AuthGuard, MenuGuard] },
  { path: 'evolucion/editar/:id', component: EditarEvolucionesComponent,canActivate: [AuthGuard, MenuGuard] },
  { path: 'evolucion/ver/:id', component: VerEvolucionesComponent ,canActivate: [AuthGuard, MenuGuard] },
  //Agregado de Jeancarlo A.
  { path: 'evolucion/nuevo', component: EvolucionesDemografiaComponent, canActivate: [AuthGuard, MenuGuard] },
  { path: 'evolucion/:id' , component: EvolucionesTabComponent, canActivate: [AuthGuard, MenuGuard]},
  { path: 'evolucionTratamiento', component: EvolucionesTratamientoComponent, canActivate: [AuthGuard, MenuGuard]},
  { path: 'evolucionInhibidores', component: EvolucionesInhibidoresComponent, canActivate: [AuthGuard, MenuGuard]},
  { path: 'evolucionEventosHemorragico', component: EvolucionesEventosHemorragicoComponent, canActivate: [AuthGuard, MenuGuard]},
  { path: 'evolucionComorbilidades', component: EvolucionesComorbilidadesComponent, canActivate: [AuthGuard, MenuGuard]},
  { path: 'evolucionObservaciones', component: EvolucionesObservacionesComponent, canActivate: [AuthGuard, MenuGuard]},
  //{ path: 'evolucion/editar/:id', component: EditarEvolucionesComponent ,canActivate: [AuthGuard, MenuGuard] }
  //Jie 28/10/2022
  { path: 'reportes', component: ReporteListarComponent ,canActivate: [AuthGuard, MenuGuard] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
