import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild,ViewEncapsulation} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { LovsService } from '../../../../services/admin/lovs.service';
import { Router } from '@angular/router';


import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { DataService } from '../../../../services/DataService';
import { Util } from '../../../../../util';
import {RegistroEvolucionService} from '../../../../services/hemofilia/registroevolucion.service';

import * as $ from 'jquery';


@Component({
  selector: 'app-evoluciones-observaciones',
  templateUrl: './evoluciones-observaciones.component.html',
  styleUrls: ['./evoluciones-observaciones.component.scss'],
  providers:[RegistroService,DataService,RegistroEvolucionService],
  encapsulation: ViewEncapsulation.None
})
export class EvolucionesObservacionesComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  idLovYesNoAnteFs: any = null;
  dataPacienteRegistro: any = null;
  observaciones: any = null;
  archivos: any = [];
  idRegistro: any = null;
  disableButtons: boolean = false;
  progress: any = 0;
  //Jeancarlo A. 23-04-21
  idRegEvolucion: any =null;
  idPaciente: any = null;
  dataPacRegistro: any = null;
  //

  @ViewChild('archivoInputEvolucion', {static: true}) archivoInputEvolucion: ElementRef;

  constructor(
    private toastrService: ToastrService,
    private lovsService: LovsService,
    private router : Router,
    private registroService: RegistroService,
    private registroEvolucionService: RegistroEvolucionService,
    public dialog: MatDialog
  ) { }
  
  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.observaciones = data.observacion;
    this.disableButtons = data.disableEdit;
    //this.idRegistro = data.idRegistro;
    this.idRegEvolucion = data.idRegEvolucion;
    this.idPaciente=data.admTmPaciente.idPaciente;

    //if (data.idRegistroEstado > 1){ // 1: En proceso
    //  this.disableButtons = true;
    //}

    this.obtenerDataRegistro();
  }

  obtenerDataRegistro(){
    this.registroService.getRegistroByPaciente(this.idPaciente).subscribe(
      data =>{
        this.dataPacRegistro = data;
        this.obtenerArchivos();
      },
      error => this.showError('Error al obtener registros')
    );
  }

  obtenerArchivos(){
    //this.registroService.getRegistroArchivos(this.idRegistro).subscribe(
    this.registroService.getRegistroArchivos(this.dataPacRegistro.idRegistro).subscribe(
      data=>{
        console.log(data);
        if (data){
          for (var i = 0; i < data.length; i++){
            let archivo = data[i];
            this.archivos.push({
              idArchivo: archivo.idArchivo,
              idRegistro:archivo.idRegistro,
              idRegEvolucion:archivo.idRegEvolucion, //Jeancarlo A. 18/03/21
              alias: archivo.alias,
              urlArchivo: Util.IP_API_FILES + archivo.idArchivo
            })
          }
          this.archivos = [...this.archivos]
        }
      },
      error => this.showError('Error obtener yes no')
    );
  }
  
  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(
      data=>{
        console.log(data);
        this.idLovYesNoAnteFs=data;         
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getRegistro(id){
    this.registroEvolucionService.getEvolucionesById(id).subscribe(
      data=>{
        console.log(data);
      },
      error => this.showError('Error obtener yes no')
    );
  }

  descargarArchivo(rowIndex){
    let archivo = this.archivos[rowIndex];
    var anchor = document.createElement('a');
    anchor.href = archivo.urlArchivo;
    anchor.target = '_blank';
    anchor.download = archivo.alias;
    anchor.click();
  }

  eliminarArchivo(rowIndex){
    let archivo = this.archivos[rowIndex];
    this.disableButtons = true;
    
    this.registroEvolucionService.eliminarRegistroArchivo(archivo.idArchivo).subscribe(
      data => {
        if (data['codigoValidacion'] == 1){
          this.showWarning(data['mensajeValidacion']);
        }else if (data['codigoValidacion'] == 2){
          this.showSuccess('Archivo eliminado.')
          this.archivos.splice(rowIndex, 1);
          this.archivos = [...this.archivos]
        }else if (data['codigoValidacion'] == 3){
          this.showError(data['mensajeValidacion']);
        }
        this.disableButtons = false;
      },
      error => {
        this.showError('Error inesperado');
        this.disableButtons = false;
      }
    );
  }

  uploadFile(evt: any){

    var archivo: File = evt.target.files.item(0);
    const MAXIMO_TAMANIO_BYTES:number = 10000000;
    if(archivo.size > MAXIMO_TAMANIO_BYTES) {
      this.showError('El tamaño máximo es de 10 MB');
      $("archivoInputEvolucion").value='';
    }else{
    if (archivo){
      this.disableButtons = true;
      let dataEnviar = {
        //idRegistro: this.idRegistro,
        idRegEvolucion: this.idRegEvolucion,
        idRegistro: this.dataPacRegistro.idRegistro,
        nombreArchivo: archivo.name
      }
      this.registroEvolucionService.updateRegistroArchivos(dataEnviar, archivo).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
            console.log(this.progress);
          } else if (event instanceof HttpResponse) {
            let data = event.body;
            if (data['codigoValidacion'] == 1){
              this.showWarning(data['mensajeValidacion']);
            }else if (data['codigoValidacion'] == 2){
    
              this.showSuccess('Archivo guardado.')
              this.agregarFilaArchivos(dataEnviar, data);
    
    
            }else if (data['codigoValidacion'] == 3){
              this.showError(data['mensajeValidacion']);
            }
            this.disableButtons = false;
            
            // Se limpia el input
            this.archivoInputEvolucion.nativeElement.value = "";
          }
        },
        error => {
          this.showError('Error inesperado');
          this.disableButtons = false;
          this.archivoInputEvolucion.nativeElement.value = "";
        }
      );
    }
  }
  }

  agregarFilaArchivos(dataEnviada, dataRecibida){
    this.archivos.push({
      idArchivo: dataRecibida.id,
      urlArchivo: Util.IP_API_FILES + dataRecibida.id,
      alias: dataEnviada.nombreArchivo
    });
    this.archivos = [...this.archivos]
  }

  clearEditarRegistro(){
    this.observaciones = null;
  }

  createRegistroObservaciones(){
    this.progress = 0;
    this.disableButtons = true;

    let dataEnviar = {
      idRegEvolucion: this.idRegEvolucion,
      //idRegistro: this.idRegistro,
      observaciones: this.observaciones,
    }

    this.registroEvolucionService.updateObservacionesRegistro(dataEnviar).subscribe(data => {
        if (data['codigoValidacion'] == 1){
          this.showWarning(data['mensajeValidacion']);
        }else if (data['codigoValidacion'] == 2){
          this.showSuccess('Observaciones guardadas.')
        }else if (data['codigoValidacion'] == 3){
          this.showError(data['mensajeValidacion']);
        }
        this.disableButtons = false;
      },
      error => {
        this.showError('Error inesperado');
        this.disableButtons = false;
      }
    );
  }

  finalizar(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Una vez finalizado, no podrá editar este registro. ¿Desea finalizar el registro?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        this.registroEvolucionService.finalizarRegistro(this.idRegEvolucion).subscribe(
          data => {
            if (data['codigoValidacion'] == 1){
              this.showWarning(data['mensajeValidacion']);
            }else if (data['codigoValidacion'] == 2){
              this.showSuccess('Registro finalizado.')
              this.goToRegistro();
            }else if (data['codigoValidacion'] == 3){
              this.showError(data['mensajeValidacion']);
            }
            this.disableButtons = false;
          },
          error => {
            this.showError('Error inesperado');
            this.disableButtons = false;
          }
        );
      }
    });
  }

  ngOnInit() {
    this.clearEditarRegistro();
    console.log('dentro registro observaciones');
    this.getLovYesNo();
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
