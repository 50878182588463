import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services/admin/authentication.service';
import { Util } from '../../../util';
import { SocketService } from '../../services/emitter/socketservice';
import { Subscription } from 'rxjs';
import { AppComponent } from '../../components/app/app.component';
import { ElegirCentroComponent } from '../hemofilia/registros/elegir-centro/elegir-centro.component';

import { MatDialog, MatDialogConfig } from '@angular/material';

import {UserService } from '../../services/admin/user.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthenticationService,UserService],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
    model: any = {};
    error = '';
    busyLogin: Subscription;
    busyUser: Subscription;
    response: string;
    subscription: any;
    datas :any [];

    constructor(
        private router: Router,
        private toastrService: ToastrService,
        public app: AppComponent,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService
       ) {
    }
    login() {
        var f = document.getElementsByTagName('form')[0];
        if(f.reportValidity()) {
            console.log(this.model);
            this.authenticationService.login(this.model.username, this.model.password).subscribe(data => {
                console.log(data,'DATA DE LOGIN');
                this.datas=data;
                if (data && data.token) {
                    sessionStorage.setItem('userToken', data.token);
                    this.authenticationService.showUserInformation().subscribe(response => {                        
                        console.log(response);
                        if (response['codigoValidacion'] == 1)
                            this.showWarning(response['mensajeValidacion']);
                        if (response['codigoValidacion'] == 2)
                            this.ingresarSistema(response);
                            console.log('ingresarSistema');
                            console.log(response['usuario']);
                        if (response['codigoValidacion'] == 3)
                            this.showError(response['mensajeValidacion']);
                    },
                        error => this.showError('Error inesperado.')
                    );
                } else {
                    this.showWarning('El usuario está inactivo. Favor comuníquese con el Administrador de su empresa.');
                    this.model.password = "";
                }
            });
        }
    }

    ingresarSistema(data) {
        let dataUsuario = data['usuario'];
        let dataCentros = data['centros'];
        let usuxperfil :any;
        
        console.log(data['centros'],'centros obtenidos');
        console.log(data['usuario']);
        
        sessionStorage.setItem('currentUser', JSON.stringify(dataUsuario));
        sessionStorage.setItem('centros', JSON.stringify(dataCentros));
        if (dataCentros && dataCentros.length > 1){
            for( var i =0;i<dataUsuario.admTrPerfilxusuarios.length;i++){
                   usuxperfil = dataUsuario.admTrPerfilxusuarios[i].idPerfilxUsuario;
                console.log(usuxperfil,'perfil dentro');
                if(usuxperfil ==1){
                    sessionStorage.setItem('currentCentro', JSON.stringify(dataCentros[1]));
                    this.app.islogin();
                    this.router.navigate(['/']);
                }
                else{
                    this.elegirCentroActual(dataCentros);
             }
                }
            console.log(dataUsuario.admTrPerfilxusuarios,'entra aca');
             
        }else if (dataCentros && dataCentros.length == 1 ){
            sessionStorage.setItem('currentCentro', JSON.stringify(dataCentros[0]));
            this.app.islogin();
            this.router.navigate(['/']);
        }else{
            // No tiene un centro asignado
            this.app.islogin();
            this.router.navigate(['/']);
        }
    }
    
    elegirCentroActual(dataCentros){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        console.log(this.datas,'data obtenida al logear se con el ususario unico');
        dialogConfig.data = {
            centros: dataCentros
        };
        console.log()
        const dialogRef = this.dialog.open(ElegirCentroComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            centro => {
                // No se almacena todo el objeto por tamaño
                let centroValores = {
                    idCentro: centro.idCentro,
                    codigoCentro: centro.codigoCentro,
                    direccionCentro: centro.direccionCentro,
                    nombreCentro: centro.nombreCentro,
                    numContacto: centro.numContacto,
                    rucCentro: centro.rucCentro,
                    personaContacto: centro.personaContacto,
                    correoContacto: centro.correoContacto
                }
                sessionStorage.setItem('currentCentro', JSON.stringify(centroValores));
                this.app.islogin();
                this.router.navigate(['/']);

            }
        );
    }


    showSuccess(mensaje) {
        this.toastrService.success(mensaje, '¡Éxito!');
    }

    showInfo(mensaje) {
        this.toastrService.info(mensaje, '¡Información!');
    }

    showWarning(mensaje) {
        this.toastrService.warning(mensaje, '¡Advertencia!');
    }

    showError(mensaje) {
        this.toastrService.error(mensaje, '¡Error!');
    }


    ngOnInit() {
        // reset login status
        this.authenticationService.logout();

        document.getElementById('wrapper').classList.add("login-bg");
        document.getElementsByTagName('footer')[0].classList.remove("footer-app");
        document.getElementsByTagName('footer')[0].classList.add("footer-login");
        document.getElementById('footer-logo').classList.remove("footer-logo-app");
        document.getElementById('footer-logo').classList.add("footer-logo-login");
    }

    ngOnDestroy() {
        document.getElementById('wrapper').classList.remove("login-bg");
        document.getElementsByTagName('footer')[0].classList.remove("footer-login");
        document.getElementsByTagName('footer')[0].classList.add("footer-app");
        document.getElementById('footer-logo').classList.remove("footer-logo-login");
        document.getElementById('footer-logo').classList.add("footer-logo-app");
    }

}
