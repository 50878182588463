import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';


import { LovsService } from '../../../../services/admin/lovs.service';

import { RegistroinhibidoresService } from '../../../../services/hemofilia/registroinhibidores.service';
import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { RegistroEvolucionService } from '../../../../services/hemofilia/registroevolucion.service';
import { MessageService } from '../../../../services/MessageService';

@Component({
  selector: 'app-editar-evoluciones',
  templateUrl: './editar-evoluciones.component.html',
  styleUrls: ['./editar-evoluciones.component.scss'],
  providers:[RegistroinhibidoresService,MessageService,PacienteService,RegistroEvolucionService],
  encapsulation: ViewEncapsulation.None
})
export class EditarEvolucionesComponent implements OnInit {
  disableCreate = false;
  updatePacienteEvolucion:any= {};
  dosajeTratamientos: any=null;
  tratamientos: any=null;
  busyId =false;
  busyCreate: Subscription;
  paciente: any=null;
  tratamientosDosajeInhibidros: any = [];
  tratamientosDosajeInhibidrosOld: any = [];

  constructor(
    private toastrService: ToastrService,
    private registroInhibidoresService: RegistroinhibidoresService,
    private lovsService: LovsService,
    private pacienteService:PacienteService,
    private router : Router,
    private route: ActivatedRoute,
    private registroEvolucion:RegistroEvolucionService
    ) { }
  
  getEvolucion(id){
    this.registroEvolucion.getEvolucionesById(id).subscribe(
        data=>{
          console.log(data);
          this.updatePacienteEvolucion.idRegEvolucion=data.idRegEvolucion;
          this.updatePacienteEvolucion.fecha=data.fecha;
          this.updatePacienteEvolucion.admTmLovTratamientoRecibe=data.admTmLovTratamientoRecibe;
          this.updatePacienteEvolucion.dosis=data.dosis;
          this.updatePacienteEvolucion.tiempo=data.tiempo;
          this.updatePacienteEvolucion.observaciones=data.observaciones;
          this.updatePacienteEvolucion.admTmPaciente=data.admTmPaciente;
          this.updatePacienteEvolucion.codigoPaciente=data.admTmPaciente.codigoPaciente;
          this.updatePacienteEvolucion.pacienteApellidosNombre=data.admTmPaciente.apellidoPaterno+' '+data.admTmPaciente.apellidoMaterno+' '+data.admTmPaciente.nombresPaciente; 
          
          this.getPacienteUsuario(data.admTmPaciente);
          console.log(this.tratamientos,'TRATAMEINTO PACEINTE');
          console.log(data.admTmPaciente,'DOCUMENTO');
          for (let i = 0; i < this.tratamientos.length; i++) {
            if (this.tratamientos[i].idTratamientoRecibe === this.updatePacienteEvolucion.admTmLovTratamientoRecibe.idTratamientoRecibe) {
              this.updatePacienteEvolucion.admTmLovTratamientoRecibe = this.tratamientos[i];
              break;
            }
          }
        },
        error => this.showError('Error obtener paciente')

    );
  }


  goToPaciente(){
    let link = ['/evolucion'];
    this.router.navigate(link);
  }


  agregarFilaTratamiento (){
    this.tratamientosDosajeInhibidros.push({
      titulo: '',
      fecha: '',
      tratamiento: '',
      dosis: ''
    });
    this.tratamientosDosajeInhibidros = [...this.tratamientosDosajeInhibidros]
  }

  eliminarFilaTratamiento(value){
    console.log('eliminarFila');
    console.log(value);
  }

  getPacienteUsuario(paciente){
    this.pacienteService.getPacienteUsuario(paciente)
        .subscribe(
          data=>{
            console.log("getPacienteUsuario");
            console.log(data);
            this.updatePacienteEvolucion.pacienteUsuario=data;
            this.busyId=true;
                      
            },
          error => this.showError('Error obtener paciente usuario')
        );
  }

  updateEvolucionInhibidores(){

    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      console.log("updateEvolucionInhibidores");
      console.log(this.updatePacienteEvolucion);
      console.log(this.tratamientosDosajeInhibidros);
      let centro = JSON.parse(sessionStorage.getItem('currentCentro'));
      this.updatePacienteEvolucion.admTmCentro=centro;
      let data={
        evolucion:this.updatePacienteEvolucion,
        hemTrRegistroEvolucionTrata:this.tratamientosDosajeInhibidros,
        hemTrRegistroEvolucionTrataOld:this.tratamientosDosajeInhibidrosOld

      }
                          
          this.busyCreate = this.registroEvolucion.updateEvolucion(data).subscribe(
            data => {
              if(data['codigoValidacion']==1)
                this.showWarning(data['mensajeValidacion']);
              if(data['codigoValidacion']==2)
                this.showSuccess(data['mensajeValidacion']);

              if(data['codigoValidacion']==3)
                this.showError(data['mensajeValidacion']);                    
            },
            error => this.showError('Error inesperado'),
            () => this.clearNewEvolucion()
            );  

    }


  }

  clearNewEvolucion() {

  }

  loadTratamientos() {

    this.lovsService.getAdmTmLovTratamientoRecibeActivas()
    .subscribe(response => {
      this.tratamientos = response;
    });

    this.lovsService.getAdmTmLovTratamientoActivas()
    .subscribe(response => {
      this.dosajeTratamientos = response;
    })
  }

  ngOnInit() {
    this.loadTratamientos();
    let itemid= this.route.snapshot.params['id'];
    this.getEvolucion(itemid);

 
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }


}
