import { Component, OnInit ,ViewChild, ViewEncapsulation} from '@angular/core';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../../services/MessageService';
import { ModuloService } from '../../../../services/admin/modulo.service';


import {AdmTmModulo} from '../../../../common/admtmmodulo';


export interface estado {
  value: string;
  nombre: string;
}



@Component({
  selector: 'app-listar-modulo',
  templateUrl: './listar-modulo.component.html',
  styleUrls: ['./listar-modulo.component.scss'],
  providers:[ModuloService,MessageService],
  encapsulation: ViewEncapsulation.None
})

export class ListarModuloComponent implements OnInit {
  items: AdmTmModulo[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEditar = false;
  canCrear = false;
  parametros:any;

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];

  displayedColumns: string[] = ['Codigo',  'Nombre', 'Descripcion'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private moduloService : ModuloService,
              private router : Router,
              private toastrService: ToastrService,
              private messageService: MessageService
    ) { }

    loadModulos() {
      this.moduloService.getModulos()
        .subscribe(response => {
          this.items = response;
   
          this.dataSource=new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log('loadModulos');
          console.log(this.items);
        })
    }

    clear() {
        this.items = [];

    }

    back(){
      let link = ['/'];
      this.router.navigate(link);
    }




    checkMenusPermitidos(url) {
        let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
        let response = false;
        for (let i = 0; i < menus.length; i++) {
            url = url.replace(/\d+/g, "id");
            if (url == '/' + menus[i].ruta) {
                response = true;
                break;
            }
        }
        return response;
    }

    
    

    ngOnInit() {
      this.messageService.sendMessage({ type: 'ruta', modulo: 'Admin', menu: 'ListarModulos' });
      this.clear();
      this.loadModulos();
    }


    showSuccess(mensaje) {
      this.toastrService.success(mensaje ,'¡Éxito!' );
    }
      
    showInfo(mensaje) {
      this.toastrService.info(mensaje ,'¡Información!' );
    }
      
    showWarning(mensaje) {
      this.toastrService.warning(mensaje ,'¡Advertencia!' );
    }

    showError(mensaje) {
      this.toastrService.error(mensaje , '¡Error!' );
    } 

}
