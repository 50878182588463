import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {HemTrSolicitudTrans} from '../../../../common/hemtrsolicitudtrans';
//services
import { MessageService } from '../../../../services/MessageService';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { SolicitudService } from '../../../../services/hemofilia/solicitud.service';


import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';



export interface estado {
  value: string;
  nombre: string;
}
@Component({
  selector: 'app-listar-solicitudes-transferencia',
  templateUrl: './listar-solicitudes-transferencia.component.html',
  styleUrls: ['./listar-solicitudes-transferencia.component.scss'],
  providers:[CentroService,UserService,SolicitudService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ListarSolicitudesTransferenciaComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEditar = false;
  canCrear = false;
  usuarios: AdmTmUsuario[];
  centros: AdmTmCentro[];
  solicitud: HemTrSolicitudTrans[];
  centroUsuarios: AdmTmCentroUsuario[];
  parametros:any;
  getData: string;
  currentCentro: any;
  user:any;

  estados: estado[] = [
    {value: '8', nombre: 'Anulado'},
    {value: '5', nombre: 'Pendiente'},
    {value: '6', nombre: 'Aprobado'},
    {value: '7', nombre: 'Rechazado'}
  ];

  displayedColumns: string[] = ['numSolicitud', 'fechaCreacion', 'admTmUsuarioOrigen','admTmUsuarioDestino','admTmCentroOrigen','admTmCentroDestino','admTmPaciente','estado','Acciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  constructor(private centroService : CentroService,
    private solicitudService : SolicitudService,
    private usuarioService : UserService,
    private router : Router,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dialog: MatDialog) { }
  
   
    validarAnular(item){
      this.openDialog(item);
  
    }
    openDialog(item): void {
      console.log(item);
      let aux:any;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "¿Seguro que desea eliminar la solicitud "+item.numSolicitud+"?"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if(result){
          
          aux = item.idSolicitud;
          console.log('id solicitud',aux);
          this.solicitudService.deleteUser(aux)
          .subscribe(
            data => {
                    
              console.log(data,'data obtenida');
              //this.getSolicitudes();
              this.reloadSolicitud();
              
              if(data.estado==8)
                  { 
                      this.showSuccess('MODIFICACION DEL USUARIO EXITOSO');
                  }
                  if(data.estado!=8)
                  {                
                    this.showError('FALLO AL  MODIFICAR EL USUARIO')
                  }
            },
                    error => this.showError('Error inesperado '),
            );
  
        }
      });
    }

  getSolicitudes(){
    let aux:any;
    this.solicitudService.getSolicitudes()
        .subscribe(
          data=>{
              console.log(data,'data antes de entrar');
              //this.dataSource=data;  
              aux=data
              for (let i = 0; i < data.length; i++) {
                if (data[i].estado ===8) {
                  aux[i].estado ='Anulado';
                }else if(data[i].estado ===5){
                  aux[i].estado ='Pendiente';
                }else if(data[i].estado ===6){
                  aux[i].estado ='Aprobado';
                }else if(data[i].estado ===7){
                  aux[i].estado ='Rechazado';
                }
              }  
              console.log(aux,'data despues de entrar aux');
              this.dataSource=new MatTableDataSource(aux);  
              this.dataSource.sort=this.sort;
              this.dataSource.paginator=this.paginator;    
            },
          error => this.showError('Error obtener solicitudes')
        );
  }
   
  getCurrentUsuario(){
    let perfil:any[];
    let per :any;
    let pert :any[] ;
    
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));

   perfil = this.user.admTrPerfilxusuarios;

    for(var i=0 ;i<perfil.length;i++){

      per =perfil[i].admTmPerfil.idPerfil; 
      console.log(per ,'ID DE PERFIl ') ;
    };
    
      if(per == 2 ){

        this.user.idUsuario = null;
      }
      else if (per ==1){

        this.user.idUsuario =0;
      }
     else {
       this.user.idUsuario = this.user.idUsuario;
     }
     console.log(this.user.idUsuario,'ID USUARIO SEGUN PERFIL');
  }


  back(){
    let link = ['/'];
    this.router.navigate(link);
  }

  clear() {
      this.items = [];
      this.params = {
        nroSolicitud:null,
        DNIPaciente:null,
        medico:null,
        centro: null,
        estado:null
      };
      this.parametros = [];
      console.log(this.params.centro,'este es el Centro medico');
  }

  Limpiar(){
    this.params.nroSolicitud = null;
    this.params.DNIPaciente = null;
    this.params.medico = null;
    this.params.estado = null;
  }

  reloadSolicitud(){
  
    console.log('data a buscar')
    console.log(this.params,'data de entrada');
    let aux:any;
    let paramsBuscar: any = {
     norSolicitud: this.params.nroSolicitud,
     DNIPaciente: this.params.DNIPaciente ,
     medico: this.params.medico,
     idUsuario : this.user.idUsuario,
      //nombresPaciente: this.params.centro
      //estado: this.params.estado.value
    }
    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centro = this.params.centro.idCentro;
    }
    
    if (this.params.estado){
      paramsBuscar.estado = this.params.estado.value;
    }

    this.solicitudService.getRegistrosFiltrado(paramsBuscar).subscribe( data => {
       if(!data){
        return;
     } 
     aux=data.content
     for (let i = 0; i < aux.length; i++) {
       if (aux[i].estado ===8) {
         aux[i].estado ='Anulado';
       }else if(aux[i].estado ===5){
         aux[i].estado ='Pendiente';
       }else if(aux[i].estado ===6){
         aux[i].estado ='Aprobado';
       }else if(aux[i].estado ===7){
         aux[i].estado ='Rechazado';
       }
     }  
      console.log(data,'data obtenida')
      console.log(aux,'aux obtenida')
      //this.data = data.content;
     // this.dataSource=new MatTableDataSource(); 
     this.dataSource=new MatTableDataSource(aux); 
       this.dataSource.sort=this.sort;
       this.dataSource.paginator=this.paginator; 
    },
    error => this.showError('Error inesperado')
  );
  
    /*debugger
    console.log(this.params);
    let aux:any;
    let nr: any =  this.params.nroSolicitud
    let id: any = nr.substring(1)
    let od : any = parseInt(id)

    this.solicitudService.getSolicitudById(1).subscribe( data=>{ 
      //debugger
     // let obj = this.dataSource.filteredData;
     // this.dataSource.filteredData = Object.values(obj);
     let aux = Object.values(data)
     console.log(aux,"aux");
      this.dataSource = new MatTableDataSource(aux); 
     // console.log(this.dataSource.filteredData, "lista datasource")
     // this.dataSource=;  
      },
    error => this.showError('Error obtener solicitudes')
      );
      //return this.dataSource */ 
      
  }

  goToAprobacion(item: HemTrSolicitudTrans) {
    console.log(item)
    debugger
        let link = ['/solicitud/aprobacion/', item.idSolicitud];
        console.log('solicitud aprobacion: ' + link);
        this.router.navigate(link);
  }

  goToView(item: HemTrSolicitudTrans) {
        let link = ['/solicitud/', item.idSolicitud];
        console.log('solicitud Ver: ' + link);
        this.router.navigate(link);
  }



  
  getCurrentCentro() {
    this.currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    console.log(this.currentCentro);
  }

  getCentros(){
    this.centroService.getCentros().subscribe(
      data=>{
        this.centros=data;
        for (var k = 0; k < this.centros.length; k++){
          if(this.user.idUsuario == 0){
            this.params.centro = 0;
            this.params.idUsuario = this.user.idUsuario;
           }
           else{   
            if(this.currentCentro.idCentro==this.centros[k].idCentro){
                this.params.centro=this.centros[k];
                console.log(this.user.idUsuario);
                this.params.idUsuario = this.user.idUsuario;
                break;
            }
          }
        } 
        this.reloadSolicitud();            
      },
      error => this.showError('Error obtener centros')
    );
  }


  getUsuarios(){
    this.usuarioService.getUsers()
        .subscribe(
          data=>{
            console.log(data);
            console.log(data,'usuario');
            this.usuarios=data['content'];
            
            },
          error => this.showError('Error obtener usuarios')
        );
    }

  
  ngOnInit() {

    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarUsuarios' });
    this.clear();
    this.checkPermisos();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.getCurrentUsuario();
    this.getCurrentCentro();
    this.getCentros();
    this.getUsuarios();
    //this.getSolicitudes();
  }

  checkPermisos() {
      this.canSelect = true;
      this.canEditar = this.checkMenusPermitidos('/solicitud/id');
      this.canCrear=this.checkMenusPermitidos('/solicitud/nuevo');
  }

  checkMenusPermitidos(url) {
      let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
      let response = false;
      for (let i = 0; i < menus.length; i++) {
          url = url.replace(/\d+/g, "id");
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
      return response;
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
