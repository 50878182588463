import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {AdmTmMedicamento} from '../../../../common/admtmmedicamento';
import {AdmTmLovMedida} from '../../../../common/admtmlovmedida';
import { MedicamentoService } from '../../../../services/mantenimiento/medicamento.service';
import { LovsService } from '../../../../services/admin/lovs.service';


@Component({
  selector: 'app-registrar-medicamento',
  templateUrl: './registrar-medicamento.component.html',
  styleUrls: ['./registrar-medicamento.component.scss'],
  providers:[MedicamentoService,LovsService],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarMedicamentoComponent implements OnInit {
  newMedicamento: AdmTmMedicamento;
  busyCreate: Subscription;
  medidas: AdmTmLovMedida[];
  disableCreate = false;
  constructor(private medicamentoService : MedicamentoService,
    private lovsService: LovsService,
    private router : Router,
    private toastrService: ToastrService) { }
  

  loadUnidadMedidas() {
    this.lovsService.getAdmTmLovMedidaActivas()
      .subscribe(response => {
        this.medidas = response;
      })
  }

  clearNewMedicamento(){
    this.newMedicamento = {
      idMedicamento: null,
      codigoMedicamento: null,
      nombreGenerico: null,
      nombreComercial: null,
      fabricante: null,
      cantidadMedida: null,
      admTmLovMedida: null
      
    };
 
    this.disableCreate=false;
  }
  goToMedicamento(){
    let link = ['/medicamento'];
    this.router.navigate(link);
  }
  createMedicamento() {
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.disableCreate = true;
    // this.newCliente.ubigeo = this.newCliente.geoTmDepartamento.codigo + this.newCliente.geoTmProvincia.codigo + this.newCliente.geoTmDistrito.codigo;

      console.log(this.newMedicamento);
      this.busyCreate = this.medicamentoService.crearMedicamento(this.newMedicamento)
        .subscribe(
        data => {
          console.log(data);
          //this.showSuccess();

          if (data['codigoValidacion'] == 1)
            this.showWarning(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 2)
            this.showSuccess(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 3)
            this.showError(data['mensajeValidacion']);

          //this.reloadItems(this.params);		
        },
        error => this.showError('Error inesperado'),
        () => this.clearNewMedicamento()
        );
    }
  }

  ngOnInit() {
    this.clearNewMedicamento();
    this.loadUnidadMedidas();
  }
  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }


}
