import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargar-registros-excel',
  templateUrl: './cargar-registros-excel.component.html',
  styleUrls: ['./cargar-registros-excel.component.scss']
})
export class CargarRegistrosExcelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
