import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/registroevolucion';

@Injectable()
export class RegistroEvolucionService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }


    createEvolucion(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/nuevoEvolucion';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updateEvolucion(body: Object): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/update';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    getEvoluciones(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // GET
    getEvolucionesByIdUsuario(idUsuario): Observable<any> {
        let findUrl = BASE_URL+'/search/'+idUsuario;
        return this.http.get(findUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    // GET
    getEvolucionesById(idRegistro): Observable<any> {
        let findUrl = BASE_URL+'/'+idRegistro;
        return this.http.get(findUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //Jeancarlo A. 21-04-21
    getRegistroFullById(idRegistro): Observable<any> {
        let findUrl = BASE_URL + '/full/' + idRegistro;
        return this.http.get(findUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getRegistroArchivos(idRegistro): Observable<any> {
        let createUrl = BASE_URL + '/obtenerArchivos';
        return this.http.get(`${createUrl}/${idRegistro}`, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    eliminarRegistroArchivo(idArchivo): Observable<Object> {
        let getUrl = BASE_URL + '/eliminarArchivo';
        return this.http.post(`${getUrl}/${idArchivo}`, {}, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    updateRegistroArchivos(data: any, file: File): Observable<HttpEvent<any>> {
        var usuario = JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL + '/uploadArchivo';
        
        const formData: FormData = new FormData();
        formData.append('file', file);
        
        data.file = formData;

        formData.append('idRegistro', data.idRegistro);
        formData.append('idRegEvolucion', data.idRegEvolucion);
        formData.append('nombreArchivo', data.nombreArchivo);

        //const req = new HttpRequest('POST', `${getUrl}/${usuario.idUsuario}`, data, {
        const req = new HttpRequest('POST', `${getUrl}/${usuario.idUsuario}`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(req);
    }

    updateObservacionesRegistro(body: Object): Observable<Object> {
        var usuario = JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/updateObservaciones';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    finalizarRegistro(idRegistro): Observable<Object> {
        var usuario = JSON.parse(sessionStorage.getItem('currentUser'));
        let getUrl = BASE_URL+'/finalizarRegistro';
        let body = {
            idRegistro: idRegistro
        }
        let bodyString = JSON.stringify(body);
        return this.http.post(`${getUrl}/${usuario.idUsuario}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }
    //

    search(paramsBusqueda: any): Observable<any> {
        let getUrl = BASE_URL + '/search';
        let paramsGet = new HttpParams()
            .set('fechaDesde', paramsBusqueda.fechaDesde)
            .set('fechaHasta', paramsBusqueda.fechaHasta)
            .set('codigoPaciente', paramsBusqueda.codigoPaciente)
            .set('nombresPaciente', paramsBusqueda.nombresPaciente)
            .set('apellidosPaciente', paramsBusqueda.apellidosPaciente)
            .set('nroDocumentoPaciente', paramsBusqueda.nroDocumentoPaciente)
            .set('generoPaciente', paramsBusqueda.generoPaciente)
            .set('centroPaciente', paramsBusqueda.centroPaciente)
            .set('medicoPaciente', paramsBusqueda.medicoPaciente)
            .set('idUsuario',paramsBusqueda.idUsuario)
            .set('pagina', paramsBusqueda.pagina)
            .set('cantidadPagina', paramsBusqueda.cantidadPagina);

        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            
            params: paramsGet
        }
        console.log(paramsGet,'entrada a java')
        return this.http.get(getUrl, options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    
     // Error handling
     errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
        } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
    
   
}
