import { Component, OnInit,ViewChild, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//services
import { MessageService } from '../../../../services/MessageService';

import { PerfilService } from '../../../../services/mantenimiento/perfil.service';
import { MenuService } from '../../../../services/admin/menu.service';
//domain
import {AdmTmPerfil} from '../../../../common/admtmperfil';
import { AdmTmMenu } from '../../../../common/admtmmenu';
@Component({
  selector: 'app-registrar-perfil',
  templateUrl: './registrar-perfil.component.html',
  styleUrls: ['./registrar-perfil.component.scss'],
  providers:[PerfilService,MenuService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class RegistrarPerfilComponent implements OnInit {
  newPerfil : AdmTmPerfil;
  getData:string;
  menu : AdmTmMenu[];
  perfiles : AdmTmPerfil[];
  idsMenuSelected : number[];
  busyCreate: Subscription;
  disableCreate=false;
  isSuperAdminProfile=false;

  constructor(
    private perfilService : PerfilService,
    private menuService : MenuService,
    private router : Router,
    private toastrService: ToastrService
  ) { }
  goToPerfiles(){
    let link = ['/perfil'];
    this.router.navigate(link);
  }

  getMenu(){
    this.menuService.getMenu()
        .subscribe(
          data=>this.menu=data,
           error => this.showError('Error inesperado')
        );
  }

  getMenuSelected(m : number){
     let indexMenu = this.idsMenuSelected.indexOf(m);
     if( indexMenu != -1){
       this.idsMenuSelected.splice(indexMenu, 1);
     }else{
       this.idsMenuSelected.push(m);
     }
  }
  
  isMenuSelected(m : number){
     let indexMenu = this.idsMenuSelected.indexOf(m);
     if( indexMenu != -1){
       return true;
     }else{
       return false;
     }
  }

  selectAllMenu() {
    for(let m of this.menu) {
      let indexMenu = this.idsMenuSelected.indexOf(m.idMenu);
      if(indexMenu == -1){
        this.idsMenuSelected.push(m.idMenu);
      }
    }
  }

  removeAllSelectedsMenu() {
    this.idsMenuSelected = [];
  }

  toogleIsSuperAdmin() {
    if(this.isSuperAdminProfile) {
      let response : AdmTmPerfil = null;
      this.perfilService.getPerfilesActivos()
          .subscribe(
            data=>{
                  this.perfiles=data;
                  for(let p of this.perfiles) {
                    if(p.isSuperAdmin === 1) {
                      response = p;
                      break;
                    }
                  }
                  if(response == null) {
                    this.newPerfil.isSuperAdmin=1;
                    this.newPerfil.nombrePerfil='Super Administrador';
                    this.newPerfil.descripcion='Con acceso a todos los módulos del sistema, además de todos los permisos existentes.';
                    this.selectAllMenu();
                  } else {
                    this.isSuperAdminProfile = false;
                   // this.showMessageWarning();
                  }
                  },
            error => this.showError('Error inesperado')
          );
    } else {
      this.newPerfil.isSuperAdmin=0;
      this.newPerfil.nombrePerfil='';
      this.newPerfil.descripcion='';
      this.removeAllSelectedsMenu();
      this.isSuperAdminProfile = false;
    }
    console.log('this.isSuperAdminProfile:2 ', this.isSuperAdminProfile);
  }


  createPerfil(){

    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.disableCreate=true;
      let body = {
        "admTmPerfil": this.newPerfil,
        "idMenus": this.idsMenuSelected
      }
      console.log(body)
      this.busyCreate=this.perfilService.createPerfil(body)
        .subscribe( 
        data => {
            //this.getData=JSON.stringify(data);
            //this.isSuperAdminProfile = false;
            //this.showSuccess();  
            if(data['codigoValidacion']==1)
              this.showWarning(data['mensajeValidacion']);
            if(data['codigoValidacion']==2)
              this.showSuccess(data['mensajeValidacion']);
            if(data['codigoValidacion']==3)
              this.showError(data['mensajeValidacion']);    

            this.isSuperAdminProfile = false;                
        },
        error => this.showError('Error inesperado'),
          () => this.clearNewPerfil()
        );
    }
  }

  clearNewPerfil(){
    this.newPerfil = {
      idPerfil:null,
      descripcion:null,
      estado:null,
      isSuperAdmin:null,
      idUsuarioActualizacion:null,
      idUsuarioCreacion:null,
      nombrePerfil:null,
      admTrMenuxperfils:[]
      };
    this.idsMenuSelected=[];
    this.disableCreate=false;
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

/*
  showSuccess() {
        this.toastrService.success('El perfil ha sido creado correctamente.' ,'¡Éxito!' );
  }
  
  showWarning() {
        this.toastrService.warning('Debe seleccionar al menos un permiso.' ,'¡Advertencia!' );
  }

  showError() {
        this.toastrService.error('Ha ocurrido un problema.','¡Error!' );
  }
*/
  ngOnInit() {
    this.getMenu();
    this.clearNewPerfil();
  }


}
