import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {AdmTmRegistro} from '../../../../common/admtmregistro';
import {HemTrRegistroAnteLocal} from '../../../../common/hemtrregistroantelocal';
import {HemTrRegistroAntecedentes} from '../../../../common/hemtrregistroantecedentes';
import { LovsService } from '../../../../services/admin/lovs.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { RegistroanteService } from "../../../../services/hemofilia/registroante.service";
import { RegistromiembroFamiliarService } from '../../../../services/hemofilia/registro.miembrofamiliar.service';
import { RegistroComorbilidadesService } from '../../../../services/hemofilia/registrocomorbi.service';
import { Output, EventEmitter } from '@angular/core';


import * as $ from 'jquery';

@Component({
  selector: 'app-registro-antecedentes',
  templateUrl: './registro-antecedentes.component.html',
  styleUrls: ['./registro-antecedentes.component.scss'],
  providers:[LovsService,ToastrService,RegistroService,RegistroanteService,RegistroComorbilidadesService],
  encapsulation: ViewEncapsulation.None
})
export class RegistroAntecedentesComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  registro: AdmTmRegistro;
  newPacienteComorbi: any=null;
  cargadoDataComorbi: boolean = false; //Jie 07-03-2021
  dataRecibidaComorbi: any = null; //Jie 07-03-2021
  newPacienteAnte: HemTrRegistroAntecedentes = {
    idRegistroAntece: null,
    mesDiagnostico:null,
    edadDiagnostico:null,
    mesesHospitalizacion:null,
    edadHospitalizacion:null,
    perfilCoagulacion:null,
    dosajeFactor:null,
    idYNLaboratorio:null,
    idYNClinica:null,
    idYNEvaHospital:null,
    fechaCirugia:null,
    hemTrRegistro: null,
    evaluacionHospitalizacion: null,
    porClinica: null,
    porLaboratorio: null,
    idRegistroEvolucion: null //Jeancarlo A. 18/03/21
  };
  localizacionesCargadas: any[] = null;
  disableCreate = false;
  listaYesNos: any = null;
  listaLocalizaciones: any = null;
  newPacienteAnteLocal: any;
  idRegistro: any = null;
  disableEdit = false;

  dataPacienteRegistro: any = null;
  idLovYesNoAnteFs: any = null;
  newPacienteAnteFamiliar: any=null;
  anteFamiliares: any = [];
  dataRecibida: any = null;
  
  cargadoData: boolean = false;
  cargadoYesNo: boolean = false;
  noFamiliares: boolean = true;
  cargadoEnfermedades: boolean = false;

  enfermedades: any = [];
  enfermedadesVacuna: any = [];

  noEventoSangrado: boolean = true;

  constructor(
    private toastrService: ToastrService,
    private lovsService: LovsService,
    private route: ActivatedRoute,
    private router : Router,
    private registroanteService: RegistroanteService,
    private registroMiembroFamiliarService: RegistromiembroFamiliarService,
    private registroComorbilidadesService: RegistroComorbilidadesService,
  ) { 
    this.enfermedades=[];
    this.enfermedadesVacuna=[];
  }
  
  clearNewPaciente(){
    this.newPacienteAnte = {
      idRegistroAntece: null,
      mesDiagnostico:null,
      edadDiagnostico:null,
      mesesHospitalizacion:null,
      edadHospitalizacion:null,
      perfilCoagulacion:null,
      dosajeFactor:null,
      idYNLaboratorio:null,
      idYNClinica:null,
      idYNEvaHospital:null,
      fechaCirugia:null,
      hemTrRegistro: null,
      evaluacionHospitalizacion: null,
      porClinica: null,
      porLaboratorio: null,
      idRegistroEvolucion: null //Jeancarlo A. 18/03/21
    };

    this.newPacienteAnteLocal = {};

    this.disableCreate=false;
  }

  recibirData(data) {
    // esto lo añadi de antecedentes
    this.dataPacienteRegistro = data;
    this.disableEdit = data.disableEdit;
    //
    //añadido de comorbilidades- Jeancarlo A.
    this.newPacienteComorbi.hemTrRegistro = {
      idRegistro: data.idRegistro
    }
    if (data.idRegistro){
      this.idRegistro = data.idRegistro;
      this.getRegistroById(data.idRegistro);
    }

    //if (data.hemTrRegistroEstado.idRegistroEstado > 1){ // 1: En proceso
      //this.disableEdit = true;
      //$("edadHospitalizacion").attr("disabled",true);
    //} //else {
      //this.disableEdit = false;
    //}//
  }

  getRegistroById(id){
    this.newPacienteAnte.hemTrRegistro = {
      idRegistro: id
    }
  }
  
  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(
      data=>{
        this.listaYesNos = data;
        this.revisarCamposYesNo();
        this.idLovYesNoAnteFs = data;
        this.cargadoYesNo = true;
        this.verificarCarga();
      },
      error => this.showError('Error obtener yes no')
    );
  }
  //Añadido de Comorbilidades
  clearNewRegistroComorbi(){
    this.newPacienteComorbi = {
      vacunacion: null,
      fecha1: null,
      fecha2: null,
      fecha3:null
    };
  }
  //

  //Añadidos de Comorbilidades agregados por Jeancarlo A. 25/02/21:
  getLovEnfermedades(){
    this.lovsService.getAdmTmLovEnfermedadActivas().subscribe(
      data=>{
        this.enfermedades = data;
        let vacunas = [];
        for (var enfermedad of data){
          if (enfermedad.tipo == 'VACUNA'){
            vacunas.push(enfermedad);
        }
      }
        this.enfermedadesVacuna = vacunas;
        
        this.cargadoEnfermedades = true;
        this.verificarCargaComorbi();
      },
      error => this.showError('Error obtener yes no')
    );
  }
 
  //Tambien añadido de Comorbilidades; pero al parecer no hace nada

  fechaVacunaElegida(event, row, numeroFecha){
    if (numeroFecha == 1){
      row.fecha1 = event;
    }else if (numeroFecha == 2){
      row.fecha2 = event;
    }else if (numeroFecha == 3){
      row.fecha3 = event;
    }
  }

  //Metodo con el que comorbilidades guarda su registro completo; incluido vacunas;
  //limitado a vacunas

  createRegistroComorbilidades(){
    let registroComorb=[];
    let fecha1No=1;
    let fecha2No=1;
    let fecha3No=1;
    for (var i = 0; i < this.enfermedadesVacuna.length; i++){
      if(this.enfermedadesVacuna[i].fecha1No){
        fecha1No=1;
      }else{
        fecha1No=0;
      }
      if(this.enfermedadesVacuna[i].fecha2No){
        fecha2No=1;
      }else{
        fecha2No=0;
      }
      if(this.enfermedadesVacuna[i].fecha3No){
        fecha3No=1;
      }else{
        fecha3No=0;
      }
      var vacuna: any = {
        admTmLovEnfermedad: this.enfermedadesVacuna[i],
        tipo: this.enfermedadesVacuna[i].tipo,
        fecha1: this.enfermedadesVacuna[i].fecha1,
        fecha2: this.enfermedadesVacuna[i].fecha2,
        fecha3: this.enfermedadesVacuna[i].fecha3,
        resultado:null,
        otraEnfermedades:null,
        hemTrRegistro:this.newPacienteComorbi.hemTrRegistro,
        fecha1No: fecha1No,
        fecha2No: fecha2No,
        fecha3No: fecha3No
      };
      registroComorb.push(vacuna);
    }

    let dataEvento = {
      listaRegistroComorbilidades: registroComorb,
      idRegistro: this.idRegistro
    }

    this.registroComorbilidadesService.createRegistroComorbi(dataEvento).subscribe(data => {
      if (data['codigoValidacion'] == 1){
        this.showWarning(data['mensajeValidacion']);
        this.disableCreate = false;
      }else if (data['codigoValidacion'] == 2){
        //this.showSuccess('Comorbilidades guardadas.')
        //this.siguienteTab();
      }else if (data['codigoValidacion'] == 3){
        this.showError(data['mensajeValidacion']);
        this.disableCreate = false;
      }
    },
    error => this.showError('Error inesperado'),
    );
  }

  //Añadido de Comorbilidades Jeancarlo A; al parecer el metodo carga 
  cargarComorbilidades(){
    this.registroComorbilidadesService.getComorbilidadesByRegistro(this.idRegistro).subscribe(
      data => {
        this.dataRecibidaComorbi = data;
        this.cargadoDataComorbi = true;
        this.verificarCargaComorbi();
      }
    );
  }
  //

  // Añadido de antecedentes:
  cargarMiembrosFamiliares(){
    this.registroMiembroFamiliarService.getRegistroEventByRegistro(this.idRegistro).subscribe(
      data => {
        this.dataRecibida = data;
        console.log(data);
        this.cargadoData = true;
        this.verificarCarga();
      }
    );
  }


  //Jie 07-03-2021
  //Antecedente Familiares
  verificarCarga(){
    if (this.cargadoData && this.cargadoYesNo){
      if (this.idRegistro && this.dataRecibida){
        let filas = [];

        if (this.dataRecibida && this.dataRecibida.length > 0){
          for (var i = 0; i < this.dataRecibida.length; i++){
            for(var x=0; x<this.listaYesNos.length; x++){
              if (this.dataRecibida[i].vida && this.dataRecibida[i].vida.idYesNo == this.listaYesNos[x].idYesNo ){//probando quitar el idYesNo de this.dataRecibida[i].vida
                this.dataRecibida[i].vida = this.listaYesNos[x];
                break;
              }
            }    
            filas.push({
              disabledFila: this.disableEdit,
              nombre: this.dataRecibida[i].nombre,
              parentesco: this.dataRecibida[i].parentesco,
              vida: this.dataRecibida[i].vida,
              edad: this.dataRecibida[i].edad
            })
          }
        }

        if (filas.length > 0){
          this.newPacienteAnteFamiliar.idLovYesNoAnteF = this.idLovYesNoAnteFs.find(x => x.codigo == 'YES');          
          this.noFamiliares = false;
        }else{
          this.newPacienteAnteFamiliar.idLovYesNoAnteF = this.idLovYesNoAnteFs.find(x => x.codigo == 'NO');
          this.noFamiliares = true;
        }

        this.anteFamiliares = filas;
        console.log("revisando la carga");
        //this.anteFamiliares = [...this.anteFamiliares]
      }
    }
  }
  //Vacunas comorbilidades
  verificarCargaComorbi(){
    if (this.cargadoDataComorbi && this.cargadoEnfermedades){
      if (this.idRegistro && this.dataRecibidaComorbi){
        var enfermedadesPivote;
        for (var i = 0; i < this.dataRecibidaComorbi.length; i++){
          let recibida = this.dataRecibidaComorbi[i];
          if (recibida.tipo == 'VACUNA'){
            enfermedadesPivote = this.enfermedadesVacuna;

            let enfermedadEditar = enfermedadesPivote.find(enfr => enfr.idEnfermedad == recibida.admTmLovEnfermedad.idEnfermedad);
            if (enfermedadEditar){
              enfermedadEditar.fecha1 = (recibida.fecha1 ? new Date(recibida.fecha1 + 'T24:00:00') : null);
              enfermedadEditar.fecha2 = (recibida.fecha2 ? new Date(recibida.fecha2 + 'T24:00:00') : null);
              enfermedadEditar.fecha3 = (recibida.fecha3 ? new Date(recibida.fecha3 + 'T24:00:00') : null);
              enfermedadEditar.resultado = recibida.resultado;
              enfermedadEditar.nombreOtro = recibida.nombreOtro;
              enfermedadEditar.otraEnfermedades = recibida.otraEnfermedades;
              if(recibida.fecha1No){
                enfermedadEditar.fecha1No=1;
              }else{
                enfermedadEditar.fecha1No=0;
              }
              if(recibida.fecha2No){
                enfermedadEditar.fecha2No=1;
              }else{
                enfermedadEditar.fecha2No=0;
              }
              if(recibida.fecha3No){
                enfermedadEditar.fecha3No=1;
              }else{
                enfermedadEditar.fecha3No=0;
              }

            }


          }
        }
       // this.enfermedadesVacuna = [...this.enfermedadesVacuna]
      }
    }
  }

  getLovLocalizaciones(){
    this.lovsService.getAdmTmLovLocalizacionCuerpoActivas().subscribe(
      data=>{
        this.listaLocalizaciones = data;
        this.revisarLocalizaciones();
      },
      error => this.showError('Error obtener localizaciones')
    );
  }

  cargarAntecedentes(){
    this.registroanteService.getRegistroAnteByRegistro(this.idRegistro).subscribe(
      data => {
        if (data){
          console.log('data registro antecedentes');
          console.log(data);

          //this.newPacienteAnte = data.antecedentes;
          this.newPacienteAnte = {
            idRegistroAntece: data.antecedentes.idRegistroAntecedentes,
            mesDiagnostico: data.antecedentes.mesDiagnostico,
            edadDiagnostico: data.antecedentes.edadDiagnostico,
            mesesHospitalizacion: data.antecedentes.mesesHospitalizacion,
            edadHospitalizacion: data.antecedentes.edadHospitalizacion,
            perfilCoagulacion: data.antecedentes.perfilCoagulacion,
            dosajeFactor: data.antecedentes.dosajeFactor,
            idYNLaboratorio: null,
            idYNClinica: null,
            idYNEvaHospital: null,
            fechaCirugia: null,
            hemTrRegistro: { idRegistro: this.idRegistro },
            evaluacionHospitalizacion: { idYesNo: data.antecedentes.idEvaluacionHospitalizacion },
            porClinica: { idYesNo: data.antecedentes.idPorClinica },
            porLaboratorio: { idYesNo: data.antecedentes.idPorLaboratorio },
            idRegistroEvolucion: null //Jeancarlo A. 18/03/21
          };

          this.localizacionesCargadas = data.localizaciones;
          this.revisarLocalizaciones();
          this.revisarCamposYesNo();
        }
      }
    );
  }

  revisarLocalizaciones(){
    if (this.localizacionesCargadas && this.localizacionesCargadas.length > 0 && this.listaLocalizaciones && this.listaLocalizaciones.length > 0){
  //   console.log(this.localizacionesCargadas);
 //    console.log(this.listaLocalizaciones);
      for (var i = 0; i < this.localizacionesCargadas.length; i++){
        let localizacion = this.listaLocalizaciones.find(l => l.idLocalCuerpo === this.localizacionesCargadas[i].idLocalCuerpo);
        if (localizacion){
          localizacion.checked = true;
          localizacion.nombreOtros = this.localizacionesCargadas[i].nombreOtros;
          //console.log(localizacion);
        }
      }
    }
  }

  revisarCamposYesNo(){
    if (this.listaYesNos  && this.newPacienteAnte){
      // Se da formato a los campos idYNClinica - idYNLaboratorio
      for (var i = 0; i < this.listaYesNos.length; i++){
        if (this.newPacienteAnte.porClinica && this.newPacienteAnte.porClinica.idYesNo == this.listaYesNos[i].idYesNo ){
          this.newPacienteAnte.idYNClinica = this.listaYesNos[i];
        }

        if (this.newPacienteAnte.porLaboratorio && this.newPacienteAnte.porLaboratorio.idYesNo == this.listaYesNos[i].idYesNo ){
          this.newPacienteAnte.idYNLaboratorio = this.listaYesNos[i];
        }
        
        if (this.newPacienteAnte.evaluacionHospitalizacion && this.newPacienteAnte.evaluacionHospitalizacion.idYesNo == this.listaYesNos[i].idYesNo ){
          this.newPacienteAnte.idYNEvaHospital = this.listaYesNos[i];
        }

      }

      this.changeHospitalizacion();
    }
  }

  //agregado de antecedentes familiares (este parece ser el culpable de hacer todos los cbo "si" y "no")
  clearNewRegistroAnteF(){
    this.newPacienteAnteFamiliar = {
      idLovYesNoAnteF: null      
    };
  }
  //

  //Más agregado de antecedentes familiares
  nombreIngresada(event, row){
    if (event && event.target && event.target.value){
      row.nombre = event.target.value;
    }else{
      row.nombre = '';
    }
  }

  parentescoIngresada(event, row){
    if (event && event.target && event.target.value){
      row.parentesco = event.target.value;
    }else{
      row.parentesco = '';
    }
  }

  agregarFila(){
    let vida=this.listaYesNos.find(x => x.codigo == 'YES');
    this.anteFamiliares.push({
      nombre: '',
      parentesco: '',
      vida:vida.idYesNo,
      edad: '',
    });
   this.anteFamiliares = [...this.anteFamiliares]
  }

  eliminarFila(rowIndex){
    this.anteFamiliares.splice(rowIndex, 1);
   // this.anteFamiliares = [...this.anteFamiliares]
  }

  algunFamiliarCambiado(){
    if (this.newPacienteAnteFamiliar.idLovYesNoAnteF && this.newPacienteAnteFamiliar.idLovYesNoAnteF.idYesNo == 1){
      this.noFamiliares = false;
    }else{
      this.noFamiliares = true;
    }
  }

  createRegistroAnteFamiliares(){
    //var f = document.getElementsByTagName('form')[2];
    //var f = $("#antecedentesFamiForm")[0];
    var f = document.getElementsByTagName('form')[2];
    if(f.reportValidity()){
      let registrosMiembros = [];
      if (this.newPacienteAnteFamiliar.idLovYesNoAnteF.idYesNo == 1){
        // Si
        for (var i = 0; i < this.anteFamiliares.length; i++){
          registrosMiembros.push({
            nombre: this.anteFamiliares[i].nombre,
            parentesco: this.anteFamiliares[i].parentesco,
            vida:this.anteFamiliares[i].vida.idYesNo,
            edad:this.anteFamiliares[i].edad
          })
        }
      }
      let dataCrear = {
        idRegistro: this.idRegistro,
        miembros: registrosMiembros
      }
      console.log(dataCrear)
      this.registroMiembroFamiliarService.createRegistroMiembroFamiliar(dataCrear).subscribe(data => {
        if (data['codigoValidacion'] == 1){
          this.showWarning(data['mensajeValidacion']);
          this.disableCreate = false;
        }else if (data['codigoValidacion'] == 2){
          //this.showSuccess('Antecedentes familiares guardados.')
          //this.siguienteTab();
        }else if (data['codigoValidacion'] == 3){
          this.showError(data['mensajeValidacion']);
          this.disableCreate = false;
        }
      },
      error => this.showError('Error inesperado'),
      );

    }
  }

  //Agregado para controlar las casillas de números meses por Jeancarlo A.
  parametrosteclado(e){
    var key = e.Keycode || e.wich;
    var teclado = String.fromCharCode(key);
    var numero="0123456789";
    var especiales : any ;
    especiales="8-37-38-46";
    var teclado_especial=false;
    for(var i in especiales){
      if(key==especiales[i]){
        teclado_especial=true;
      }
    } 
    if(numero.indexOf(teclado) == -1 && !teclado_especial){
      return false
    }
  }

  //Hasta acá

  changeHospitalizacion(){
    if (this.newPacienteAnte.idYNEvaHospital && this.newPacienteAnte.idYNEvaHospital.idYesNo == 1){
      // Es si - se verifican los campos obligatorios
      $("#edadHospitalizacion").attr("required","");
      $("#mesesHospitalizacion").attr("required","");
      this.noEventoSangrado = false;
      //$("#edadHospitalizacion").prop("disabled", false);
      //$("#mesesHospitalizacion").prop("disabled", false);
      //Cambio realizado Jeancarlo 22/02/20
      $("input[id^='list_loc_']").removeAttr("disabled",false);
      //
    }else{
      $("#edadHospitalizacion").removeAttr("required");
      $("#mesesHospitalizacion").removeAttr("required");
      //$("#edadHospitalizacion").prop("disabled", true);
      //$("#mesesHospitalizacion").prop("disabled", true);
      this.noEventoSangrado = true;
      //Cambio realizado Jeancarlo 22/02/20
      $("input[id^='list_loc_']").prop("checked",false);
      $("input[id^='list_loc_']").attr("disabled",true);
      $("input[id^='loc_detalle_']").attr("disabled",true);
      //
      this.newPacienteAnte.edadHospitalizacion = null;
      this.newPacienteAnte.mesesHospitalizacion = null;

      if (this.listaLocalizaciones){
        
        for(var i = 0; i < this.listaLocalizaciones.length; i++){
          if (this.listaLocalizaciones[i].checked){
            console.log(this.listaLocalizaciones[i]);
            this.listaLocalizaciones[i].checked=false;
            this.listaLocalizaciones[i].nombreOtros='';
          }
        }
      }
      
    }
  }
  
  createRegistroAntecedentes(){
    var f = document.getElementsByTagName('form')[2];
    if(f.reportValidity()) {      
      this.disableCreate = true;
      // Campos para hacer match con el BE
      //this.newPacienteAnte.evaluacionHospitalizacion = this.newPacienteAnte.idYNEvaHospital;
      //this.newPacienteAnte.porClinica = this.newPacienteAnte.idYNClinica;
      //this.newPacienteAnte.porLaboratorio = this.newPacienteAnte.idYNLaboratorio;

      let dataAntecedentes = {
        idRegistro: this.idRegistro,
        idRegistroAntecedentes: this.newPacienteAnte.idRegistroAntece,
        edadDiagnostico: this.newPacienteAnte.edadDiagnostico,
        mesDiagnostico: this.newPacienteAnte.mesDiagnostico,
        idPorClinica: this.newPacienteAnte.idYNClinica.idYesNo,
        idPorLaboratorio: this.newPacienteAnte.idYNLaboratorio.idYesNo,
        idEvaluacionHospitalizacion: this.newPacienteAnte.idYNEvaHospital.idYesNo,
        perfilCoagulacion: this.newPacienteAnte.perfilCoagulacion,
        dosajeFactor: this.newPacienteAnte.dosajeFactor,
        edadHospitalizacion: this.newPacienteAnte.edadHospitalizacion,
        mesesHospitalizacion: this.newPacienteAnte.mesesHospitalizacion,
        idRegistroEvolucion: null //Jeancarlo A. 18/03/21
      }

      var localizaciones = [];
      if (this.listaLocalizaciones){
        for(var i = 0; i < this.listaLocalizaciones.length; i++){
          if (this.listaLocalizaciones[i].checked){
            console.log(this.listaLocalizaciones[i]);
            localizaciones.push({
              idLocalCuerpo: this.listaLocalizaciones[i].idLocalCuerpo,
              nombreOtros: this.listaLocalizaciones[i].nombreOtros  //null  <---- REVISAR IMPLEMENTACION EN CASO "OTROS"
              
            });
          }
        }
      }
      let dataEnvio = {
        antecedentes: dataAntecedentes,
        localizaciones: localizaciones
      }
      console.log(dataEnvio);

      //Jeancarlo A. 08-06-21  : Cambios en el guardado
     /* if(this.newPacienteAnte.idYNEvaHospital.idYesNo == 1 && this.){
        this.showWarning("Seleccionar localización del sangrado del sangrado");
      }else{*/
      if((localizaciones == null || localizaciones.length == 0) && this.newPacienteAnte.idYNEvaHospital.idYesNo == 1){
        this.showWarning("Se debe elegir una localización del sangrado");
      }else{

      this.registroanteService.createRegistroAnte(dataEnvio).subscribe(data => {
          console.log(data);
          //let getData = JSON.stringify(data);
          if (data['codigoValidacion'] == 1){
            this.showWarning(data['mensajeValidacion']);
            this.disableCreate = false;
          }else if (data['codigoValidacion'] == 2){
            this.newPacienteAnte.idRegistroAntece = data['id'];
            this.showSuccess('Antecedentes guardados.')
            this.siguienteTab();
          }else if (data['codigoValidacion'] == 3){
            this.showError(data['mensajeValidacion1']);
            this.disableCreate = false;
          }
        },
        error => this.showError('Error inesperado'),
      );
    }
    
    }
  }

  siguienteTab(){
    this.dataRegistrada.next(this.newPacienteAnte);
  }

  createRegistros(){
    this.createRegistroAntecedentes();
    //console.log("Observacion");
    this.createRegistroAnteFamiliares();
    //console.log("Observacion familiar");
    this.createRegistroComorbilidades();
  }

  noEscribas(){
    var mesDiag=document.getElementsByName("mesDiagnostico");
    var mesHosp=document.getElementsByName("mesesHospitalizacion");
    $(mesDiag).keydown(function(){
      return false
    });
    $(mesHosp).keydown(function(){
      return false
    });
  }


  ngOnInit() {
    console.log('registro antecedentes');
    this.noEscribas();
    this.clearNewPaciente();
    //sacado de antecedentes
    this.clearNewRegistroAnteF();
    //sacado de comorbilidades
    this.clearNewRegistroComorbi();
    
    //
    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegistro = itemid;
      this.newPacienteAnte.hemTrRegistro = {
        idRegistro: itemid
      }
      this.newPacienteComorbi.hemTrRegistro = {
        idRegistro: itemid
      }
      this.cargarAntecedentes();
      this.cargarMiembrosFamiliares();
      this.cargarComorbilidades();
    }else{
      this.cargadoData = true;
    }
    this.getLovEnfermedades();
    this.getLovYesNo();
    this.getLovLocalizaciones();
    console.log("prueba");
    console.log(this.listaLocalizaciones);
    //$("#tabset :input").attr('disabled', 'disabled');
    //$(".tablas").attr('disabled', 'disabled');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }


}
