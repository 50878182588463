import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Util } from '../../../util';


const BASE_URL = Util.IP_API_CONNECTION ;

@Injectable()
export class CatalogosService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }
    //CatalogTmCodigoTipoDocumentoIdentidad
    findById(idTipoDocumento): Observable<any> {
        let findUserUrl = BASE_URL+'/catalogtipodocumentoidentidad/'+idTipoDocumento;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getCodigoTiposDocumentoIdentidadActivas(): Observable<any> {
        let url =BASE_URL+ '/catalogtipodocumentoidentidad/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }



     // Error handling
     errorHandl(error) {
            let errorMessage = '';
            if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
            } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            console.log(errorMessage);
            return throwError(errorMessage);
    }
}

