import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//services
import { MessageService } from '../../../services/MessageService';
import { CentroService } from '../../../services/mantenimiento/centro.service';
import { UserService } from '../../../services/admin/user.service';
import { ReportesService } from '../../../services/hemofilia/reportes.service';
//import { LoaderService } from '../shared/loader/loader.service';
//domain
import {HemTrRegistroReporte} from '../../../common/hemtrreportes';
import {AdmTmCentro} from '../../../common/admtmcentro';
import { DataService } from '../../../services/DataService';

import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { InputDialogComponent } from '../../shared/input-dialog/input-dialog.component';
import * as $ from 'jquery';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
type AOA = any[][];

export interface genero {
  codigo: string;
  descripcion: string;
}
export interface estado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-reporte-listar',
  templateUrl: './reporte-listar.component.html',
  styleUrls: ['./reporte-listar.component.scss'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class ReporteListarComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEdit = false;
  canCreate = true; // Para que funcione el disable correctamente
  parametros:any;
  getData: string;
  centros: AdmTmCentro[];
  disableButtons: boolean = true;
  currentCentro: any;
  user :any;
  usuarios :any;
  options: any[];
  public elec: String;

  pagina: any = {
    total: 0,
    numeroPagina: 0,
    cantidadPagina: 10
  }

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  displayedColumns: string[] = ['FechaCreacion', 'numReporte','Centro', 'Fecha Desde','Fecha Hasta','Acciones'];
  dataSource: MatTableDataSource<HemTrRegistroReporte>;
  dataTabla: any = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('excelInput', {static: true}) excelInput: ElementRef;

  constructor(private centroService : CentroService,
    private usuarioService : UserService,
    public dialog: MatDialog,
    private router : Router,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dataService: DataService,
    private reportesService: ReportesService
  ) { }
 
 
  
  clear() {
    this.items = [];
    this.params = {
      fechaDesde: null,
      fechaHasta: null,
      codigoUsuario:null,
      nombresUsuario:null,
      apellidosUsuario:null,
      admTrPerfilxusuarios:null,
      centro:null,
      numeroDocumento:null,
      genero:null,
      estado:null
    };
    this.parametros = [];
  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }




  checkPermisos() {
    this.canSelect = true;
    this.canCreate=this.checkMenusPermitidos('/reportes');
  }

  checkMenusPermitidos(url) {
    let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
    let response = false;
    url = url.replace(/\d+/g, "id");
    if (menus){
      for (let i = 0; i < menus.length; i++) {          
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
    }
    return response;
  }

  seleccionarPagina(pageInfo){
    this.pagina.numeroPagina = pageInfo.offset;
    this.obtenerData();
  }

  obtenerData(){
    console.log('data a buscar')
    console.log(this.params);

    let paramsBuscar: any = {
      fechaDesde: this.params.fechaDesde,
      fechaHasta: this.params.fechaHasta,
      pagina: this.pagina.numeroPagina,
      cantidadPagina: this.pagina.cantidadPagina
      
    }

    if (this.params.fechaDesde){
      let momentDesde = moment(this.params.fechaDesde);
      if (momentDesde.isValid()){
        paramsBuscar.fechaDesde = momentDesde.format('MM/DD/YYYY');
      }
    }

    if (this.params.fechaHasta){
      let momentHasta = moment(this.params.fechaHasta);
      if (momentHasta.isValid()){
        paramsBuscar.fechaHasta = momentHasta.format('MM/DD/YYYY');
      }
    }

    if(this.user.idUsuario==0){
      paramsBuscar.estadoUsuario =1
    }else{
      paramsBuscar.estadoUsuario =0;
    }


    if (this.params.centro && this.params.centro.idCentro){
      paramsBuscar.centroPaciente = this.params.centro.idCentro;
    }

 
    
 
    this.reportesService.getReportes().subscribe( data => {
       console.log(data,'data')
      console.log(data.content,'data.conten')
        this.dataTabla = data;
        //this.pagina.total = data.totalElements;
      },
      error => this.showError('Error inesperado')
    );
 
  }

  getCurrentCentro() {
    this.currentCentro = JSON.parse(sessionStorage.getItem('currentCentro'));
    console.log(this.currentCentro,'id centro');
  }


  getCurrentUsuario(){
    let perfil:any[];
    let per :any;
    let pert :any[] ;
    
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));

    perfil = this.user.admTrPerfilxusuarios;

    for(var i=0 ;i<perfil.length;i++){

      per =perfil[i].admTmPerfil.idPerfil; 
      console.log(per ,'ID DE PERFIl PER ') ;
    };
    
      if(per == 2 ){

        this.user.idUsuario = null;
      }
      else if (per ==1){

        this.user.idUsuario =0;
      }
      else {
        this.user.idUsuario = this.user.idUsuario;
      }
      console.log(this.user.idUsuario,'ID USUARIO SEGUN PERFIL');
  }
 

  getCentros(){

    console.log(this.user.idUsuario,'usuario a necesitar');
    this.centroService.getCentros().subscribe(
      data=>{

  
        this.centros=data;
        console.log(this.centros,'centroo');
        for (var k = 0; k < this.centros.length; k++){
          if(this.user.idUsuario == 0){
            this.params.centro = 0;
            this.params.idUsuario = this.user.idUsuario;
           }
           else{   
            if(this.currentCentro.idCentro==this.centros[k].idCentro){
                this.params.centro=this.centros[k];
                console.log(this.user.idUsuario);
                this.params.idUsuario = this.user.idUsuario;
                break;
            }
          }
        } 
       
        this.obtenerData();            
      },
      error => this.showError('Error obtener centros')
    );

  
  }


  generarReporte(){

    console.log('data a generar reporte')
    console.log(this.params);
    var f = $("#reporteForm")[0];
    if(f.reportValidity()){
        let paramsBuscar: any = {
          fechaParamInicio: this.params.fechaDesde,
          fechaParamFinal: this.params.fechaHasta
          
        }

        if (this.params.fechaDesde){
          let momentDesde = moment(this.params.fechaDesde);
          if (momentDesde.isValid()){
            paramsBuscar.fechaDesde = momentDesde.format('MM/DD/YYYY');
          }
        }

        if (this.params.fechaHasta){
          let momentHasta = moment(this.params.fechaHasta);
          if (momentHasta.isValid()){
            paramsBuscar.fechaHasta = momentHasta.format('MM/DD/YYYY');
          }
        }

        if(this.user.idUsuario==0){
          paramsBuscar.estadoUsuario =1
        }else{
          paramsBuscar.estadoUsuario =0;
        }


        if (this.params.centro && this.params.centro.idCentro){
          paramsBuscar.idCentro = this.params.centro.idCentro;
        }

    
        
    
        this.reportesService.generarReporte(paramsBuscar,this.user.idUsuario).subscribe( data => {
        if (data['codigoValidacion']  == 1){
            
            this.showSuccess(data['observaciones']);
          }else if (data['codigoValidacion']  == 2){
            this.showError(data['observaciones'] );
          }
        },
          error => this.showError('Error inesperado')
        );
      }
 
  }

  descargarReporte(item) {
    console.log('descargarReporte');
    console.log(item);
    let reporte: any = {
      idRegistroReporte: item
      
    }
    this.reportesService
      .descargaExcel(item)
      .subscribe(response => {
        var blob = new Blob([(<any>response)], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(blob);
        window.open(url);
        //this.descargarArchivo(response, 'Reporte');
      });


      // this.reporteService.descargaExcel(registro.idCurso, element.value).subscribe(data2 => {
      //   if(data2){
      //     this.descargarArchivo(data2, nombreReporte);
      //     this.toastrService.success('Se ha generado el reporte satisfactoriamente.', '¡Éxito!');
      //   }else{
      //     this.toastrService.error('No se ha podido generar el reporte.', '¡Error!');
      //   }
      //   this.loaderService.hide();
      // },
      // error =>{
      //   this.showError('Error inesperado.')
      //   this.loaderService.hide();
      // });
  }


  descargarReporte1(idRegistro){
    //this.loaderService.display();
    var element: any = document.getElementById("select");
    var nombreReporte = element.options[element.selectedIndex].text;
    //this.idtipo = element.value;
    let reporte = this.options.find(x => x.idRegistroReporte == this.elec);
    if (reporte && reporte.idRegistroReporte && element.value){
     
        this.reportesService.descargaExcel(reporte.idRegReporte).subscribe(data2 => {
          if(data2){
            this.descargarArchivo(data2, nombreReporte);
          }else{
            this.toastrService.error('No se ha podido generar el reporte.', '¡Error!');
          }
         // this.loaderService.hide();
        },
        error =>{
          this.showError('Error inesperado.')
         // this.loaderService.hide();
        });
     
     }
     //else{
    //   this.loaderService.hide();
    // }
  }

  descargarArchivo(blob, nombreReporte){
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let nombreArchvo = nombreReporte.split(' ').join('')
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", nombreArchvo);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }else{
      //html5 download not supported
    }
  }


  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

  ngOnInit() {
    console.log('listar registros');
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Reporte', menu: 'ListarReportes' });
    this.clear();
    this.checkPermisos();
    this.getCurrentUsuario();
    this.getCurrentCentro();
    this.getCentros();
    //this.reloadRegistro();
    this.obtenerData();
  }


  
}
