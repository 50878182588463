import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
//import { environment } from './environments/environment';
import { environment} from './environments/environment.prod';

console.log(environment.environment);
if (environment.production) {
  enableProdMode();
  window.console.log=function(){};
  window.console.log = () => { };
  window.console.error = () => { };
  window.console.warn = () => { };
  window.console.time = () => { };
  window.console.timeEnd = () => { };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
