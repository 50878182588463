import { NavigationEnd, Router } from '@angular/router';
import { Component,OnInit,ViewEncapsulation,ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/admin/user.service';
import { AdmTmModulo } from '../../common/admtmmodulo';
import { AdmTmMenu } from '../../common/admtmmenu';
import { AdmTmUsuario } from '../../common/admtmusuario';
import { AdmTmCentro} from '../../common/admtmcentro';
import { MessageService } from '../../services/MessageService';
import {filter} from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService,UserService],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    //Agregado para Analytics por Jeacarlo A. 08-04-21
    //title = 'Sistema Registro Hemofilia';
    //Hasta aca
    @ViewChild('popOpcionesToggle', {static: false}) popOpcionesToggle;
    currentUser: AdmTmUsuario;
    menusgot: boolean;
    modulomenus: AdmTmModulo[];
    menus: any[];
    menusPermitidos: any[];
    menusxPerfil;
    menusMembresia;
    currentCentro: AdmTmCentro;
    _opened: boolean = false;
    currentModuleName: string = null;
    currentMenuName: string = null;
    subscription: Subscription;
    menusubmenu: any[];
    display='none'; 
    panelOpenState = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private toastrService: ToastrService,
        private messageService: MessageService
    ){ 
      this.clearItems();
      this.subscription = this.messageService.getMessage().subscribe(message => {
          this.handleMessage(message);
      });

      //Seguimos con el agregado de analytics por Jeancarlo A. 08/04/21
      const navEndEvents = this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
      );
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag('config', 'G-CVJKMFZ1BL' , {
            'page_path' : event.urlAfterRedirects
        });
      })
      //hasta acá
    }
    
    islogin() {
        if (sessionStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.currentCentro=JSON.parse(sessionStorage.getItem('currentCentro'));
            this.getMenus();
            this.menusgot = true;
            return true;
        } else {
            // this.clearItems();
            return false;
        }
  }

  getMenus() {
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));        
      this.menusxPerfil = this.getUserMenusPerfiles(currentUser);
      this.filterMenus(this.menusxPerfil);
      this.getModuloxMenu(this.menus);
  }

  clearMenus(){
      this.modulomenus = [];
      this.menus = [];
      this.menusPermitidos = [];
      this.menusubmenu=[];
  }

  getUserMenusPerfiles(currentUser) {
      let menusxPerfil = currentUser.admTrPerfilxusuarios[0].admTmPerfil.admTrMenuxperfils;
      var menus = [];
      for (let i = 0; i < menusxPerfil.length; i++) {
          if (menusxPerfil[i].estado == 1) {
              menus.push(menusxPerfil[i].admTmMenu);
          }
      }
    //   console.log('getUserMenusPerfiles.')
    //   console.log(menus);
      return menus;
  }

  filterMenus(menusxPerfil) {
      for (let mp of this.menusxPerfil) {
          this.menusPermitidos.push(mp);
          if (mp.admTmMenuPadre == null) {

              this.menus.push(mp);
          }
        //   }else{
        //     this.menus[mp.idMenu].admTmMenu.push(mp);

        //   }
      }
  }

  getModuloxMenu(menus) {
      for (let m of menus) {
          let getModulo = this.findModuloById(this.modulomenus, m.admTmModulo.idModulo);
          if (getModulo == null) {
              let modulo = this.clearInit();
              modulo.idModulo = m.admTmModulo.idModulo;
              modulo.nombre = m.admTmModulo.nombre;
              modulo.admTmMenu.push(m);
              this.modulomenus.push(modulo);
          } else {
            this.modulomenus[getModulo.index].admTmMenu.push(m);
            //   if (m.admTmMenu==null){
            //     this.modulomenus[getModulo.index].admTmMenu.push(m);
            //   }else{
            //       for(let sm of m){
            //         this.menusubmenu[sm.idMenu].admTmMenu.push(sm);
            //       }
                
            //   } 
            
          }
      }
    //   console.log("menusubmenu");
    //   console.log(this.menusubmenu);
      for (let module of this.modulomenus) {
          this.orderItems(module);
      }
  }

  findModuloById(modulos, idModulo) {
      let response = null;
      for (let i = 0; i < modulos.length; i++) {
          if (modulos[i].idModulo === idModulo) {
              modulos[i].index = i;
              response = modulos[i];
              break;
          }
      }
      return response;
  }
   
  orderItems(module: AdmTmModulo) {
      let ordered: AdmTmMenu[] = [];

      for (let item of module.admTmMenu) {
          if (item.orden != 0) {
              ordered[item.orden] = item;
          }
      }

      if (ordered.length > 0) {
          module.admTmMenu = ordered.filter(function () { return true; });
      }
  }

  openModule(modulo){
      this.currentModuleName = modulo.nombre;
  }

  openMenu(menu) {
      this.currentMenuName = menu.descripcion;
      this.router.navigate(menu.ruta.split(" "));
  }

    openSubMenu(menu) {
        this.currentMenuName = menu.descripcion;
        this.router.navigate(menu.ruta.split(" "));
    }
  
  handleMessage(message){
      if (message.type == 'ruta'){
          this.currentModuleName = message.modulo;
          this.currentMenuName = message.menu;
      } else if (message.type == 'loginReload'){
          this.islogin();
      }
  }

  clearInit() {
      return {
          idModulo: null,
          codigo: null,
          descripcion: null,
          estado: null,
          nombre: null,
          admTmMenu: [],
          idUsuarioActualizacion: null,
          idUsuarioCreacion: null
      };
  }

  clearItems() {
      this.menusxPerfil = null;
      this.menusMembresia = null;
      this.menusgot = false;
      this.menus = [];
      this.menusPermitidos = [];
      this.modulomenus = [];
  }

  goToHome(){
      this.currentMenuName = null;
      this.router.navigate(["/"]);
  }

  toggleMenu(){
      this._opened = !this._opened;
  }

  closeSesion(){
      this.clearMenus();
      this.closeMenuLogOut();
      this.menusgot = false;
  }

  closeMenuLogOut(){
      this.currentModuleName = null;
      this.currentMenuName = null;
      this._opened = false;
  }

  closeMenu(){
      this._opened = false;
  }

  ngOnInit() {
      // this.clearItems();
      this.messageService.sendMessage({ type: 'ruta', modulo: null, menu: null });
      this.islogin();
    //   console.log(this.modulomenus);
  }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.subscription.unsubscribe();
  }
  onToggleSidenav() {
    this.display='block'; //Set block css
}
closeModalDialog(){
    this.display='none';
}

ingresarmenu(menu){
    // console.log(menu);
    this.router.navigate(menu.ruta.split(" "));
}

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
    }

    showInfo(mensaje) {
        this.toastrService.info(mensaje, '¡Información!');
    }

    showWarning(mensaje) {
        this.toastrService.warning(mensaje, '¡Advertencia!');
    }

    showError(mensaje) {
        this.toastrService.error(mensaje, '¡Error!');
    }

}
