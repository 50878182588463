import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LovsService } from '../../../../services/admin/lovs.service';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core'; 
import { DataService } from '../../../../services/DataService';
import { RegistroComorbilidadesService } from '../../../../services/hemofilia/registrocomorbi.service';
import {RegistroTratamientoService} from '../../../../services/hemofilia/registrotratamiento.service';

import * as $ from 'jquery';

export interface resultado {
  value: string;
  nombre: string;
}

@Component({
  selector: 'app-evoluciones-comorbilidades',
  templateUrl: './evoluciones-comorbilidades.component.html',
  styleUrls: ['./evoluciones-comorbilidades.component.scss'],
  providers:[RegistroComorbilidadesService,RegistroTratamientoService,DataService],
  encapsulation: ViewEncapsulation.None
})
export class EvolucionesComorbilidadesComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  dataPacienteRegistro: any = null;
  newPacienteComorbi: any=null;
  enfermedades: any = [];
  enfermedadesVacuna: any = [];
  enfermedadesSerologicas: any = [];
  enfermedadesOtro: any = [];
  enfermedadesCirugia: any = [];
  disableCreate: boolean = false;
  disableYesNo: boolean = true;
  idRegistro: any = null;
  dataRecibida: any = null;
  //
  idRegEvolucion: any = null;
  dataRecibidaTrata: any = null; //Jeancarlo A. 05-05-21
  cargadoDataTrata: boolean = false; //Jeancarlo A. 05-05-21

  admTmLovOtrasEnfermedades: any = null; //Jeancarlo A. 05-05-21
  cargadoOtrasEnfermedades: boolean=false;//Jeancarlo A. 05-05-21
  admTmLovProcedimientosQuirurgicos: any = null;//Jeancarlo A. 06-05-21
  cargadoProcQuirurgicos: boolean=false; //Jeancarlo A. 06-05-21
  admTmLovYesNo: any = null;//Jeancarlo A. 06-05-21
  cargadoYesNo: boolean = false;//Jeancarlo A. 06-05-21 
  respuestaNo: boolean = true; // Jeancarlo A. 07-05-21

  cargadoData: boolean = false;
  cargadoEnfermedades: boolean = false;
  disableEdit: boolean = false;

  resultados: resultado[] = [
    {value: 'Reactivo',nombre: 'Reactivo'},
    {value: 'No Reactivo',nombre: 'No Reactivo'},
    {value: 'Sin Especificar', nombre:'Sin Especificar'}
  ];

  constructor(
    private lovsService: LovsService,
    private toastrService: ToastrService,
    private dataService : DataService,
    private router : Router,
    private route: ActivatedRoute,
    private registroComorbilidadesService: RegistroComorbilidadesService,
    private registroTratamientoService: RegistroTratamientoService
  ) { 
    this.enfermedades=[];
    this.enfermedadesVacuna=[];
    this.enfermedadesSerologicas=[];
    this.enfermedadesOtro=[];
    this.enfermedadesCirugia=[];
  }
  
  recibirData(data: any) {
    this.dataPacienteRegistro = data;
    this.disableEdit=data.disableEdit;
    this.newPacienteComorbi.hemTrRegistroEvolucion = {
      //idRegistro: data.idRegistro
      idRegEvolucion: data.idRegEvolucion
    }
    //this.idRegistro = data.idRegistro;
    this.idRegEvolucion = data.idRegEvolucion;
    //if (data.idRegistroEstado > 1){ // 1: En proceso
    //  this.disableEdit = true;
    //}
  }

  clearNewRegistroComorbi(){
    $("#ResultadoEnfSero").val('Sin Especificar');
    $("#ResultadoEnfSero2").val('Sin Especificar');
    //let result=this.resultados.find(x => x.value == 'Reactivo');
    this.newPacienteComorbi = {
      vacunacion: null,
      fecha1: null,
      fecha2: null,
      resultado:null, //Agregado por Jeancarlo A. 07/04/21 result.value
      imc: null,
      admTmLovOtrasEnfermedades: null, //Agregado por Jeancarlo A. 07/04/21 result.value
      admTmLovProcedimientosQuirurgicos: null,
      admTmLovYesNo: null,
    };
  }

  getLovEnfermedades(){
    this.lovsService.getAdmTmLovEnfermedadActivas().subscribe(
      data=>{
        this.enfermedades = data;
        let vacunas = [];
        let serologicas = [];
        let otros = [];
        let cirugias = [];
        for (var enfermedad of data){
          if (enfermedad.tipo == 'VACUNA'){
            vacunas.push(enfermedad);
          }else if (enfermedad.tipo == 'SEROLOGICA'){
            serologicas.push(enfermedad);
          }else if (enfermedad.tipo == 'OTROS'){
            otros.push(enfermedad);
          }else if (enfermedad.tipo == 'CIRUGIA'){
            cirugias.push(enfermedad);
          }
        }
        this.enfermedadesVacuna = vacunas;
        this.enfermedadesSerologicas = serologicas;
        this.enfermedadesOtro = otros;
        this.enfermedadesCirugia = cirugias;

        this.cargadoEnfermedades = true;
        this.verificarCarga();
      },
      error => this.showError('Error obtener yes no')
    );
  }

  getLovOtrasEnfermedades(){
    this.lovsService.getAdmTmLovOtrasEnfermedadesActivas().subscribe(data=>{
      this.admTmLovOtrasEnfermedades = data;
      this.cargadoOtrasEnfermedades = true;
      this.verificarCarga();
    },
      error => this.showError('Error obtener otras enfermedades')
    );
  }

  getLovYesNo(){
    this.lovsService.getAdmTmLovYesNoActivas().subscribe(data=>{
      this.admTmLovYesNo = data;
      this.cargadoYesNo = true;
      this.verificarCarga();
    },
      error => this.showError('Error obterner yes no')
    );
  }

  getLovProcQuirurgicos(){
    this.lovsService.getAdmTmLovProcedimientosQuirurgicosActivas().subscribe(data =>{
      this.admTmLovProcedimientosQuirurgicos = data;
      this.cargadoProcQuirurgicos = true;
      this.verificarCarga();
    },
      error => this.showError('Error obtener procedimientos quirurgicos')
    );
  }

  fechaVacunaElegida(event, row, numeroFecha){
    if (numeroFecha == 1){
      row.fecha1 = event;
    }else if (numeroFecha == 2){
      row.fecha2 = event;
    }
  }

  fechaSerologicaElegida(event, row){
    row.fecha1 = event;
  }

  resultadoIngresado(event, row){
    if (event && event.target && event.target.value){
      row.resultado = event.target.value;
    }else{
      row.resultado = '';
    }
  }

  enfermedadIngresada(event, row){
    row.nombreOtro = event;
  }

  agregarFila(){
    this.enfermedadesOtro.push({
      nombreOtro: '',
      fecha1: '',
      resultado: '',
      admTmLovOtrasEnfermedades:'',
    });
    this.enfermedadesOtro = [...this.enfermedadesOtro]
  }

  agregarFilaCirugia(){
    this.enfermedadesCirugia.push({
      admTmLovProcedimientosQuirurgicos:'',
      nombreOtro: '',
      fecha1: '',
      admTmLovYesNo: '',
      resultado: ''
    });
    this.enfermedadesCirugia = [...this.enfermedadesCirugia]
  }

  eliminarFila(rowIndex){
    this.enfermedadesOtro.splice(rowIndex, 1);
    this.enfermedadesOtro = [...this.enfermedadesOtro]
  }

  eliminarFilaCirugia(rowIndex){
    this.enfermedadesCirugia.splice(rowIndex, 1);
    this.enfermedadesCirugia = [...this.enfermedadesCirugia]
  }

  cargarComorbilidades(){
    this.registroComorbilidadesService.getComorbilidadesByRegistroEvolucion(this.idRegEvolucion).subscribe(
      data => {
        this.dataRecibida = data;
        this.cargadoData = true;
        this.verificarCarga();
      }
    );
  }

  cargarTratamientos(){
    this.registroTratamientoService.getRegistroTratamientoByRegistroEvolucion(this.idRegEvolucion).subscribe(
      data => {
        this.dataRecibidaTrata = data;
        this.cargadoDataTrata = true;
        this.verificarCargaTratamientos();
      }
    );
  }

  ComplicacionesChange(row: any){
    console.log("revisar apartado")
    var detalle = document.getElementById("complicacionesDetalle");


    if (this.enfermedadesCirugia && this.enfermedadesCirugia.length > 0){
      for (var i = 0; i < this.enfermedadesCirugia.length; i++){
        for(var x=0; x<this.admTmLovYesNo.length; x++){
          if (this.enfermedadesCirugia[i].admTmLovYesNo && this.enfermedadesCirugia[i].admTmLovYesNo.idYesNo == this.admTmLovYesNo[x].idYesNo ){//probando quitar el idYesNo de this.dataRecibida[i].vida
            this.enfermedadesCirugia[i].admTmLovYesNo = this.admTmLovYesNo[x];
            break;
          }
        }    
        
        if(this.enfermedadesCirugia[i].admTmLovYesNo.idYesNo == 2){
          $(detalle).attr("required","");
          row.disableEdit=true;
          this.enfermedadesCirugia[i].resultado=null;

          setTimeout(() =>{
            
          },200);
          console.log(row);


        }else{

          $(detalle).removeAttr("required");
          row.disableEdit=false;
          
          setTimeout(() =>{
          },200);
          console.log(row);

        }

      }
    }

  }

  verificarCarga(){
    if (this.cargadoData && this.cargadoEnfermedades){
      //if (this.idRegistro && this.dataRecibida){
      if (this.idRegEvolucion && this.dataRecibida){
        var enfermedadesPivote;
        for (var i = 0; i < this.dataRecibida.length; i++){
          let recibida = this.dataRecibida[i];          
          //if (recibida.admTmLovEnfermedad.tipo == 'VACUNA'){
          if (recibida.tipo == 'VACUNA'){
            enfermedadesPivote = this.enfermedadesVacuna;
          //} else if (recibida.admTmLovEnfermedad.tipo == 'SEROLOGICA'){
          } else if (recibida.tipo == 'SEROLOGICA'){
            enfermedadesPivote = this.enfermedadesSerologicas;

          } else if (recibida.tipo == 'OTROS'){
            // Otros
            enfermedadesPivote = this.enfermedadesOtro;
          }else if (recibida.tipo == 'CIRUGIA'){
            enfermedadesPivote = this.enfermedadesCirugia;
          }

          if (recibida.tipo == 'OTROS'){

            for(var x=0;x<this.admTmLovOtrasEnfermedades.length; x++){
              if(recibida.admTmLovOtrasEnfermedades && recibida.admTmLovOtrasEnfermedades.idOtraEnfermedad == this.admTmLovOtrasEnfermedades[x].idOtraEnfermedad){
                recibida.admTmLovOtrasEnfermedades = this.admTmLovOtrasEnfermedades[x];
                break;
              }
            }

            let otro: any = {
              admTmLovEnfermedad: recibida.admTmLovEnfermedad,
              tipo: "OTROS",
              fecha1: (recibida.fecha1 ? new Date(recibida.fecha1 + 'T24:00:00') : null),
              fecha2: (recibida.fecha2 ?  new Date(recibida.fecha2 + 'T24:00:00') : null),
              fecha3:null,
              resultado: recibida.resultado,
              nombreOtro: recibida.nombreOtro,
              fecha1No:0,
              fecha2No:0,
              fecha3No:0,
              admTmLovOtrasEnfermedades: recibida.admTmLovOtrasEnfermedades,
              admTmLovProcedimientosQuirurgicos: null,
              admTmLovYesNo:null
            };
            this.enfermedadesOtro.push(otro);

          }else if (recibida.tipo == 'CIRUGIA'){

            for(var x=0;x<this.admTmLovProcedimientosQuirurgicos.length; x++){
              if(recibida.admTmLovProcedimientosQuirurgicos && recibida.admTmLovProcedimientosQuirurgicos.idProcQuirurgico == this.admTmLovProcedimientosQuirurgicos[x].idProcQuirurgico){
                recibida.admTmLovProcedimientosQuirurgicos = this.admTmLovProcedimientosQuirurgicos[x];
                break;
              }
            }

            for(var x=0;x<this.admTmLovYesNo.length; x++){
              if(recibida.admTmLovYesNo && recibida.admTmLovYesNo.idYesNo == this.admTmLovYesNo[x].idYesNo){
                recibida.admTmLovYesNo = this.admTmLovYesNo[x];
                break;
              }
            }

            let otro: any = {
              disableEdit: false,
              admTmLovEnfermedad: recibida.admTmLovEnfermedad,
              tipo: "CIRUGIA",
              fecha1: (recibida.fecha1 ? new Date(recibida.fecha1 + 'T24:00:00') : null),
              fecha2: (recibida.fecha2 ? new Date(recibida.fecha2 + 'T24:00:00') : null),
              fecha3:null,
              resultado: recibida.resultado,
              nombreOtro: recibida.nombreOtro,
              fecha1No:0,
              fecha2No:0,
              fecha3No:0,
              admTmLovOtrasEnfermedades: null,
              admTmLovProcedimientosQuirurgicos: recibida.admTmLovProcedimientosQuirurgicos,
              admTmLovYesNo:recibida.admTmLovYesNo
            };
            this.enfermedadesCirugia.push(otro);
          }/*else if(recibida.tipo == 'SEROLOGICA'){
            let resultadoSer = this.resultados.find(res => res.value == recibida.resultado);
            let otro: any ={
              admTmLovEnfermedad: recibida.admTmLovEnfermedad,
              tipo: "SEROLOGICA",
              fecha1: (recibida.fecha1 ? new Date(recibida.fecha1 + 'T00:00:00') : null),
              fecha2: (recibida.fecha2 ? new Date(recibida.fecha2 + 'T00:00:00') : null),
              resultado: resultadoSer,
              nombreOtro: recibida.nombreOtro,
              fecha1No:0,
              fecha2No:0,
              fecha3No:0
            };
            this.enfermedadesSerologicas.push(otro);
          }*/
          else{
            let enfermedadEditar = enfermedadesPivote.find(enfr => enfr.idEnfermedad == recibida.admTmLovEnfermedad.idEnfermedad);
            let resultadoSer=recibida.resultado;
            if(recibida.tipo == 'SEROLOGICA'){
              resultadoSer = this.resultados.find(res => res.value == recibida.resultado);
            }
            if (enfermedadEditar){
              enfermedadEditar.fecha1 = (recibida.fecha1 ? new Date(recibida.fecha1 + 'T24:00:00') : null);
              enfermedadEditar.fecha2 = (recibida.fecha2 ? new Date(recibida.fecha2 + 'T24:00:00') : null);
              enfermedadEditar.fecha2 =null;
              enfermedadEditar.resultado = resultadoSer;
              /*$("#SelectResultados").val(recibida.resultado);*/
              enfermedadEditar.nombreOtro = recibida.nombreOtro;

              enfermedadEditar.otraEnfermedades = recibida.otraEnfermedades;
              enfermedadEditar.fecha1No=recibida.fecha1No;
              enfermedadEditar.fecha2No=recibida.fecha2No;
              enfermedadEditar.fecha3No=recibida.fecha3No;
              enfermedadEditar.admTmLovOtrasEnfermedades=recibida.admTmLovOtrasEnfermedades;
              enfermedadEditar.admTmLovProcedimientosQuirurgicos= recibida.admTmLovProcedimientosQuirurgicos;
              enfermedadEditar.admTmLovYesNo= recibida.admTmLovYesNo;
            }
          }
        }
        //this.enfermedadesVacuna = [...this.enfermedadesVacuna]
        this.enfermedadesSerologicas = [...this.enfermedadesSerologicas]
        this.enfermedadesOtro = [...this.enfermedadesOtro]
        this.enfermedadesCirugia = [...this.enfermedadesCirugia]
      }
    }
  }

  verificarCargaTratamientos(){
    if(this.cargadoDataTrata){
      if(this.idRegEvolucion && this.dataRecibidaTrata){
        if(this.dataRecibidaTrata.pesoActual != null && this.dataRecibidaTrata.tallaActual != null){
          this.newPacienteComorbi.imc = (this.dataRecibidaTrata.pesoActual/(this.dataRecibidaTrata.tallaActual*this.dataRecibidaTrata.tallaActual)).toFixed(2);
        }else{
          this.newPacienteComorbi.imc = null;
        }
      }
    }
  }

  createRegistroComorbilidades(){
    let registroComorb=[];
 let contador = 0;
    for (var i = 0; i < this.enfermedadesSerologicas.length; i++){
      if(this.enfermedadesSerologicas[i].resultado === undefined) {
contador=contador+1;
      }
      
    }

    let contComplicaciones = 0;
    for (var i = 0; i < this.enfermedadesCirugia.length; i++){
      if(this.enfermedadesCirugia[i].admTmLovYesNo == "") {
        contComplicaciones=contComplicaciones+1;
      }
      
    }

    let contOtrasEnf = 0;
    for(var i = 0; i < this.enfermedadesOtro.length; i++){
      if(this.enfermedadesOtro[i].admTmLovOtrasEnfermedades == ""){
        contOtrasEnf=contOtrasEnf+1;
      }
    }

    let contProc = 0;
    for(var i = 0; i < this.enfermedadesCirugia.length; i++){
      if(this.enfermedadesCirugia[i].admTmLovProcedimientosQuirurgicos == ""){
        contProc=contProc+1;
      }
    }

    console.log(contador);
    if(contador > 0 ){
      this.showWarning("Por favor complete los datos de los resultados, por lo menos defina los resultados.");
    }else if(contOtrasEnf > 0){
      this.showWarning("Indique minimo la enfermedad en OTRAS ENFERMEDADES");
    }else if(contProc > 0){
      this.showWarning("Indique Los Proc. quirúrgicos");
    }else if(contComplicaciones > 0){
      this.showWarning("Determine si hubo complicaciones.");
    }else{
    for (var i = 0; i < this.enfermedadesVacuna.length; i++){
      var vacuna: any = {
        admTmLovEnfermedad: this.enfermedadesVacuna[i],
        tipo: this.enfermedadesVacuna[i].tipo,
        fecha1: this.enfermedadesVacuna[i].fecha1,
        fecha2: this.enfermedadesVacuna[i].fecha2,
        fecha3: null,
        resultado:null,
        otraEnfermedades:null,
        hemTrRegistroEvolucion:this.newPacienteComorbi.hemTrRegistroEvolucion,
        fecha1No:0,
        fecha2No:0,
        fecha3No:0,
        admTmLovOtrasEnfermedades:null,
        admTmLovProcedimientosQuirurgicos: null,
        admTmLovYesNo: null
      };
      registroComorb.push(vacuna);
    }

    for (var i = 0; i < this.enfermedadesSerologicas.length; i++){
      
      var serologica: any = {
        admTmLovEnfermedad: this.enfermedadesSerologicas[i],
        tipo: this.enfermedadesSerologicas[i].tipo,
        fecha1: this.enfermedadesSerologicas[i].fecha1,
        fecha2: null,
        fecha3:null,
        hemTrRegistroEvolucion:this.newPacienteComorbi.hemTrRegistroEvolucion,
        resultado:this.enfermedadesSerologicas[i].resultado.value,
        fecha1No:0,
        fecha2No:0,
        fecha3No:0,
        admTmLovOtrasEnfermedades:null,
        admTmLovProcedimientosQuirurgicos: null,
        admTmLovYesNo:null
      };
      registroComorb.push(serologica);
    }

    for (var i = 0; i < this.enfermedadesOtro.length; i++){
      var otro: any = {
        admTmLovEnfermedad: null,
        tipo: "OTROS",
        fecha1: this.enfermedadesOtro[i].fecha1,
        fecha2: null,
        fecha3: null,
        nombreOtro: this.enfermedadesOtro[i].nombreOtro,
        resultado: this.enfermedadesOtro[i].resultado,
        hemTrRegistroEvolucion:this.newPacienteComorbi.hemTrRegistroEvolucion,
        fecha1No:0,
        fecha2No:0,
        fecha3No:0,
        admTmLovOtrasEnfermedades:this.enfermedadesOtro[i].admTmLovOtrasEnfermedades,
        admTmLovProcedimientosQuirurgicos: null,
        admTmLovYesNo: null
      };
      registroComorb.push(otro);
    }

    for (var i = 0; i < this.enfermedadesCirugia.length; i++){
      var otro: any = {
        admTmLovEnfermedad: null,
        tipo: "CIRUGIA",
        fecha1: this.enfermedadesCirugia[i].fecha1,
        fecha2: null,
        fecha3: null,
        nombreOtro: this.enfermedadesCirugia[i].nombreOtro,
        resultado: this.enfermedadesCirugia[i].resultado,
        hemTrRegistroEvolucion:this.newPacienteComorbi.hemTrRegistroEvolucion,
        fecha1No:0,
        fecha2No:0,
        fecha3No:0,
        admTmLovOtrasEnfermedades:null,
        admTmLovProcedimientosQuirurgicos: this.enfermedadesCirugia[i].admTmLovProcedimientosQuirurgicos,
        admTmLovYesNo: this.enfermedadesCirugia[i].admTmLovYesNo
      };
      registroComorb.push(otro);
    }

    let dataEvento = {
      listaRegistroComorbilidades: registroComorb,
      //idRegistro: this.idRegistro
      idRegEvolucion: this.idRegEvolucion
    }

    this.registroComorbilidadesService.createRegistroComorbi(dataEvento).subscribe(data => {
      if (data['codigoValidacion'] == 1){
        this.showWarning(data['mensajeValidacion']);
        this.disableCreate = false;
      }else if (data['codigoValidacion'] == 2){
        this.showSuccess('Comorbilidades guardadas.')
        this.siguienteTab();
      }else if (data['codigoValidacion'] == 3){
        this.showError(data['mensajeValidacion']);
        this.disableCreate = false;
      }
    },
    error => this.showError('Error inesperado'),
    );
  }
  }

  siguienteTab(){
    this.dataRegistrada.next(this.newPacienteComorbi);
  }

  ngOnInit() {
    this.clearNewRegistroComorbi()
    console.log('dentro de comorbilidades');
    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      //this.idRegistro = itemid;
      this.idRegEvolucion = itemid;
      this.newPacienteComorbi.hemTrRegistroEvolucion = {
        //idRegistro: itemid
        idRegEvolucion: itemid
      }
      this.cargarComorbilidades();
      this.cargarTratamientos();
    }else{
      this.cargadoData = true;
    }

    this.getLovEnfermedades();
    this.getLovOtrasEnfermedades();
    this.getLovYesNo();
    this.getLovProcQuirurgicos();
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }
  
  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

}
