import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute, Params, Data } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//services
import { MessageService } from '../../../../services/MessageService';

import { MedicamentoService } from '../../../../services/mantenimiento/medicamento.service';
import { LovsService } from '../../../../services/admin/lovs.service';
//domain
import {AdmTmMedicamento} from '../../../../common/admtmmedicamento';

import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
;

export interface estado {
  value: string;
  nombre: string;
}


@Component({
  selector: 'app-listar-medicamentos',
  templateUrl: './listar-medicamentos.component.html',
  styleUrls: ['./listar-medicamentos.component.scss'],
  providers:[MedicamentoService,LovsService,MessageService],
  encapsulation: ViewEncapsulation.None
  
})
export class ListarMedicamentosComponent implements OnInit {
  items: any[];
  itemCount: Number = 0;
  pageable: any;
  params: any;
  canSelect = true;
  canEdit = false;
  canCreate = false;
  canDelete = false;
  medicamentos: AdmTmMedicamento[];
  parametros:any;
  getData: string;

  estados: estado[] = [
    {value: '1', nombre: 'Activo'},
    {value: '0', nombre: 'Desactivo'}
  ];
  displayedColumns: string[] = ['CodigoMedicamento', 'NombreComercial', 'NombreGenerico','Fabricante','Estado','Acciones'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(private medicamentoService : MedicamentoService,
    private lovsService : LovsService,
    private router : Router,
    private toastrService: ToastrService,
    private messageService: MessageService,
    public dialog: MatDialog) { }


    validarAnular(item){
      this.openDialog(item);
  
    }
    openDialog(item): void {
      console.log(item);
      let aux:any;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "¿Seguro que desea eliminar la solicitud "+item.codigoMedicamento+"?"
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        if(result){
          
          aux = item.idMedicamento;
          console.log('id medicamento',aux);
          this.medicamentoService.deleteUser(aux)
          .subscribe(
            data => {
                    
              console.log(data,'data obtenida');
              this.getMedicamentos();
              
              
              if(data.estado==0)
                  { 
                      this.showSuccess('MODIFICACION DEL MEDICAMENTO EXITOSO');
                  }
                  if(data.estado!=0)
                  {                
                    this.showError('FALLO AL  MODIFICAR EL MEDICAMENTO')
                  }
            },
                    error => this.showError('Error inesperado '),
            );
  
        }
      });
    }

  getMedicamentos(){
    let aux:any;
    this.medicamentoService.getMedicamentos()
        .subscribe(
          data=>{
            console.log(data);
            this.medicamentos=data;     
           // this.dataSource=data;  
            aux=data
              for (let i = 0; i < data.length; i++) {
                if (data[i].estado ===1) {
                  aux[i].estado ='Activo';
                }
                else if (data[i].estado ===0){
                  aux[i].estado = 'Desactivo';
                }
              }
  
              this.dataSource=new MatTableDataSource(aux);
              this.dataSource.sort=this.sort;
              this.dataSource.paginator=this.paginator;     
            },
          error => this.showError('Error obtener medicamento')
        );
  }

  reloadMedicamento(){

    console.log('data a buscar')
    console.log(this.params,'data de entrada');
    let aux:any;
    let paramsBuscar: any = {
      codigoMedicamento: this.params.codigoMedicamento,
      nombreGenerico: this.params.nombreGenerico ,
      nombreComercial: this.params.nombreComercial,
      fabricante:this.params.fabricante,
      //estado:this.params.estado
       //nombresPaciente: this.params.centro
       //estado: this.params.estado.value
     }
   
     if (this.params.estado){
      paramsBuscar.estado = this.params.estado.value;
    }

     this.medicamentoService.getRegistrosFiltrado(paramsBuscar).subscribe( data => {
      if(!data){
        return;
      }
      aux=data.content
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].estado ===1) {
          aux[i].estado ='Activo';
        }else if(aux[i].estado ===0){
          aux[i].estado ='Desactivo';
      }  }
       console.log(data,'data obtenida')
       console.log(aux,'aux obtenida')
       //this.data = data.content;
      // this.dataSource=new MatTableDataSource(); 
      this.dataSource=new MatTableDataSource(aux); 
     //this.data = data.content;
      this.dataSource.sort=this.sort;
      this.dataSource.paginator=this.paginator; 
   },
   error => this.showError('Error inesperado')
 );
  }

  clear() {
      this.items = [];
      this.params = {
        codigoMedicamento:null,
        nombreGenerico:null,
        nombreComercial:null,
        fabricante:null,
        estado:null
      };
      this.parametros = [];
  }

  back(){
    let link = ['/'];
    this.router.navigate(link);
  }

  goToCrear() {
    let link = ['/medicamento/nuevo'];
    this.router.navigate(link);
  }

  goToEdit(item: AdmTmMedicamento) {
    console.log(item)
        let link = ['/medicamento/', item.idMedicamento];
        console.log('Medicamento Editar: ' + link);
        this.router.navigate(link);
  }

  goToView(item: AdmTmMedicamento) {
        let link = ['/medicamento/ver/', item.idMedicamento];
        console.log('Medicamento Ver: ' + link);
        this.router.navigate(link);
  }

  checkPermisos() {
    this.canSelect = true;
    this.canEdit = this.checkMenusPermitidos('/medicamento/id');
    this.canCreate=this.checkMenusPermitidos('/medicamento/nuevo');
    this.canDelete=this.canCreate;
  }

  checkMenusPermitidos(url) {
      let menus = JSON.parse(sessionStorage.getItem('menusPermitidos'));
      let response = false;
      for (let i = 0; i < menus.length; i++) {
          url = url.replace(/\d+/g, "id");
          if (url == '/' + menus[i].ruta) {
              response = true;
              break;
          }
      }
      return response;
  }

  ngOnInit() {
    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'ListarMedicamento' });
    this.clear();
    this.checkPermisos();
    this.getMedicamentos();
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
