import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {AdmTmTipoCentro} from '../../../../common/admtmtipocentro';
import { DepartamentoService } from '../../../../services/ubigeo/departamento/departamento.service';
import { ProvinciaService } from '../../../../services/ubigeo/provincia/provincia.service';
import { DistritoService } from '../../../../services/ubigeo/distrito/distrito.service';
import { GeoTmDepartamento } from '../../../../common/geotmdepartamento';
import { GeoTmProvincia } from '../../../../common/geotmprovincia';
import { GeoTmDistrito } from '../../../../common/geotmdistrito';

import { PaisService } from '../../../../services/ubigeo/pais.service';
import { CatalogTmPais } from '../../../../common/catalogtmpais';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { TipoCentroService } from '../../../../services/mantenimiento/tipocentro.service';



// export interface institucion {
//   value: string;
//   nombreInstitucion: string;
// }

@Component({
  selector: 'app-editar-centro',
  templateUrl: './editar-centro.component.html',
  styleUrls: ['./editar-centro.component.scss'],
  providers:[CentroService,PaisService, DepartamentoService, ProvinciaService, DistritoService,TipoCentroService],
  encapsulation: ViewEncapsulation.None
})
export class EditarCentroComponent implements OnInit {
  centroToEdit: AdmTmCentro;
  centro: AdmTmCentro[];
  getData: string;
  busyUpdate: Subscription;
  busyId: Promise<any>;
  disableUpdate = false;
  /*ubigeo*/
  departamentos: GeoTmDepartamento[];
  provincias: GeoTmProvincia[];
  distritos: GeoTmDistrito[];
  paises: CatalogTmPais[];
  instituciones: AdmTmTipoCentro[];

  constructor(private centroService : CentroService,
              private paisService: PaisService,
              private departamentoService: DepartamentoService,
              private provinciaService: ProvinciaService,
              private distritoService: DistritoService,
              private router : Router,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              private tipoCentroService: TipoCentroService
  ) { }


  updateCentro() {
    var f = document.getElementsByTagName('form')[0];
    if(f.reportValidity()) {
      this.disableUpdate = true;

      console.log(this.centroToEdit);
      this.busyUpdate = this.centroService.updateCentro(this.centroToEdit)
        .subscribe(
        data => {
          console.log(data);
          this.getData=JSON.stringify(data);

          if (data['codigoValidacion'] == 1)
            this.showWarning(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 2)
            console.log(data['mensajeValidacion']);
            this.showSuccess(data['mensajeValidacion']);
          if (data['codigoValidacion'] == 3)
            this.showError(data['mensajeValidacion']);
          this.busyUpdate = null;
          this.disableUpdate = false;
        },
        error => this.showError('Error inesperado')
        );
      }
    }

    loadInstituciones() {
      this.tipoCentroService
        .getTipoCentrosActivos()
        .subscribe(response => {
          this.instituciones = response;
        })
    }

    loadPaises() {
      this.paisService
        .findAll()
        .subscribe(response => {
          this.paises = response;
        })
    }

    /*ubigeo*/
    loadDepartamentos() {
      if(this.centroToEdit.catalogTmPais != null){
        if(this.centroToEdit.catalogTmPais.iso.toLocaleUpperCase() === 'PE'){
          this.departamentoService
          .findAll()
          .subscribe(response => {
            this.departamentos = response;
          })
        }
      }
      
  }

  loadProvincia() {
    this.provincias = [];
    this.distritos = [];

    if (this.centroToEdit.geoTmDepartamento) {
      this.provinciaService
        .findByDepartamento(this.centroToEdit.geoTmDepartamento.id)
        .subscribe(response => {
          this.provincias = response;
        })
    }
  }

  loadDistrito() {
    if (this.centroToEdit.geoTmProvincia) {
      this.distritoService
        .findByProvincia(this.centroToEdit.geoTmProvincia.id)
        .subscribe(response => {
          this.distritos = response;
        })
    }
  }

  goToCentro(){
    let link = ['/centro'];
    this.router.navigate(link);
  }

  getCentroById(id) {
      this.centroService
        .getCentroById(id)
        .subscribe(response => {
          this.centroToEdit = response;
          if(this.centroToEdit.admTmTipoCentro!=null){
            for (let i = 0; i < this.instituciones.length; i++) {
              if (this.instituciones[i].idTipoCentro === this.centroToEdit.admTmTipoCentro.idTipoCentro) {
                this.centroToEdit.admTmTipoCentro = this.instituciones[i];
                break;
              }
            }
          }
          if(this.centroToEdit.catalogTmPais!=null){
            for (let i = 0; i < this.paises.length; i++) {
              if (this.paises[i].id === this.centroToEdit.catalogTmPais.id) {
                this.centroToEdit.catalogTmPais = this.paises[i];
                break;
              }
            }
  
            this.departamentoService
              .findByPais(this.centroToEdit.catalogTmPais.id)
              .subscribe(response => {
                this.departamentos = response;
    
                for (let i = 0; i < this.departamentos.length; i++) {
                  if (this.departamentos[i].id === this.centroToEdit.geoTmDepartamento.id) {
                    this.centroToEdit.geoTmDepartamento = this.departamentos[i];
                    break;
                  }
                }
  
  
              this.provinciaService
                .findByDepartamento(this.centroToEdit.geoTmDepartamento.id)
                .subscribe(response => {
                  this.provincias = response;
  
                  for (let i = 0; i < this.provincias.length; i++) {
                    if (this.provincias[i].id === this.centroToEdit.geoTmProvincia.id) {
                      this.centroToEdit.geoTmProvincia = this.provincias[i];
                      break;
                    }
                  }
  
                  this.distritoService
                    .findByProvincia(this.centroToEdit.geoTmProvincia.id)
                    .subscribe(response => {
                      this.distritos = response;
  
                      for (let i = 0; i < this.distritos.length; i++) {
                        if (this.distritos[i].id === this.centroToEdit.geoTmDistrito.id) {
                          this.centroToEdit.geoTmDistrito = this.distritos[i];
                          break;
                        }
                      }
                    })
  
                });
  
            });
          }
        });
      
  }

  

  clearCentroToEdit(){
    this.centroToEdit = {
      idCentro:null,
      codigoCentro:null,
      nombreCentro:null,
      personaContacto:null,
      correoContacto:null,
      numContacto:null,
      direccionCentro:null,
      idUsuarioActualizacion:null,
      idUsuarioCreacion:null,
      institucion:null,
      ubigeo: null,
      geoTmDepartamento: null,
      geoTmProvincia: null,
      geoTmDistrito: null,
      catalogTmPais: null,
      admTmTipoCentro:null,
      idCodigotipodocumentoidentidad: null,
      estado:null
      
    };
 
    this.disableUpdate=false;
  }

  ngOnInit() {
    this.clearCentroToEdit();
    this.loadInstituciones();
    let itemid= this.route.snapshot.params['id'];
    this.loadPaises();
    this.loadDepartamentos();
    this.getCentroById(itemid);
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
