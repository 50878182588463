import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Util } from '../../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/geo/provincia';

@Injectable()
export class ProvinciaService {

  constructor(
    private http: HttpClient
  ) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

  findByDepartamento(id): Observable<any> {
        let getUrl = BASE_URL + '/findByDepartamento?value='+ id;
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
  }

  findById(id): Observable<any> {
      let getUrl = BASE_URL + '/'+ id;
      return this.http.get(getUrl)
      .pipe(
      retry(1),
      catchError(this.errorHandl)
      )
  }

   // Error handling
   errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
    } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
