import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter } from '@angular/core'; 
import { AdmTmCentro } from '../../../../common/admtmcentro';
import { AdmTmTipoCentro } from '../../../../common/admtmtipocentro';
import { AdmTmPaciente } from '../../../../common/admtmpaciente';
import { AdmTmPacienteActual } from '../../../../common/admtmpacienteactual';
import { CatalogTmCodigoTipoDocumentoIdentidad } from '../../../../common/catalogtmcodigotipodocumentoidentidad';
import { DepartamentoService } from '../../../../services/ubigeo/departamento/departamento.service';
import { ProvinciaService } from '../../../../services/ubigeo/provincia/provincia.service';
import { DistritoService } from '../../../../services/ubigeo/distrito/distrito.service';
import { GeoTmDepartamento } from '../../../../common/geotmdepartamento';
import { GeoTmProvincia } from '../../../../common/geotmprovincia';
import { GeoTmDistrito } from '../../../../common/geotmdistrito';

import { PaisService } from '../../../../services/ubigeo/pais.service';
import { CatalogTmPais } from '../../../../common/catalogtmpais';
import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { CatalogosService } from '../../../../services/admin/catalogos.service';
import { DataService } from '../../../../services/DataService';

import { PacienteService } from '../../../../services/mantenimiento/paciente.service';
import { RegistroService } from '../../../../services/hemofilia/registro.service';
import { PacienteActualService } from '../../../../services/mantenimiento/pacienteactual.service';
import { AdmTmUsuario } from 'src/app/common/admtmusuario';
import { AdmTmCentroUsuario } from 'src/app/common/admtmcentrousuario';
//Jeancarlo A. 18-05-21
import { LovsService } from '../../../../services/admin/lovs.service';
import { AdmTmLovGradoInstruccion } from '../../../../common/admtmlovgradoinstruccion'

import * as $ from 'jquery';



export interface genero {
  codigo: string;
  descripcion: string;
}
@Component({
  selector: 'app-registro-demografia',
  templateUrl: './registro-demografia.component.html',
  styleUrls: ['./registro-demografia.component.scss'],
  providers:[CentroService,PaisService, DepartamentoService, ProvinciaService, DistritoService,PacienteService,PacienteActualService,CatalogosService,LovsService],
  encapsulation: ViewEncapsulation.None
})
export class RegistroDemografiaComponent implements OnInit {
  @Output() dataRegistrada = new EventEmitter<any>();
  idRegistro: any = null;
  dataTotal: any = null;
  newPaciente: AdmTmPaciente;
  newPacienteActual: AdmTmPacienteActual;
  centros: AdmTmCentro[];
  centroUsuario: AdmTmCentroUsuario;
  usuario: AdmTmUsuario;
  getData: string;
  busyId: Promise<any>;
  disableCreate = false;
  disableEdit = false;
  instituciones: AdmTmTipoCentro[];
  tipodocumentos: CatalogTmCodigoTipoDocumentoIdentidad[];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femenino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];

  /*ubigeo*/
  departamentosNac: GeoTmDepartamento[];
  provinciasNac: GeoTmProvincia[];
  distritosNac: GeoTmDistrito[];
  paisesNac: CatalogTmPais[];
  departamentosAct: GeoTmDepartamento[];
  provinciasAct: GeoTmProvincia[];
  distritosAct: GeoTmDistrito[];
  paisesAct: CatalogTmPais[];

  otrosPaises: boolean = true;//Jeancarlo A. 28/04/21

  admTmLovGradoInstruccion: AdmTmLovGradoInstruccion[]; //Jeancarlo A. 18/05/21
  cargadoGradoInstruccion: boolean = false; //Jeancarlo A. 18/05/21

  constructor(
    private centroService : CentroService,
    private paisService: PaisService,
    private departamentoService: DepartamentoService,
    private provinciaService: ProvinciaService,
    private distritoService: DistritoService,
    private router : Router,
    private toastrService: ToastrService,
    private pacienteService: PacienteService,
    private registroService: RegistroService,
    private route: ActivatedRoute,
    private dataService : DataService,
    private catalogosService : CatalogosService,
    private lovsService: LovsService
  ) { }
  
  getCentros(){
    this.centroService.getCentros().subscribe(data=>{
      this.centros=data;
      this.cargarCentro()
    },
    error => this.showError('Error obtener centros')
    );
  }

  getLovGradoInstruccion(){
    this.lovsService.getAdmTmLovGradoInstruccionActivos().subscribe(data=>{
      this.admTmLovGradoInstruccion = data;
      this.cargadoGradoInstruccion = true;
      this.cargarGradosInstruccion()
    },
      error => this.showError('Error obtener otras enfermedades')
    );
  }

  getTipoDocumento(){
    this.catalogosService.getCodigoTiposDocumentoIdentidadActivas().subscribe(data=>{
      this.tipodocumentos = data;
      this.cargarTipoDocumento();
    },
    error => this.showError('Error obtener centros')
    );
  }

  loadPaises() {
    this.paisService.findAll().subscribe(response => {
      let soloPeru =[]
        for (var i = 0; i < response.length; i++){
          if(response[i].id<=1){
            //tratamientoT.push(data[i])
            soloPeru.push({
              id:response[i].id,
              estado:response[i].estado,
              iso:response[i].iso,
              nombre:response[i].nombre
            })
          }
        }
        this.paisesAct = soloPeru;
        this.paisesNac = response;
        this.verificarPaisNacimiento();
        this.verificarPaisActual();
      })
  }

  /* Ubigeo actual */
  changePaisActual(){
    this.newPacienteActual.geoTmDepartamento = null;
    this.newPacienteActual.geoTmProvincia = null;
    this.newPacienteActual.geoTmDistrito = null;
    this.loadDepartamentosAct();
  }

  loadDepartamentosAct() {
    if(this.newPacienteActual.catalogTmPais){
      if(this.newPacienteActual.catalogTmPais.iso.toLocaleUpperCase() === 'PE'){
        this.departamentoService.findAll().subscribe(response => {
          this.departamentosAct = response;
          if (this.newPacienteActual.geoTmDepartamento && this.newPacienteActual.geoTmDepartamento.id){
            var validado = false;
            for (var departamento of this.departamentosAct){
              if (departamento.id == this.newPacienteActual.geoTmDepartamento.id){
                this.newPacienteActual.geoTmDepartamento = departamento;
                validado = true;
                break;
              }
            }
            if (validado){
              this.loadProvinciaAct();
            }
          }
        })
      }
    }
  }

  changeDepartamentoActual(){
    this.newPacienteActual.geoTmProvincia = null;
    this.newPacienteActual.geoTmDistrito = null;
    this.loadProvinciaAct();
  }

  loadProvinciaAct() {
    this.provinciasAct = [];
    if (this.newPacienteActual.geoTmDepartamento) {
      this.provinciaService.findByDepartamento(this.newPacienteActual.geoTmDepartamento.id).subscribe(response => {
        this.provinciasAct = response;
        if (this.newPacienteActual.geoTmProvincia && this.newPacienteActual.geoTmProvincia.id){
          var validado = false;
          for (var provincia of this.provinciasAct){
            if (provincia.id == this.newPacienteActual.geoTmProvincia.id){
              this.newPacienteActual.geoTmProvincia = provincia;
              validado = true;
              break;
            }
          }
          if (validado){
            this.loadDistritoAct();
          }
        }
      })
    }
  }

  changeProvinciaActual(){
    this.newPacienteActual.geoTmDistrito = null;
    this.loadDistritoAct();
  }

  loadDistritoAct() {
    this.distritosAct = [];
    if (this.newPacienteActual.geoTmProvincia) {
      this.distritoService.findByProvincia(this.newPacienteActual.geoTmProvincia.id).subscribe(response => {
        this.distritosAct = response;
        if (this.newPacienteActual.geoTmDistrito && this.newPacienteActual.geoTmDistrito.id){
          var validado = false;
          for (var distrito of this.distritosAct){
            if (distrito.id == this.newPacienteActual.geoTmDistrito.id){
              this.newPacienteActual.geoTmDistrito = distrito;
              validado = true;
              break;
            }
          }
        }
      })
    }
  }

  /* Ubigeo Nacimiento */
  changePaisNacimiento(){
    this.newPaciente.geoTmDepartamento = null;
    this.newPaciente.geoTmProvincia = null;
    this.newPaciente.geoTmDistrito = null;
    this.loadDepartamentosNacimiento();
    this.Otrospaises();

  }

  //Jeancarlo A. 28-04-21
  Otrospaises(){
    if(this.newPaciente.catalogTmPais.id != 1){
      this.newPaciente.geoTmDepartamento = null;
      this.newPaciente.geoTmProvincia = null;
      this.newPaciente.geoTmDistrito = null;
      this.otrosPaises = true;
    }else{
      this.otrosPaises = false;
    }
  }
  
  loadDepartamentosNacimiento() {
    if(this.newPaciente.catalogTmPais){
      if(this.newPaciente.catalogTmPais.iso.toLocaleUpperCase() === 'PE'){
        this.departamentoService.findAll().subscribe(response => {
          this.departamentosNac = response;
          if (this.newPaciente.geoTmDepartamento && this.newPaciente.geoTmDepartamento.id){
            var validado = false;
            for (var departamento of this.departamentosNac){
              if (departamento.id == this.newPaciente.geoTmDepartamento.id){
                this.newPaciente.geoTmDepartamento = departamento;
                validado = true;
                break;
              }
            }
            if (validado){
              this.loadProvinciaNacimiento();
            }
          }
        })
      }
    }
  }

  changeDepartamentoNacimiento(){
    this.newPaciente.geoTmProvincia = null;
    this.newPaciente.geoTmDistrito = null;
    this.loadProvinciaNacimiento();
  }

  loadProvinciaNacimiento() {
    this.provinciasNac = [];
    if (this.newPaciente.geoTmDepartamento) {
      this.provinciaService.findByDepartamento(this.newPaciente.geoTmDepartamento.id).subscribe(response => {
        this.provinciasNac = response;
        if (this.newPaciente.geoTmProvincia && this.newPaciente.geoTmProvincia.id){
          var validado = false;
          for (var provincia of this.provinciasNac){
            if (provincia.id == this.newPaciente.geoTmProvincia.id){
              this.newPaciente.geoTmProvincia = provincia;
              validado = true;
              break;
            }
          }
          if (validado){
            this.loadDistritoNacimiento();
          }
        }
      })
    }
  }

  changeProvinciaNacimiento(){
    this.newPaciente.geoTmDistrito = null;
    this.loadDistritoNacimiento();
  }

  loadDistritoNacimiento() {
    this.distritosNac = [];
    if (this.newPaciente.geoTmProvincia) {
      this.distritoService.findByProvincia(this.newPaciente.geoTmProvincia.id).subscribe(response => {
        this.distritosNac = response;
        if (this.newPaciente.geoTmDistrito && this.newPaciente.geoTmDistrito.id){
          var validado = false;
          for (var distrito of this.distritosNac){
            if (distrito.id == this.newPaciente.geoTmDistrito.id){
              this.newPaciente.geoTmDistrito = distrito;
              validado = true;
              break;
            }
          }
        }
      })
    }
  }
  
  cargarRegistro(data: any){
    //console.log('dentro de cargarRegistro')
    this.disableEdit = data.registro.disableEdit;
    if (this.idRegistro){
      this.disableCreate = true;
      let dataRegistro = data.registro;
      /*if (data.idRegistroEstado > 1){ // 1: En proceso
        this.disableEdit = true;
      }*/

      // Se modifican los campos asociados a AdmTmPacienteActual
      this.dataTotal = dataRegistro;
      this.centroUsuario = dataRegistro.admTmCentroUsuario;
      if (dataRegistro.admTmPaciente){
        if (dataRegistro.admTmPaciente.gradoPreEscolar == 'true'){
          delete dataRegistro.admTmPaciente.gradoPreEscolar;
        }
        if (dataRegistro.admTmPaciente.gradoPrimario == 'true'){
          delete dataRegistro.admTmPaciente.gradoPrimario;
        }
        if (dataRegistro.admTmPaciente.gradoSecundario == 'true'){
          delete dataRegistro.admTmPaciente.gradoSecundario;
        }
        if (dataRegistro.admTmPaciente.gradoSuperior == 'true'){
          delete dataRegistro.admTmPaciente.gradoSuperior;
        }
        this.newPaciente = dataRegistro.admTmPaciente;
  

        

      //  let date = new Date();as
      //  let año = dataRegistro.admTmPaciente.fechaNacimiento.substr(0,4); 
      //  let mes = dataRegistro.admTmPaciente.fechaNacimiento.substr(5,2); 
      //  let dia = dataRegistro.admTmPaciente.fechaNacimiento.substr(8,2);
      //  let fecha = dia+"/"+mes+"/"+año;
      //  console.log(fecha);
       //dataRegistro.admTmPaciente.fechaNacimiento = fecha;
       dataRegistro.admTmPaciente.fechaNacimiento =(dataRegistro.admTmPaciente.fechaNacimiento ? new Date(dataRegistro.admTmPaciente.fechaNacimiento + 'T24:00:00') : null);
      // console.log("test");
      // console.log(dataRegistro.admTmPaciente);
       // this.verificarPaisNacimiento();
        this.verificarPaisActual();
        this.cargarTipoDocumento();
        this.cargarGradosInstruccion();//Jeancarlo A. 18/05/21
        this.cargarCentro();
      }

      if (data.pacienteActual){
        this.newPacienteActual = data.pacienteActual;
        this.verificarPaisActual();
      }

      this.disableCreate = false;

    }
  }

  /*SelectPreEscolar(){
    if(this.newPaciente.gradoPreEscolar != null){
      $("checkboxG1").prop("checked",false);
      $("checkboxG2").prop("checked",false);
      $("checkboxG3").prop("checked",false);
      if(this.primario.checked){
        console.log(this.primario);
        this.primario.checked=false;
      }
    }
  }*/

  verificarPaisNacimiento(){
    // Ajuste de Pais
    var verificado = false;
    if (this.newPaciente && this.newPaciente.catalogTmPais && this.newPaciente.catalogTmPais.id && this.paisesNac){
      for (var i = 0; i < this.paisesNac.length; i++){
        if (this.newPaciente.catalogTmPais.id == this.paisesNac[i].id){
          this.newPaciente.catalogTmPais = this.paisesNac[i];
          verificado = true;
          break;
        }
      }
      if (verificado){
        // Se verifican los departamentos
        this.loadDepartamentosNacimiento();
      }
    }
  }

  verificarPaisActual(){
    // Ajuste de Pais
    var verificado = false;
    if (this.newPacienteActual && this.newPacienteActual.catalogTmPais && this.newPacienteActual.catalogTmPais.id && this.paisesAct){
      for (var pais of this.paisesAct){
        if (pais.id = this.newPacienteActual.catalogTmPais.id){
          this.newPacienteActual.catalogTmPais = pais;
          verificado = true;
          break;
        }
      }
      if (verificado){
        // Se verifican los departamentos
        this.loadDepartamentosAct();
      }
      //setear la fecha de nacimiento Jie 
      this.newPacienteActual.admTmPaciente.fechaNacimiento=(this.newPacienteActual.admTmPaciente.fechaNacimiento ? new Date(this.newPacienteActual.admTmPaciente.fechaNacimiento + 'T24:00:00') : null);

    }
  }

  cargarTipoDocumento(){
    if (this.tipodocumentos && this.newPaciente && this.newPaciente.idPaciente){
      for (var i = 0; i < this.tipodocumentos.length; i++){
        if (this.tipodocumentos[i].idCodigotipodocumentoidentidad == this.newPaciente.catalogTmTipoDocumento.idCodigotipodocumentoidentidad){
          this.newPaciente.catalogTmTipoDocumento = this.tipodocumentos[i];
          break;
        }
      }
    }
  }

  cargarGradosInstruccion(){
    if(this.admTmLovGradoInstruccion && this.cargadoGradoInstruccion && this.newPaciente.admTmLovGradoInstruccion && this.newPaciente.idPaciente){
      for(var x=0; x<this.admTmLovGradoInstruccion.length; x++){
        if(this.admTmLovGradoInstruccion[x].idGradoInstruccion == this.newPaciente.admTmLovGradoInstruccion.idGradoInstruccion){
          this.newPaciente.admTmLovGradoInstruccion = this.admTmLovGradoInstruccion[x];
          break;
        }
      }
    }
  }

  cargarCentro(){
    if (this.centros){
      let centro = JSON.parse(sessionStorage.getItem('currentCentro'));
      var idCentro = centro.idCentro;
      if (this.dataTotal){
        idCentro = this.dataTotal.admTmCentroUsuario.admTmCentro.idCentro;
      }
      for (var i = 0; i < this.centros.length; i++){
        if (this.centros[i].idCentro == idCentro){
          this.newPaciente.admTmCentro = this.centros[i];
          break;
        }
      }
    }
  }

  createRegistroDemofrafico() {
    var f = document.getElementsByTagName('form')[0];
    let centro = JSON.parse(sessionStorage.getItem('currentCentro'));
    if(f.reportValidity()) {
      this.disableCreate = true;
      this.newPacienteActual.celular = this.newPaciente.celular;
      this.newPacienteActual.telefono = this.newPaciente.telefono;
      this.newPacienteActual.ocupacion = this.newPaciente.ocupacion;
      this.newPacienteActual.email = this.newPaciente.email;
      
      let newPPA: any = {
        admTmLovGradoInstruccion: this.newPaciente.admTmLovGradoInstruccion ? this.newPaciente.admTmLovGradoInstruccion.idGradoInstruccion :0,
        admTmPacienteActual: this.newPacienteActual,
        admTmPaciente: this.newPaciente,
        admTmCentro: {
          idCentro: centro.idCentro
        }
      
      }
     // console.log(newPPA,'ESTE ES LA DATA PARA REGISTRO SIN MOFDIFICAR');
     // debugger;
     if(this.newPaciente.admTmLovGradoInstruccion == null){
        this.showWarning("Seleccionar al menos un Grado de Instrucción");
        this.disableCreate = false;
      }else if(this.newPaciente.catalogTmPais.id == 1 && this.newPaciente.geoTmDepartamento == null){
        this.showWarning("Seleccionar un departamento de nacimiento");
      }else if(this.newPaciente.catalogTmPais.id == 1 && this.newPaciente.geoTmProvincia == null){
        this.showWarning("Seleccionar una provincia de nacimiento");
      } else if(this.newPaciente.catalogTmPais.id == 1 && this.newPaciente.geoTmDistrito == null){
        this.showWarning("Seleccionar una distrito de nacimiento");
      } else{

      this.pacienteService.createPaciente(newPPA).subscribe( data => {
         // console.log(data,'********************');
          this.getData = JSON.stringify(data);
          if (data['codigoValidacion'] == 1){
            this.showWarning(data['mensajeValidacion']);
            this.disableCreate = false;
          }else if (data['codigoValidacion'] == 2){
            debugger;
            newPPA.id = data['id'];
            this.crearRegistro(newPPA);
            console.log(newPPA,'ESTE ES LA DATA PARA EL REGISTRO')
            debugger;
          }else if (data['codigoValidacion'] == 3){
            this.showError(data['mensajeValidacion']);
            this.disableCreate = false;
          }
        },
        error => this.showError('Error inesperado'),
      );

    } 

    }
  }

  goToRegistro(){
    let link = ['/registro'];
    this.router.navigate(link);
  }

  crearRegistro(dataPaciente){
    let dataRegistro: any = {
      idPaciente: dataPaciente.id,
      idCentro: dataPaciente.admTmPaciente.admTmCentro.idCentro,
      observaciones: ''
    }
    if (this.idRegistro){
      dataRegistro.idRegistro = this.idRegistro;
    }

  


    this.registroService.createRegistro(dataRegistro).subscribe( data => {
      this.getData = JSON.stringify(data);
      if (data['codigoValidacion'] == 1){
        this.showWarning(data['mensajeValidacion']);
        this.disableCreate = false;
      }else if (data['codigoValidacion'] == 2){
        this.showSuccess(data['mensajeValidacion']);
        dataPaciente.idRegistro = data['id'];
        this.newPaciente.codigoPaciente = data['paramString'];
        this.siguienteTab(dataPaciente);
      }else if (data['codigoValidacion'] == 3){
        this.showError(data['mensajeValidacion']);
        this.disableCreate = false;
      }
    },
      error => this.showError('Error inesperado'),
    );
  }

  siguienteTab(dataPaciente){
    this.dataRegistrada.next(dataPaciente);
  }

  clearNewPaciente(){
    this.newPaciente = {
      idPaciente: null,
      nombresPaciente:null,
      apellidoPaterno:null,
      apellidoMaterno:null,
      codigoPaciente:null,
      genero: null,
      catalogTmTipoDocumento: null,
      numeroDocumento:null,
      fechaNacimiento: null,
      direccion:null,
      ubigeo: null,
      geoTmDepartamento: null,
      geoTmProvincia: null,
      geoTmDistrito: null,
      catalogTmPais: null,
      nota:null,
      telefono:null,
      celular:null,
      email:null,
      ocupacion: null,
      gradoPreEscolar: null,
      gradoPrimario:null,
      gradoSecundario:null,
      gradoSuperior:null,
      admTmPacienteActual: null,
      personaContacto:null,
      personaContactoParentezco:null,
      personaContactoTelefono:null,
      personaContactoCelular:null,

      admTmCentro: null,
      admTmLovGradoInstruccion: null
      
    };

    this.newPacienteActual = {
      idPacienteActual: null,
      admTmPaciente: null,
      direccion:null,
      ubigeo: null,
      geoTmDepartamento: null,
      geoTmProvincia: null,
      geoTmDistrito: null,
      catalogTmPais: null,
      nota:null,
      telefono:null,
      celular:null,
      email:null,
      ocupacion: null,
      estado: null,
      direccionActual: null
      
    };
 
    this.disableCreate=false;
  }

  ngOnInit() {
    console.log('demografia')
    this.clearNewPaciente();

    let itemid = this.route.snapshot.params['id'];
    if (itemid){
      this.idRegistro = itemid;
    }else{
      let dataValidacionDocumento = this.dataService.dataValidacionDocumento;
      if (dataValidacionDocumento){
        this.newPaciente.numeroDocumento = dataValidacionDocumento.numeroDocumento;
        this.dataService.dataValidacionDocumento = null;
      }
    }

    
    this.loadPaises();
    this.getCentros();
    this.getTipoDocumento();
    this.getLovGradoInstruccion();
   
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje, '¡Éxito!');
  }

  showWarning(mensaje) {
    this.toastrService.warning(mensaje, '¡Advertencia!');
  }

  showError(mensaje) {
    this.toastrService.error(mensaje, '¡Error!');
  }

}
