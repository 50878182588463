import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ModuloService } from '../../services/admin/modulo.service';
import { UserService } from '../../services/admin/user.service';
import { AuthenticationService } from '../../services/admin/authentication.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AdmTmModulo } from '../../common/admtmmodulo';
import { AdmTmMenu } from '../../common/admtmmenu';
import { AdmTmUsuario } from '../../common/admtmusuario';


@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss'],
    providers: [
        UserService,
        AuthenticationService,
        ModuloService
    ],
    encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
    currentUser: AdmTmUsuario;
    menusgot: boolean;
    modulomenus: AdmTmModulo[];
    menus: any[];
    menusPermitidos: any[];
    menusxPerfil;
    moduloSeleccionado: any;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private moduloService: ModuloService,
        private toastrService: ToastrService,
        private router: Router
    )
    { }


    getCurrentUser() {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }

    showError() {
        this.toastrService.error('Ha ocurrido un problema', '¡Error!');
    }

    loadMenu() {
        if (sessionStorage.getItem('currentUser')) {
            if (!this.menusgot) {
                this.getMenus();
                this.menusgot = true;
            }
            return true;
        } else {
            this.clearItems();
            return false;
        }
    }

    getMenus() {
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        this.menusxPerfil = this.getUserMenusPerfiles(currentUser);
        console.log('jie');
        console.log(this.menusxPerfil );
        this.filterMenus(this.menusxPerfil);
        console.log(this.menus);
        this.getModuloxMenu(this.menus);
        sessionStorage.setItem('menusPermitidos', JSON.stringify(this.menusPermitidos));
        console.log('getMenus Home');
        console.log(this.menusPermitidos);
        console.log('currentUser home');
        console.log(currentUser);
    }

    getModuloxMenu(menus) {
        for (let m of menus) {
            console.log('getModuloxMenu');
            console.log(m);
            let getModulo = this.findModuloById(this.modulomenus, m.admTmModulo.idModulo);
            if (getModulo == null) {
                let modulo = this.clearInit();
                modulo.idModulo = m.admTmModulo.idModulo;
                modulo.nombre = m.admTmModulo.nombre;
                modulo.admTmMenu.push(m);
                this.modulomenus.push(modulo);
            } else {
                this.modulomenus[getModulo.index].admTmMenu.push(m);
            }
        }

        for (let module of this.modulomenus) {
            this.orderItems(module);
        }

    }

    orderItems(module: AdmTmModulo) {
        let ordered: AdmTmMenu[] = [];

        for (let item of module.admTmMenu) {
            if (item.orden != 0) {
                ordered[item.orden] = item;
            }
        }

        if (ordered.length > 0) {
            module.admTmMenu = ordered.filter(function () { return true; });
        }
    }

    filterMenus(menusxPerfil) {
        for (let mp of this.menusxPerfil) {

            this.menusPermitidos.push(mp);
            if (mp.admTmMenuPadre == null) {
                this.menus.push(mp);
            }
        }

    }

    findModuloById(modulos, idModulo) {
        let response = null;
        for (let i = 0; i < modulos.length; i++) {
            if (modulos[i].idModulo === idModulo) {
                modulos[i].index = i;
                response = modulos[i];
                break;
            }
        }
        return response;
    }


    getUserMenusPerfiles(currentUser) {
        let menusxPerfil = currentUser.admTrPerfilxusuarios[0].admTmPerfil.admTrMenuxperfils;
        var menus = [];
        for (let i = 0; i < menusxPerfil.length; i++) {
            if (menusxPerfil[i].estado == 1) {
                menus.push(menusxPerfil[i].admTmMenu);
            }
        }
        return menus;
    }



    clearInit() {
        let modulo
        return modulo = {
            idModulo: null,
            codigo: null,
            descripcion: null,
            estado: null,
            nombre: null,
            admTmMenu: [],
            idUsuarioActualizacion: null,
            idUsuarioCreacion: null
        };
    }

    clearItems() {
        this.menusxPerfil = null;
        this.menusgot = false;
        this.menus = [];
        this.menusPermitidos = [];
        this.modulomenus = [];
    }

    openModule(modulo) {
        sessionStorage.setItem('moduloOpen', JSON.stringify(modulo));
        this.moduloSeleccionado = JSON.parse(sessionStorage.getItem('moduloOpen'));

        if (!this.moduloSeleccionado.admTmMenu[0].back) {
            this.moduloSeleccionado.admTmMenu.splice(0, 0, { textoMenu: 'Volver', back: true });
        }
    }

    openMenu(menu) {

        console.log('openMenu '+menu);
        if (menu.back) {
            sessionStorage.removeItem('moduloOpen');
            this.moduloSeleccionado = null;
        }
        else {
            this.router.navigate(menu.ruta.split(" "));
        }
    }

    getImage(menu) {
        return 'assets/img/icon_' + menu.ruta + '.png';
    }
    getImagemodulo(nombre) {
        return 'assets/img/icon_' + nombre + '.png';
    }

    ngOnInit() {
        this.clearItems();
        this.getCurrentUser();
        this.loadMenu();
console.log(this.modulomenus);
        if(sessionStorage.getItem('moduloOpen')){
            this.openModule(JSON.parse(sessionStorage.getItem('moduloOpen')));
        }
    }


}