import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Util } from '../../../util';


const BASE_URL = Util.IP_API_CONNECTION ;

@Injectable()
export class LovsService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }

    //AdmTmLovScore
    getAdmTmLovScoreActivos(): Observable<any> {
        let url = BASE_URL+ '/lovscore/TodosConPuntaje'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
   
    //AdmTmLovactividad
    findByIdAdmTmLovactividad(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovactividad/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovActividadActivas(): Observable<any> {
        let url =BASE_URL+ '/lovactividad/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovArticulacion
    findByIdAdmTmLovArticulacion(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovarticulacion/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovNoArticulacionActivas(): Observable<any> {
        let url =BASE_URL+ '/lovnoarticulacion/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovNoArticulacion
    findByIdAdmTmLovNoArticulacion(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovnoarticulacion/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovArticulacionActivas(): Observable<any> {
        let url =BASE_URL+ '/lovarticulacion/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovEnfermedad
    findByIdAdmTmLovEnfermedad(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovenfermedad/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovEnfermedadActivas(): Observable<any> {
        let url =BASE_URL+ '/lovenfermedad/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovFactor
    findByIdAdmTmLovFactor(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovfactor/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovFactorActivas(): Observable<any> {
        let url =BASE_URL+ '/lovfactor/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovFrecuencia
    findByIdAdmTmLovFrecuencia(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovfrecuencia/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovFrecuenciaActivas(): Observable<any> {
        let url =BASE_URL+ '/lovfrecuencia/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovFrecuencia
    findByIdAdmTmLovLocalizacionCuerpo(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovlocalicacioncuerpo/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovLocalizacionCuerpoActivas(): Observable<any> {
        let url =BASE_URL+ '/lovlocalicacioncuerpo/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovMedida
    findByIdAdmTmLovMedida(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovmedida/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovMedidaActivas(): Observable<any> {
        let url =BASE_URL+ '/lovmedida/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovRegimenTratamiento
    findByIdAdmTmLovRegimenTratamiento(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovregimentrata/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovRegimenTratamientoActivas(): Observable<any> {
        let url =BASE_URL+ '/lovregimentrata/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovYesNo
    findByIdAdmTmLovYesNo(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovyesno/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }



    getAdmTmLovYesNoActivas(): Observable<any> {
        let url =BASE_URL+ '/lovyesno/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }


    getAdmTmLovServeridadActivas(): Observable<any> {
        let url =BASE_URL+ '/lovseveridad/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovTratamiento
    findByIdAdmTmLovTratamiento(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovtratamiento/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovTratamientoActivas(): Observable<any> {
        let url =BASE_URL+ '/lovtratamiento/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
    //AdmTmLovTratamientoRecibe
    findByIdAdmTmLovTratamientoRecibe(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovtratamientorecibe/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovTratamientoRecibeActivas(): Observable<any> {
        let url =BASE_URL+ '/lovtratamientorecibe/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovTiposConcentradosFactores
    findByIdAdmTmLovTiposConcentradosFactores(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovtiposconcentradosfactores/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovTiposConcentradosFactoresActivas(): Observable<any> {
        let url =BASE_URL+ '/lovtiposconcentradosfactores/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }
     //Jeancarlo A. 16-06-21

     getAdmTrPacienteUsuarioporCentro(idCentro): Observable<any> {
         let url = BASE_URL+'/pacientexusuario/obtenerListaPacienteUsuario/'+ idCentro;
         return this.http.get(url)
         .pipe(
             retry(1),
             catchError(this.errorHandl)
         )
     }




    //AdmTmLovTipoProfilaxis
    findByIdAdmTmLovTipoProfilaxis(id): Observable<any> {
        let findUserUrl = BASE_URL+'/lovtipoprofilaxis/'+id;
        return this.http.get(findUserUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getAdmTmLovTipoProfilaxisActivas(): Observable<any> {
        let url =BASE_URL+ '/lovtipoprofilaxis/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }


    getAdmTmLovProfilaxisRealizadaActivas(): Observable<any> {
        let url =BASE_URL+ '/lovprofilaxisrealizada/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    //AdmTmLovInhibidores
    getAdmTmLovInhibidoresActivas(): Observable<any> {
        let url =BASE_URL+ '/lovinhibidores/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )           
    }

    //AdmTmLovRespuestaInmunotolerancia
    getAdmTmLovRespuestaInmunotoleranciaActivas(): Observable<any> {
        let url =BASE_URL+ '/lovrespuestainmunotolerancia/todosActivos'
        return this.http.get(url)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )           
    }

    //AdmTmLovOtrasEnfermedades
    getAdmTmLovOtrasEnfermedadesActivas(): Observable<any>{
        let url=BASE_URL+'/lovotrasenfermedades/todosActivos'
        return this.http.get(url)
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        )
    }

    //AdmTmLovProcedimientosQuirurgicos
    getAdmTmLovProcedimientosQuirurgicosActivas(): Observable<any>{
        let url=BASE_URL+'/lovprocedimientosquirurgicos/todosActivos'
        return this.http.get(url)
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        )
    }

    //AdmTmLovGradoInstruccion
    getAdmTmLovGradoInstruccionActivos(): Observable<any>{
        let url=BASE_URL+'/lovgradoinstruccion/todosActivos'
        return this.http.get(url)
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        )
    }

    


     // Error handling
     errorHandl(error) {
            let errorMessage = '';
            if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
            } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            console.log(errorMessage);
            return throwError(errorMessage);
    }
}

