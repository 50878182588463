import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable, throwError,Subscription } from 'rxjs';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
//services
import { MessageService } from '../../../../services/MessageService';

import { CentroService } from '../../../../services/mantenimiento/centro.service';
import { UserService } from '../../../../services/admin/user.service';
import { CatalogosService } from '../../../../services/admin/catalogos.service';
import { PerfilService } from '../../../../services/mantenimiento/perfil.service'
//domain
import {AdmTmUsuario} from '../../../../common/admtmusuario';
import {AdmTmCentroUsuario} from '../../../../common/admtmcentrousuario';
import {AdmTmPerfil} from '../../../../common/admtmperfil';
import {AdmTmCentro} from '../../../../common/admtmcentro';
import {CatalogTmCodigoTipoDocumentoIdentidad} from '../../../../common/catalogtmcodigotipodocumentoidentidad';
export interface genero {
  codigo: string;
  descripcion: string;
}

@Component({
  selector: 'app-ver-usuario',
  templateUrl: './ver-usuario.component.html',
  styleUrls: ['./ver-usuario.component.scss'],
  providers:[CentroService,UserService,PerfilService,CatalogosService,MessageService],
  encapsulation: ViewEncapsulation.None
})
export class VerUsuarioComponent implements OnInit {
  updateUsuario: AdmTmUsuario;
  updateuserPerfil : AdmTmPerfil;
  updateuserCentro : AdmTmCentro;
  password1Usuario : string;
  password2Usuario : string;
  busyUpdate: Subscription;
  busyId: Subscription;
  disableCreate = false;
  showChangePwd = false;
  centros: AdmTmCentro[];
  perfiles: AdmTmPerfil[];
  tipodocumentos: CatalogTmCodigoTipoDocumentoIdentidad[];
  generos: genero[] = [
    {codigo: 'F', descripcion: 'Femelino'},
    {codigo: 'M', descripcion: 'Masculino'}
  ];
  listaCentros:any[];
  centrosUsuario: any[];

 
  constructor(private centroService : CentroService,
              private perfilService : PerfilService,
              private usuarioService : UserService,
              private catalogosService : CatalogosService,
              private route: ActivatedRoute,
              private router : Router,
              private toastrService: ToastrService,
              private messageService: MessageService
    ) { }

    getUserById(itemid){
      this.busyId=this.usuarioService.getUserById(itemid)
          .subscribe(data=>{ 
                        console.log(data);
                        this.updateUsuario = data;
                        data.admTrPerfilxusuarios[0].admTmPerfil.admTrMenuxperfils=null;
                        
                        console.log(this.perfiles);
                        for (let i = 0; i < this.perfiles.length; i++) {
                          if (this.perfiles[i].idPerfil === data.admTrPerfilxusuarios[0].admTmPerfil.idPerfil) {
                            this.updateuserPerfil = this.perfiles[i];
                            break;
                          }
                        }
                        // for (let i = 0; i < this.centros.length; i++) {
                        //   if (this.centros[i].idCentro === data.admTmCentro.idCentro) {
                        //     this.updateuserCentro = this.centros[i];
                        //     break;
                        //   }
                        // }
                        this.usuarioService.getCentrosByUser(data.idUsuario).subscribe(centros=>{
                          console.log('centrosUsuaro');
                          console.log(centros);
                          this.centrosUsuario=centros;
                          this.verificarCarga(); 

                        });
                        for (let i = 0; i < this.tipodocumentos.length; i++) {
                          if (this.tipodocumentos[i].idCodigotipodocumentoidentidad === data.catalogTmTipoDocumento.idCodigotipodocumentoidentidad) {
                            this.updateUsuario.catalogTmTipoDocumento = this.tipodocumentos[i];
                            break;
                          }
                        }
                        this.updateUsuario.genero=data.genero;

                      });      
  }
  getCentros(){
    this.centroService.getCentros()
        .subscribe(
          data=>{
            console.log(data);
            this.centros=data;  
            this.listaCentros=data;   
            this.getPerfiles();
                  
            },
          error => this.showError('Error obtener centros')
        );
  }

  verificarCarga(){
        if (this.centrosUsuario){
          for (var i = 0; i < this.centrosUsuario.length; i++){
            let listaCentros = this.listaCentros.find(x => x.idCentro == this.centrosUsuario[i].admTmCentro.idCentro)
            listaCentros.elegido = true;
          }
        }
  }

  listaCentroElegido(centro){
    if (centro.elegido){
      centro.elegido = false;
    }else{
      centro.elegido = true;
    }

  }

  getPerfiles(){
    this.perfilService.getPerfiles()
        .subscribe(
          data=>{
            console.log("perfiles");
            console.log(data);
            this.perfiles=data;  
            let itemid= this.route.snapshot.params['id'];
            this.getUserById(itemid);          
            },
          error => this.showError('Error obtener perfiles')
        );
  }


  getTipoDocumento(){
    this.catalogosService.getCodigoTiposDocumentoIdentidadActivas()
        .subscribe(
          data=>{
            console.log(data);
            this.tipodocumentos=data;            
            },
          error => this.showError('Error obtener tipo documento')
        );
  }


  clearUpdateUsuario(){
    this.updateUsuario = {
      idUsuario: null,
      nombresUsuario:null,
      apellidosUsuario:null,
      codigoUsuario:null,
      numeroDocumento:null,
      emailUsuario:null,
      telefono:null,
      celular:null,
      estado:null,
      admTrPerfilxusuarios:null, 
      passwordUsuario:null ,
      direccion: null,
      genero: null,
      noEliminable:null,
      catalogTmTipoDocumento: null,
      apellidosNombres:null
    };
 
    this.disableCreate=false;
  }

  goToUsuario(){
    let link = ['/usuario'];
    this.router.navigate(link);
  }

  back(){
    let link = ['/usuario'];
    this.router.navigate(link);
  }

 
  ngOnInit() {

    this.messageService.sendMessage({ type: 'ruta', modulo: 'Mantenimiento', menu: 'Crear Usuario' });
    this.clearUpdateUsuario();
    this.getCentros();
   // this.getPerfiles();
    this.getTipoDocumento();
    // let itemid= this.route.snapshot.params['id'];
    // this.getUserById(itemid);
  }

  updateUser(){

  }
  toogleChangePwd(){
    
  }

  showSuccess(mensaje) {
    this.toastrService.success(mensaje ,'¡Éxito!' );
  }
    
  showInfo(mensaje) {
    this.toastrService.info(mensaje ,'¡Información!' );
  }
    
  showWarning(mensaje) {
    this.toastrService.warning(mensaje ,'¡Advertencia!' );
  }

  showError(mensaje) {
    this.toastrService.error(mensaje , '¡Error!' );
  } 

}
