import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdmTmCentro } from '../../common/admtmcentro';
import { Util } from '../../../util';

const BASE_URL = Util.IP_API_CONNECTION + '/reportes';

@Injectable()
export class ReportesService {
    constructor(private http: HttpClient) { }
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    }


    getReportesFiltrado(paramsBusqueda: any): Observable<any> {
        let getUrl = BASE_URL + '/todos';
        let paramsGet = new HttpParams()
            .set('fechaDesde', paramsBusqueda.fechaDesde)
            .set('fechaHasta', paramsBusqueda.fechaHasta)
            .set('centroPaciente', paramsBusqueda.centroPaciente)
            .set('pagina', paramsBusqueda.pagina)
            .set('cantidadPagina', paramsBusqueda.cantidadPagina);

        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: paramsGet
        }
        return this.http.get(getUrl, options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    getReportes(): Observable<any> {
        let getUrl = BASE_URL+'/todos';
        return this.http.get(getUrl)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    generarReporte(body: Object,iduser:any): Observable<Object> {
        var usuario=JSON.parse(sessionStorage.getItem('currentUser'));
        let createUrl = BASE_URL+'/generar';
        let bodyString = JSON.stringify(body);
        return this.http.post(`${createUrl}/${iduser}`, bodyString, this.httpOptions)
          .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

    generarReporte1(paramsBusqueda: any, iduser:any): Observable<any> {
        let getUrl = BASE_URL + '/generar/'+iduser;
        let paramsGet = new HttpParams()
            .set('fechaDesde', paramsBusqueda.fechaDesde)
            .set('fechaHasta', paramsBusqueda.fechaHasta)
            .set('idCentro', paramsBusqueda.centro);

        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            params: paramsGet
        }
        return this.http.get(getUrl, options)
        .pipe(
        retry(1),
        catchError(this.errorHandl)
        )
    }

    descargaExcel(idRegReporte): Observable<any> {
        let excelURL = BASE_URL + '/downloadreporte/'+idRegReporte;
        return this.http.get(excelURL,{responseType: 'blob'})
        .pipe(
            retry(1),
            catchError(this.errorHandl)
        )
    }

    descargaExcel1(idRegReporte){
        let uri = BASE_URL + '/downloadreporte';  
    
        return Observable.create(observer => {
          let xhr = new XMLHttpRequest();
          xhr.open('POST', uri, true);
          xhr.setRequestHeader('Content-type', 'application/json');
          xhr.send(idRegReporte)
          xhr.responseType = 'blob';
    
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                var contentType = 'application/vnd.ms-excel';
                var blob = new Blob([xhr.response], { type: contentType });
                observer.next(blob);
                observer.complete();
              } else {
                observer.error(xhr.response);
              }
            }
          }
          xhr.send();
    
        });
      }

   // Error handling
   errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
    } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
}

}
